import { Certificate } from "crypto";

export enum HelpURLKeys {

    UserEdit = 'User_Edit',
    UserUnlockUser = 'User_UnlockUser',
    UserActivate = 'User_Activate',
    UserDeActivate = 'User_DeActivate',
    UserDelete = 'User_Delete',
    UserNewUser = 'User_NewUser',
    UserResetPassword = 'User_ResetPassword',
    UserForceResetPassword = 'User_ForceResetPassword',
    UserChangePassword = 'User_ChangePassword',
    ViewPreferences = 'view_preferences',
    ApplicationEdit = 'Application_Edit',
    ApplicationAssignUser = 'Application_AssignUser',
    ApplicationManageCredentials = 'Application_ManageCredentials',
    ApplicationActivate= 'Application_Activate',
    ApplicationDeactivate = 'Application_Deactivate',
    ApplicationDelete = 'Application_Delete',
    ApplicationManageRoles = 'Application_ManageRoles',
    ApplicationAddApplication = 'Application_AddApplication',
    ApplicationAddResourceShare = 'Application_AddResourceShare',
    ApplicationAddSotiMobiControl = 'Application_AddSotiMobiControl',
    ApplicationAddSotiAssist = 'Application_AddSotiAssist',
    ApplicationAddSotiConnect = 'Application_AddSotiConnect',
    ApplicationAddSOTIInsight = 'Application_AddSOTIInsight',
    ApplicationAddSOTICentral = 'Application_AddSOTICentral',
    ApplicationAddSOTISnap = 'Application_AddSOTISnap',
    TenantEdit = 'Tenant_Edit',
    TenantAssignApplication = 'Tenant_AssignApplication',
    TenantActivate = 'Tenant_Activate',
    TenantDeactivate= 'Tenant_Deactivate',
    TenantDelete = 'Tenant_Delete',
    TenantCreateTenant = 'Tenant_CreateTenant',
    DirectoriesEdit = 'Directories_Edit',
    DirectoriesActivate= 'Directories_Activate',
    DirectoriesDeActivate= 'Directories_DeActivate',
    DirectoriesDelete= 'Directories_Delete',
    UserAddDirectoryUserGroup= 'User_AddDirectoryUserGroup',
    UserAddIDPUserGroup= 'User_AddIDPUserGroup',
    ApplicationValidate= 'Application_Validate',
    DirectoriesCheckConnection = 'Directories_CheckConnection',
    DirectoriesNewLDAP = 'Directories_NewLDAP',
    DirectoriesNewIDP = 'Directories_NewIDP',
    DirectoriesNewAzureAD = 'Directories_NewAzureAD',
    UserMyProfile= 'User_MyProfile',
    DomainAddNewDomain= 'Domain_AddNewDomain',
    DomainVerify= 'Domain_Verify',
    DomainDelete= 'Domain_Delete',
    ContactUs= 'Contact_Us',
    GroupNew= 'Group_New',
    GroupEdit= 'Group_Edit',
    GroupDelete= 'Group_Delete',
    GroupAssignUser = 'Group_AssignUser',
    AssignApplicationUser = 'User_AssignApplication',
    AddAzureADConnection = 'IDP_AddAzureAD',
    EditAzureADConnection = 'IDP_EditAzureAD',
    AddGsuiteConnection = 'IDP_AddGSuite',
    EditGsuiteConnection = 'IDP_EditGSuite',
    ConfigureCLA = 'CLA_Configure',
    CreatePolicy = 'Policy_Create',

    EditPolicy = 'Policy_Edit',
    PolicyDeActivate= 'Policy_Deactivate',

    PolicyActivate= 'Policy_Activate',
    AuthenticationUserPassword = 'Authentication_UserPassword',
    AuthenticationGoogleAuthenticator = 'Authentication_GoogleAuthenticator',
    NewDeviceSignIn = 'NewDevice_SignIn',
    UserAccessManagement = "UserAccess_Mangement",
    TenantSessionManagement = "TenantSession_Management",

    AuthenticationMicrosoftAuthenticator = 'Authentication_MicrosoftAuthenticator',
    AuthenticationDuoSecurity = 'Authentication_DuoSecurity',
    AuthenticationEmail = 'Authentication_Email',
    PolicyDelete = 'Policy_Delete',
    LogsEvents = 'Logs_Events',
    BulkUsersUpload ='Bulk_Users_Upload',
    UserProfile = 'User_Profile',
    GroupProfile = 'Group_Profile',
    Integrations = 'Integration',
    EnableINTERAPPCommunication = 'Enable_Interapp',
    DisableINTERAPPCommunication = 'Disable_Interapp',
    SupportAccountAddNewSupportAccount='SupportAccount_AddAccount',
    SupportAccountListing='SupportAccount_Listing',
    SupportAccountEdit='SupportAccount_EditAccount',
    SupportAccountDelete='SupportAccount_DeleteAccount',
    SupportAccountRevoke='SupportAccount_RevokeAccess',
    EditDirectoryUser = 'Edit_Directory_user',
    MSPLink = 'MSP_Link',
    MSPdelink = 'MSP_delink',
    MSPlinkingrequest = 'MSP_linking_request',
    MSPAppAssignment = 'MSP_App_Assignment',
    AuthenticationCertificateAuthenticator = 'Authentication_CertificateAuthenticator',
    TenantBackground = 'Tenant_Background',
    CertificateAuthorityDelete  = 'Certificate_Authority_delete',
    ThirdPartyIntegrateApp = 'Third_Party_Integrate_App',
    SPClientlink = 'SP_Clientlink',
    RoleCreate = 'Add_role',
    RoleEdit = 'Edit_role',
    RoleProfile = 'Role_Profile',
    AssignRoleUser = 'Assign_role',
    UassignRoleUser = 'Assign_role',
    RoleActivate = 'Activate_role',
    RoleDeactivate = 'Activate_role',
    RoleDelete = 'Delete_role',
    UnblockCLA = 'Unblock_CLA',
    DeleteCLA = 'Delete_CLA',
    RenamePasskey = 'Rename_Passkey',
    MSPAliasName = 'MSP_Aliasname'

}