import {
    Component,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { ModalDialogService2 } from '../../shared/controls/dialogs';
import { HeaderService } from '../../shared/header/header.service';
import { ViewLocationEnum } from '../../shared/enums/ViewLocationEnum';
import { AuthService } from '../../services/auth.service';
import { AssociatedMspTenants } from 'src/app/models/AssociatedMspTenants';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { HelpURLKeys } from 'src/app/shared/enums/HelpURLKeys';


@Component({
    selector: 'msp-management-view',
    templateUrl: './msp-management.view.html',
    styleUrls: [
        './msp-management.view.scss'
    ]

})
export class MspManagementViewComponent implements OnInit, OnDestroy {
    public noTenants: boolean = true;
    public associatedMspTenants: AssociatedMspTenants[];    
    public loadingIndicator: boolean = true;
    private _disposalBag: DisposalBag = new DisposalBag();
    public selectedMspTenant:AssociatedMspTenants;
    public helpUrl: string = "";
    constructor(
        private _modalDialogService: ModalDialogService2,
        private _headerService: HeaderService,
        private companyService: CompanyService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.authService.isHeaderRequired.next(true);
        this._headerService.setCurrentLocation(ViewLocationEnum.MspSupport);
        this.getAssociatedTenants();
        let helpId = HelpURLKeys['MSPLink'];
        if (helpId) {
            this.helpUrl = `${this.authService.systemParams.HelpFileUrl}${helpId}`;

        }
    }

    public getAssociatedTenants(): void {
        this._disposalBag .nextSub = this.companyService.getAssociatedMspTenants().subscribe((result: AssociatedMspTenants[]) => {
            this.noTenants = !(result.length>0);
            this.loadingIndicator = false;
            if(this.noTenants) {
             return;
            }
            
   
             this.associatedMspTenants = result;
             this.selectMspTenant(this.associatedMspTenants[0]);
        });

    }

    public selectMspTenant(selectedTenant: AssociatedMspTenants){
        this.selectedMspTenant = {...selectedTenant};
    }

    ngOnDestroy(): void {
        this._disposalBag.dispose();
        this._modalDialogService.closeModal();
    }
}