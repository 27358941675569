import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { DBkeys } from 'src/app/services/db-keys';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { ModalDialogService2 } from '../../controls/dialogs';
import { ThemeType } from '../../enums/ThemeType';
import { ViewLocationEnum } from '../../enums/ViewLocationEnum';
import { DisposalBag } from '../../utils/DisposalBag';
import { HeaderService } from '../header.service';
import { AboutSotiIdentity } from '../Modals/aboutsotiidentity/aboutSotiIdentity.ctrl';
import { ContactUs } from '../Modals/contactUs/contactus.ctrl';
import { Permissions } from './../../enums/Permissions';
@Component({
    selector: 'menu-content',
    templateUrl: './menu-content.ctrl.html',
    styleUrls: ['./menu-content.ctrl.scss']
})
export class MenuContentControl implements OnInit, OnDestroy {        
    @Input()
    public hideTabCurrentPage: boolean = true;
    @Output('action')
    public action: EventEmitter<boolean> = new EventEmitter<boolean>();

    public usersceenpermissions : Permissions[] ;

    public applicationPermissions : Permissions[] ;

    public connectionPermissions : Permissions[] ;

    public companyPermissions : Permissions[];  

    public accountSettingPermissions : Permissions[];

    public domainPermission : Permissions[];
    public manageMSP: Permissions[];

    public policyPermissions: Permissions[];

    public tabselect: boolean = false;

    public currentUser: User;

    public companyIdDisplayValue:string = "";

    public isCompanyIdTooLong:boolean = false;
    public isDarkTheme:boolean = false;

    private _disposalBag: DisposalBag = new DisposalBag();
    @HostBinding('class.is-desktop')
    private _isDesktop: boolean = false;

    public _companyId: string = "";


    constructor( 
        private _modalDialogService: ModalDialogService2,
        private authService: AuthService,        
        private _browserUtilsService: BrowserUtilsService,
        private _headerService: HeaderService,
        private localStorage: LocalStoreManager,
        private router: Router,
        private _sotiThemeService: SotiThemeService
    ) {
        this._headerService.currentLocation.subscribe(val => {
            if (val === ViewLocationEnum.Dashboard) {
                this.tabselect = true;
            }
            else {                
                this.tabselect = false;
            }
        });
        
    }


    public ngOnInit(): void {
        
        this.currentUser = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
        
        this._companyId =  this.localStorage.getDataObject<Company>(DBkeys.CURRENT_COMPANY).TenantIdentifier;
        this.setCompanyIdDisplayValue();
        this.usersceenpermissions = [Permissions.ENABLEUSERSECTIONVIEW];
        this.applicationPermissions = [Permissions.ENABLEAPPLICATIONSECTIONVIEW];
        this.connectionPermissions = [Permissions.ENABLEDIRECTORYSECTIONVIEW];
        this.companyPermissions = [Permissions.CREATECOMPANY, Permissions.GETCOMPANY];
        this.accountSettingPermissions = [Permissions.ENABLEACCOUNTSECTIONVIEW];
        this.domainPermission = [Permissions.IDENTITYDOMAIN];
        this.policyPermissions = [Permissions.ENABLEPOLICYSECTIONVIEW];
        this.manageMSP = [Permissions.VIEWMSPTENANTPORTAL];

        this._disposalBag.nextSub = this._sotiThemeService.selectedTheme.subscribe((val) => {
            this.isDarkTheme = val === ThemeType.DARK;
        });
    }
    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }
    public onAboutClick(): void
    {
        this._modalDialogService.open(AboutSotiIdentity);
        this.action.emit(true);
    }

    public onLinkClick($event?: MouseEvent | TouchEvent): void {
        
        this.action.emit(true);
    }

    public onContactUsClick(): void {
        this._modalDialogService.open(ContactUs);
        this.action.emit(true);
    }
    
    public onHelpClick(): void {
        this._browserUtilsService.createNewBrowserWindow(this.authService.systemParams.HelpFileUrl);
    }
    public onClickSotiOneUrl(): void {
        this.router.navigate([Constants.DashboardPath]);
    }

    public checkPolicyPermission(): boolean {
        if((this.currentUser.UserPermissions.indexOf(Permissions.MANAGEGOOGLEAUTHENTICATIONFACTOR)!=-1) ||
        (this.currentUser.UserPermissions.indexOf(Permissions.MANAGEEMAILAUTHENTICATIONFACTOR)!=-1)||
        (this.currentUser.UserPermissions.indexOf(Permissions.MANAGEDUOAUTHENTICATIONFACTOR)!=-1)||
        (this.currentUser.UserPermissions.indexOf(Permissions.MANAGEMICROSOFTAUTHENTICATIONFACTOR)!=-1))
        {
            return true;
        } 
        
        return false;
    }

    private setCompanyIdDisplayValue(): void {
        if(this._companyId){
            if(this._companyId.length > Constants.CompanyIDDisplayLengthLimit){
                this.companyIdDisplayValue = this._companyId.substring(0,Constants.CompanyIDDisplayLengthLimit - 3);
                this.isCompanyIdTooLong = true;
            }
            else{
                this.companyIdDisplayValue = this._companyId.substring(0,this._companyId.length);
                this.isCompanyIdTooLong = false;
            }
        }
    }
}
