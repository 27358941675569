import {NgModule} from '@angular/core';
import {ContextualActionBar} from './contextual-action-bar/action-bar.ctrl';
import {MoreActionItemsComponent} from './contextual-action-bar/more-action-items/more-action-items.ctrl';
import {CoreCommonModule} from '../../core-common.module';
import {SharedDialogsModule} from '../dialogs/index';
import {SharedInlineSearchModule} from '../inline-search/shared-inline-search.module';
import { SharedTooltipModule } from '../tooltip';
import { SharedHelpModule } from '../../internal-controls/help/shared-help.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../../../services/app-translation.service';
import { SharedModule } from '../../shared.module';
import { SharedPaginationModule } from '../../soti-node-modules/pagination';

@NgModule({
    imports: [
        CoreCommonModule,
        SharedDialogsModule,
        SharedInlineSearchModule,
        SharedTooltipModule,
        SharedHelpModule,
        SharedDialogsModule,
        SharedPaginationModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [
        ContextualActionBar,
        MoreActionItemsComponent
    ],
    exports: [
        ContextualActionBar
    ]
})
export class SharedActionBarModule {

}