import {NgModule} from '@angular/core';
import {VirtualScrollControl} from './virtual-scroll.ctrl';
import {SharedModule} from '../../shared.module';

@NgModule({
    imports: [
         
    ],
    declarations: [
        VirtualScrollControl
    ],
    exports: [
        VirtualScrollControl
    ]
})
export class SharedVirtualScrollModule {

}