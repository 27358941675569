import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from '../pipes/filter.pipe';
import { TextFilter } from '../pipes/text-filter.pipe';
import { CharacterCountDirective } from './directives/character-count/character-count.directive';
import { SharedLoadingDirectiveModule } from './directives/loading/shared-loading.module';
import { NumberOnlyDirective } from './directives/number-only/numberOnly.directive';
import { ResizeDirective } from './directives/resize/resize.directive';
import { ScopeVarDirective } from './directives/scope-var/scope-var.directive';
import { SetFocusDirective } from './directives/set-focus/set-focus.directive';
import { ShowMoreDetailDirective } from './directives/show-more-detail/show-more-detail.directive';
import { TrapFocusDirective } from './directives/trap-focus/trapFocus.directive';
import { HasPermissionDirective } from './directives/user-permission/checkAccess.directive';
import { FileUploader } from './internal-controls/file-uploader/file-uploader.ctrl';
import { DatePipe } from './pipes/date.pipe';
import { GroupFilterPipe } from './pipes/group-filter.pipe';
import { TabbingClickDirective } from './directives/accessibility/tab-index.directive';
import { AnnounceFieldValidationDirective } from './directives/accessibility/AnnounceFieldValidation/announce-field-validation.directive';
import { ValidateFormOnSubmissionDirective } from './directives/accessibility/ValidateFormOnSubmission/validate-form-on-submission.directive';
import { AnnounceButtonClickDirective } from './directives/accessibility/AnnounceButtonClick/announce-button-click.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppTranslationService, TranslateLanguageLoader } from 'src/app/services/app-translation.service';
import { ScrollToViewDirective } from './directives/scroll/scroll-to-view.directive';
import { TrimValueAccessorDirective } from './directives';


@NgModule({
    imports: [
        CommonModule,
        SharedLoadingDirectiveModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
    ],
    declarations: [
        DatePipe,
        GroupFilterPipe,
        TrapFocusDirective,
        SetFocusDirective,
        ScopeVarDirective,
        ResizeDirective,
        NumberOnlyDirective,
        CharacterCountDirective,
        TabbingClickDirective,
        HasPermissionDirective,
        FileUploader,
        FilterPipe,
        ShowMoreDetailDirective,
        TextFilter,
        AnnounceFieldValidationDirective,
        ValidateFormOnSubmissionDirective,
        AnnounceButtonClickDirective,
        ScrollToViewDirective,
        TrimValueAccessorDirective
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ScopeVarDirective,
        DatePipe,
        GroupFilterPipe,
        TrapFocusDirective,
        SetFocusDirective,
        HasPermissionDirective,
        ResizeDirective,
        SharedLoadingDirectiveModule,
        CharacterCountDirective,
        TabbingClickDirective,
        NumberOnlyDirective,
        FileUploader,
        FilterPipe,
        ShowMoreDetailDirective,
        TextFilter,
        AnnounceFieldValidationDirective,
        ValidateFormOnSubmissionDirective,
        AnnounceButtonClickDirective,
        ScrollToViewDirective,
        TrimValueAccessorDirective
    ],
    providers: [
        AppTranslationService
    ]
})
export class CoreCommonModule {
}
