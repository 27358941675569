import { Injectable } from "@angular/core";
import { AzureADEndpoint } from "./azure-ad-endpoint.services";
import { oAuthBaseConnectionModel } from "../../models/oAuthBaseConnectionModel";
import { oAuthBaseLoginUrlParams  } from '../../models/oAuthBaseLoginUrlParams';
import { oAuthBaseConnectionFilterParams } from '../../models/oAuthBaseConnectionFilterParams';
import { Observable } from "rxjs";

@Injectable()
export class AzureADService {
    constructor(private azureADEndpoint: AzureADEndpoint){

    }
    public getAzureADLoginUrlParams(name: string, selectedDomains: string[], eventType: number, userStatusSync: boolean = true): Observable<oAuthBaseLoginUrlParams> {
        return this.azureADEndpoint.getAzureADLoginUrlParams(name,selectedDomains, eventType,userStatusSync);
    }
    public getAzureADConnections(azureADConnectionFilterparams?: oAuthBaseConnectionFilterParams): Observable<oAuthBaseConnectionModel[]>{
       return this.azureADEndpoint.getAzureADConnections(azureADConnectionFilterparams);
    }
    public getAzureADConnectionDataById(connectionId: string): Observable<oAuthBaseConnectionModel> {
        return this.azureADEndpoint.getAzureADConnectionDataById(connectionId);
    }
    public updateAzureADConnection(param: oAuthBaseConnectionModel): Observable<oAuthBaseConnectionModel> {
        return  this.azureADEndpoint.updateAzureADConnection(param);
    }
}