
import { ApplicationUser } from './../../models/applicationUser.model';
import { Group } from './../../models/group.model';
import { PrincipalUserRoles } from './../../models/principalUserRoles.model';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FilteringParams } from '../../models/filteringparams';
import { GAuthModel } from '../../models/gAuth.model';
import { ResetPassword } from '../../models/resetpassword.model';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';

import { List } from 'lodash';
import { AssignUserGroupModel } from 'src/app/models/AssignUserGroup.model';
import { ChangePassword } from 'src/app/models/ChangePassword.model';
import { ExpiredPasswordLinkModel } from 'src/app/models/ExpiredPasswordLinkModel';
import { UserBackgroundImage } from 'src/app/models/UserBackgroundImage';
import { UserPasskeyList } from 'src/app/models/UserPasskeyList';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { UserSearchParams } from 'src/app/models/UserSearchParams.model';
import { AddBulkUsersCsv } from 'src/app/models/addBulkUsersCsv';
import { AssignedApplicationModel } from 'src/app/models/assignedApplication.model';
import { GroupListUI } from 'src/app/models/group-list-ui.model';
import { Ldap } from 'src/app/models/ldapdirectory';
import { MicrosoftAuthModel } from 'src/app/models/microsoftAuthModel';
import { NotificationResponse } from 'src/app/models/notificationResponse.model';
import { UserApplication } from 'src/app/models/userApplication.model';
import { UserApplications } from 'src/app/models/userApplications.model';
import { ForgotCompany } from '../../models/ForgotCompany.modal';
import { Application } from '../../models/application.model';
import { Roles } from '../../models/applicationRoles.model';
import { LdapUserGroups } from '../../models/ldapUserGroups.model';
import { ListResponse } from '../../models/list-response.model';
import { UserListUI } from '../../models/user-list-ui.model';
import { User } from '../../models/user.model';
import { GetApplicationsResponseModel } from 'src/app/models/GetApplicationsResponseModel.model';
import { _throw } from 'rxjs/observable/throw';
import { CompanyListUI } from 'src/app/models/company-list-ui.model';

@Injectable()
export class UserEndpoint extends EndpointFactory {

    // tslint:disable-next-line:no-any
    public countriesCode: Subject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    private readonly _getUserRolesforAplication: string = 'UserRole';
    private readonly _usersUrl: string = 'User';
    private readonly _groupsUrl: string = 'AssignGroup';

    private readonly _rolesUrl: string = '/Roles';
    private readonly _registerPasskeyPayloadUrl: string = 'User/CredentialOptions';
    private readonly _registerPasskeysUrl: string ='User/Credentials';
    private readonly _deletePasskeyUrl:string ='User/DeletePasskey';
    private readonly _updatePasskeyUrl:string ='User/passkey';
    private readonly _userPasskeyDataUrl: string = 'User/UserPasskeyData';
    private readonly _connectionUrl: string = '/Connection';
    private readonly _ldapUserUrl: string = 'User/ExternalUsers';
    private readonly _currentUserUrl: string = 'user/users/me';
    private readonly _searchUserUrl: string = 'user/search';
    private readonly _verifyDuplicateUserUrl: string = 'user/VerifyDuplicateUserEmail/';
    private readonly _verifyDuplicateUserNameUrl: string = 'user/VerifyDuplicateUserName/';
    private readonly _resetpasswordUrl: string = '/ResetPassword';
    private readonly _resetUserpasswordUrl: string = '/ResetUserPassword';
    private readonly _forceResetpasswordUrl: string = 'User/ForceResetPassword';
    private readonly _setUpGAuthUrl: string = '/SetupGAuth';
    private readonly _setUpMicrosoftAuthUrl: string = '/SetupMicrosoftAuth';
    private readonly _sendResetPasswordLinkUrl: string = '/SendResetPasswordLink';
    private readonly _sendExpiredPasswordLink: string = '/SendExpiredPasswordLink';
    private readonly _userAppsUrl: string = '/Applications';
    private readonly _deleteUserUrl: string = 'User/BulkDelete';
    private readonly _unlockUserUrl: string = 'User/Unlock';
    private readonly _deactivateUserUrl: string = 'User/Deactivate';
    private readonly _activateUserUrl: string = 'User/Activate';
    private readonly _updateIdpUserGroup: string = 'User/UpdateIdpUserGroup';
    private readonly _getCurrentUserUrl: string = 'User/UserInfo';
    private readonly _changePasswordUrl: string = 'User/ChangePassword';
    private readonly _assignedApplicationsUrl: string = '/ApplicationsRoles';
    private readonly _filterUserUrl: string = 'user/FilterUser';
    private readonly _templateDownloadUrl: string = 'User/BulkUsersUploadTemplate';
    private readonly _userListDownloadUrl: string = 'User/FilterUser/Download';
    private readonly _preferencesUrl: string = 'Preferences';
    private readonly _backgroundImage: string = 'BackgroundImage';
    private readonly _backgroundImages: string = 'BackgroundImages';
    private readonly _passkeyurl : string = 'Passkey';
    private readonly _mspTenantsUrl: string = 'AssignedMspTenants';

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

        super(http, configurations, injector);
    }

    public getUsersEndpoint(param: FilteringParams): Observable<ListResponse<UserListUI>> {
        if (param.SearchType && param.SearchType.length > 0) {
            param.UserTypes = param.SearchType;
        }
        return this.http.post<ListResponse<UserListUI>>(this.getFilterUserUrl, param, this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getGroupsEndpoint(param: FilteringParams): Observable<ListResponse<GroupListUI>> {
        if (param.SearchType && param.SearchType.length > 0) {
            param.UserTypes = param.SearchType;
        }
        return this.http.post<ListResponse<GroupListUI>>(this.getFilterUserUrl, param, this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUserEndpoint(emailId?: string): Observable<User> {
        const endpointUrl = `${this.usersUrl}/${emailId}`;

        return this.http.get<User>(endpointUrl, this.getRequestHeaders()).pipe()
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUpdateUserEndpoint<T>(userObject: any, Id?: string): Observable<T> {
        const endpointUrl = `${this.usersUrl}/${Id}`;

        return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUserByUserNameEndpoint<T>(userName: string): Observable<T> {
        const endpointUrl = `${this.usersUrl}/${userName}`;

        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public createLdapUserEndpoint(userObject: LdapUserGroups): Observable<User> {
        return this.http.post<User>(this.ldapUserUrl, JSON.stringify(userObject), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public createDbUserEndpoint(userObject: User): Observable<User> {
        return this.http.post<User>(this.usersUrl, JSON.stringify(userObject), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public resetPassword(ReferenceId: string, model: ResetPassword): Observable<void> {
        const endpointUrl = `${this.usersUrl}/${ReferenceId}` + this.resetPasswordUrl;
        return this.http.post<void>(endpointUrl, JSON.stringify(model), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public resetPasswordByAdmin(id: string, model: ChangePassword): Observable<void> {
        const endpointUrl = `${this.usersUrl}/${id}` + this.resetUserPasswordUrl;
        return this.http.post<void>(endpointUrl, JSON.stringify(model), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public setUpGAuth(ReferenceId: string, model: GAuthModel, languageCode : string): Observable<boolean> {
        const endpointUrl = `${this.usersUrl}/${ReferenceId}` + this.setUpGAuthUrl + `/${languageCode}`;
        return this.http.post<boolean>(endpointUrl, JSON.stringify(model), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public setUpMicrosoftAuth(ReferenceId: string, model: MicrosoftAuthModel, languageCode : string): Observable<boolean> {
        const endpointUrl = `${this.usersUrl}/${ReferenceId}` + this.setUpMicrosoftAuthUrl + `/${languageCode}`;
        return this.http.post<boolean>(endpointUrl, JSON.stringify(model), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public forceResetPassword(userIds: string[]): Observable<void> {
        const endpointUrl = this.forceResetPasswordUrl;
        return this.http.post<void>(endpointUrl, userIds, this.getRequestHeaders());
    }

    public SendExpiredPasswordLink(expiredPasswordLinkModel: ExpiredPasswordLinkModel): Observable<void> {
        return this.http.post<void>(`${this.usersUrl}` + this.sendExpiredPasswordLink, JSON.stringify(expiredPasswordLinkModel), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getApplicationsEndpoint(userReferenceId: string): Observable<GetApplicationsResponseModel<Application>> {
        const endpointUrl = `${this.usersUrl}/${userReferenceId}` + this.userAppUrl;
        return this.http.get<GetApplicationsResponseModel<Application>>(endpointUrl, this.getRequestHeaders());
    }

    public getLongLoadingApplications(applicationType: number ,applicationreferenceIds: string[]): Observable<Application[]> {
        const endpointUrl = `${this.usersUrl}` + this.userAppUrl + `/${applicationType}`;
        return this.http.post<Application[]>(endpointUrl, applicationreferenceIds, this.getRequestHeaders());
    }

    public getAssignedApplications(userReferenceId: string): Observable<AssignedApplicationModel[]> {
        const endpointUrl = `${this.usersUrl}/${userReferenceId}${this._assignedApplicationsUrl}`;
        return this.http.get<AssignedApplicationModel[]>(endpointUrl, this.getRequestHeaders());
    }

    public getPasskeyConfigData(): Observable<any> {
        let endpointUrl = `${this.getRegisterPasskeyPayload}`;
        return this.http.get<any>(endpointUrl, this.getRequestHeaders()).catch(error => {
            return Observable.throw(error);
        });
    }

    public registerPasskeys(model:any): Observable<any> {
        const endpointUrl = this.getAddPasskey;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<void>(endpointUrl, JSON.stringify(model), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUserPasskeyData(): Observable<List<UserPasskeyList>> {
         let endpointUrl = `${this.getUserPasskeyUrl}`;
        return this.http.get<List<UserPasskeyList>>(endpointUrl, this.getRequestHeaders()).catch(error => {
            return Observable.throw(error);
        });
    }

    public deletePasskeys(userRefId: string, passkeyRefIds: string[]): Observable<any>{
        let endpointUrl = `${this.usersUrl}/${userRefId}/${this.getPasskeyUrl}`;
        let options ={
            ...this.getRequestHeaders(),
            body: passkeyRefIds
        };
        return this.http.delete<List<UserPasskeyList>>(endpointUrl,options).catch(error => {
            return Observable.throw(error);
        });
    }

    public updatePasskeysStatus(userRefId: string, passkeyRefIds: string[], status: number): Observable<any>{
        let endpointUrl = `${this.usersUrl}/${userRefId}/${this.getPasskeyUrl}/${status}`;
        let body = {
            passkeyRefIds: passkeyRefIds.map(id => id.toString()),
            status: status
          };
        return this.http.patch<List<UserPasskeyList>>(endpointUrl,passkeyRefIds,this.getRequestHeaders()).catch(error => {
            return Observable.throw(error);
        });
    }

    public getPasskeyData(userRefId: string): Observable<List<UserPasskeyList>> {
        let endpointUrl = `${this.usersUrl}/${this.getPasskeyUrl}/${userRefId}`;
       return this.http.get<List<UserPasskeyList>>(endpointUrl, this.getRequestHeaders()).catch(error => {
           return Observable.throw(error);
       });
   }

    public deletePasskeyEndpoint(passkeyRefId: string): Observable<void> {
        let endpointUrl = `${this.deletePasskeyUrl}`;
       return this.http.post<void>(endpointUrl, JSON.stringify(passkeyRefId), this.getRequestHeaders()).catch(error => {
           return Observable.throw(error);
       });
   }
   public updatePasskeyEndpoint(passkeyRefId:string, passkeyName : string): Observable<void> {
        // // let endpointUrl = `${this.updatePasskey}`;
        let endpointUrl = `${this.updatePasskey}/${passkeyRefId}`;
       return this.http.put<void>(endpointUrl, JSON.stringify(passkeyName), this.getRequestHeaders()).catch(error => {
           return Observable.throw(error);
       });
   }

    public deleteUsersEndpoint(userIds: string[]): Observable<string> {
        const endpointUrl = this.deleteUserUrl;
        return this.http.post<string>(endpointUrl, userIds, this.getRequestHeaders());
    }
    public unlockUsers(userIds: string[]): Observable<void> {
        const endpointUrl = this.unlockUserUrl;
        return this.http.post<void>(endpointUrl, userIds, this.getRequestHeaders());
    }

    public deactivateUsers(userIds: string[]): Observable<string> {
        const endpointUrl = this.deactivateUserUrl;
        return this.http.post<string>(endpointUrl, userIds, this.getRequestHeaders());
    }

    public activateUsers(userIds: string[]): Observable<string> {
        const endpointUrl = this.activateUserUrl;
        return this.http.post<string>(endpointUrl, userIds, this.getRequestHeaders());
    }

    public searchUserEndpoint(model: UserSearchParams): Observable<ApplicationUser[]> {
        return this.http.post<ApplicationUser[]>(this.searchUsersUrl, JSON.stringify(model), this.getRequestHeaders());
    }

    public verifyDuplicateEmail(userEmail: string): Observable<boolean> {
        return this.http.post<boolean>(this.verifyDuplicateUserUrl, JSON.stringify(userEmail), this.getRequestHeaders());
    }

    public getUserRolesEndpoint(userReferenceId: string): Observable<PrincipalUserRoles[]> {
        const endpointUrl = `${this.getUserRolesUrl}/${userReferenceId}${this.getRolesUrl}`;

        return this.http.get<PrincipalUserRoles[]>(endpointUrl, this.getRequestHeaders()).pipe()
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public verifyLastIdentityAccountAdministrator(userId: string): Observable<boolean> {
        let endpointUrl: string = `${this.usersUrl}/${userId}/VerifyLastIdentityAccountAdministrator`;
        return this.http.get<boolean>(endpointUrl, this.getRequestHeaders()).pipe()
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUserConnectionEndpoint(userReferenceId: string): Observable<Ldap> {
        const endpointUrl = `${this.getUserRolesUrl}/${userReferenceId}${this.getConnectionUrl}`;

        return this.http.get<Ldap>(endpointUrl, this.getRequestHeaders()).pipe()
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUserDetails(): Observable<User> {
        const endpointUrl = this.getCurrentUserUrl;
        return this.http.get<User>(endpointUrl, this.getRequestHeaders());

    }

    public getUserDetailsByReferenceId(userRefereneId: string): Observable<User> {
        let endpointUrl = `${this.usersUrl}/${userRefereneId}`;
        return this.http.get<User>(endpointUrl, this.getRequestHeaders()).catch(error => {
            return Observable.throw(error);
        });
    }
    public updateLocalUser(userObject: User): Observable<string> {

        let userId = userObject.ReferenceId;
        let endpointUrl = `${this.usersUrl}/${userId}`;

        return this.http.put<string>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public updateLdapUserRoles(userReferenceId: string, applicationReferenceId: string, roleIds: string[]): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${userReferenceId}/${applicationReferenceId}`;
        return this.http.put<void>(endpointUrl, JSON.stringify(roleIds), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public updateIdpUserGroup(userGroup: Group): Observable<void> {
        let endpointUrl = `${this.getupdateIdpUserGroupUrl}`;
        return this.http.put<void>(endpointUrl, JSON.stringify(userGroup), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public verifyDuplicateUserName(userName: string): Observable<boolean> {
        return this.http.post<boolean>(this.verifyDuplicateUserNameUrl, JSON.stringify(userName), this.getRequestHeaders());
    }

    public acceptEula(userReferenceId: string): Observable<void> {
        let endpointUrl: string = `${this.usersUrl}/${userReferenceId}/EulaAccepted`;
        return this.http.post<void>(endpointUrl, null, this.getRequestHeaders());
    }


    public changePassword(token: string, model: ChangePassword): Observable<void> {
        let endpointUrl = `${this.usersUrl}/ChangePassword`;
        return this.http.put<void>(endpointUrl, JSON.stringify(model), this.getRequestHeadersByToken(token))
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public userAssignApplications(userId: number, userApplications: UserApplications[]): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${userId}/AssignApplications`;
        return this.http.post<void>(endpointUrl, JSON.stringify(userApplications), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUserRolesByApplicationIdEndPoint(applicationReferenceId: string, userReferenceId: string): Observable<Roles[]> {
        let endpointUrl = `${this.configurations.baseUrl}${this._usersUrl}/${userReferenceId}/${this._getUserRolesforAplication}/${applicationReferenceId}`;
        let options = new HttpParams();
        return this.http.get<Roles[]>(endpointUrl, { headers: this.getHeaders(), params: options, withCredentials: true });
    }

    public UserAssignApplicationEndPoint(userId: string, applicationId: string, userApplication: UserApplication): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${userId}/applications/${applicationId}`;
        return this.http.post<void>(endpointUrl, JSON.stringify(userApplication), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public forgotCompany(forgotCompany: ForgotCompany): Observable<boolean> {
        let endpointUrl = `${this.usersUrl}/SendForgotCompany`;
        return this.http.post<boolean>(endpointUrl, JSON.stringify(forgotCompany), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public templateDownloadUrlEndPoint(): string {
        return this.getTemplateDownloadUrl;
    }

    public userListDownloadUrlEndPoint(): string {
        return this.getUserListDownloadUrl;
    }
    public ValidateBulkUsersCsv(param: AddBulkUsersCsv): Observable<void> {
        let endpointUrl = `${this.usersUrl}/ValidateBulkUsersCsv`;
        const uploadData = new FormData();
        if (param.file !== undefined && param.file !== null) {
            uploadData.append('BulkUsersCsv', param.file, param.file.name);
        }
        return this.http.post<void>(endpointUrl, uploadData, this.getFormDataRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public BulkUsersUpload(param: AddBulkUsersCsv): Observable<NotificationResponse> {
        let endpointUrl = `${this.usersUrl}/Bulk`;
        const uploadData = new FormData();
        if (param.file !== undefined && param.file !== null) {
            uploadData.append('BulkUsersCsv', param.file, param.file.name);
        }
        return this.http.post<NotificationResponse>(endpointUrl, uploadData, this.getFormDataRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public UnassignUserApplication(userReferenceId: string, applicationReferenceId: string): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${userReferenceId}/applications/${applicationReferenceId}`;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders()).catch(error => {
            return Observable.throw(error);
        });
    }

    public AssignGroups(userReferenceId: string, groups: AssignUserGroupModel[]): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${userReferenceId}/${this._groupsUrl}`;
        return this.http.post<void>(endpointUrl, JSON.stringify(groups), this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getPreferences(): Observable<UserPreferenceModel[]> {
        let endpointUrl = `${this.usersUrl}/${this._preferencesUrl}`;
        return this.http.get<UserPreferenceModel[]>(endpointUrl, this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public savePreferences(userPreference: UserPreferenceModel[]): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${this._preferencesUrl}`;
        return this.http.post<void>(endpointUrl, userPreference, this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public getUserBackgroundImages(userRefId: string): Observable<UserBackgroundImage[]> {
        let endpointUrl = `${this.usersUrl}/${this._backgroundImages}/${userRefId}`;
        return this.http.get<UserBackgroundImage[]>(endpointUrl, this.getRequestHeaders())
            .catch(error => {
                return Observable.throw(error);
            });
    }

    public postCustomBackgroundImage(file: File): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${this._backgroundImage}`;
        const uploadData = new FormData();
        if (file !== undefined && file !== null) {
            uploadData.append('backgroundImage', file, file.name);
        }
        return this.http.post<void>(endpointUrl, uploadData, this.getFormDataRequestHeaders());
    }

    public deleteCustomBackgroundImage(refid: string): Observable<void> {
        let endpointUrl = `${this.usersUrl}/${this._backgroundImage}/${refid}`;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders())
    }

    public getAssignedMspTenants(userRefereneId: string): Observable<CompanyListUI[]> {
        let endpointUrl = `${this.usersUrl}/${userRefereneId}/${this._mspTenantsUrl}`;
        return this.http.get<CompanyListUI[]>(endpointUrl, this.getRequestHeaders())
            .catch(error => {
                return _throw(error);
            });
    }

    public AssignTenantsToUser(userReferenceId: string, childTenantList: string[]): Observable<void> {
        const endpointUrl = `${this.usersUrl}/${userReferenceId}/${this._mspTenantsUrl}`;
        return this.http.post<void>(endpointUrl, JSON.stringify(childTenantList), this.getRequestHeaders())
            .catch(error => {
                return _throw(error);
            });
    }

    public UnassignTenantFromUser(childTenantReferenceId: string, userReferenceId: string): Observable<void> {
        const endpointUrl = `${this.usersUrl}/${childTenantReferenceId}/${this._mspTenantsUrl}/${userReferenceId}`;
        return this.http.delete<void>(endpointUrl, this.getRequestHeaders())
            .catch(error => {
                return _throw(error);
            });
    }

    public getUserEmailsByGroupdId(groupReferenceIds: string[]): Observable<string[]> {
        const endpointUrl = `${this.usersUrl}/EmailsByGroupIds`;
        return this.http.post<string[]>(endpointUrl, groupReferenceIds, this.getRequestHeaders())
            .catch(error => {
                return _throw(error);
            });
    }

    private get usersUrl(): string { return this.configurations.baseUrl + this._usersUrl; }
    private get ldapUserUrl(): string { return this.configurations.baseUrl + this._ldapUserUrl; }
    private get currentUserUrl(): string { return this.configurations.baseUrl + this._currentUserUrl; }
    private get resetPasswordUrl(): string { return this._resetpasswordUrl; }
    private get resetUserPasswordUrl(): string { return this._resetUserpasswordUrl; }
    private get forceResetPasswordUrl(): string { return this.configurations.baseUrl + this._forceResetpasswordUrl; }

    private get setUpGAuthUrl(): string { return this._setUpGAuthUrl; }
    private get setUpMicrosoftAuthUrl(): string { return this._setUpMicrosoftAuthUrl; }
    private get sendExpiredPasswordLink(): string { return this._sendExpiredPasswordLink; }
    private get sendResetPasswordLinkUrl(): string { return this._sendResetPasswordLinkUrl; }
    private get searchUsersUrl(): string { return this.configurations.baseUrl + this._searchUserUrl; }
    private get userAppUrl(): string { return this._userAppsUrl; }

    private get getCurrentUserUrl(): string { return this.configurations.baseUrl + this._getCurrentUserUrl; }

    private get deleteUserUrl(): string { return this.configurations.baseUrl + this._deleteUserUrl; }
    private get unlockUserUrl(): string { return this.configurations.baseUrl + this._unlockUserUrl; }
    private get deactivateUserUrl(): string { return this.configurations.baseUrl + this._deactivateUserUrl; }
    private get activateUserUrl(): string { return this.configurations.baseUrl + this._activateUserUrl; }
    private get getUserRolesUrl(): string { return this.configurations.baseUrl + this._usersUrl; }
    private get getRolesUrl(): string { return this._rolesUrl; }
    private get getRegisterPasskeyPayload(): string { return this.configurations.baseUrl +  this._registerPasskeyPayloadUrl; }
    private get getAddPasskey(): string { return this.configurations.baseUrl +  this._registerPasskeysUrl; }
    private get deletePasskeyUrl(): string { return this.configurations.baseUrl +  this._deletePasskeyUrl; }
    private get updatePasskey(): string { return this.configurations.baseUrl +  this._updatePasskeyUrl; }
    private get getUserPasskeyUrl(): string {return this.configurations.baseUrl + this._userPasskeyDataUrl;}
    private get getPasskeyUrl(): string {return this._passkeyurl}
    private get getConnectionUrl(): string { return this._connectionUrl; }
    private get verifyDuplicateUserUrl(): string { return this.configurations.baseUrl + this._verifyDuplicateUserUrl; }
    private get getupdateIdpUserGroupUrl(): string { return this.configurations.baseUrl + this._updateIdpUserGroup; }
    private get verifyDuplicateUserNameUrl(): string { return this.configurations.baseUrl + this._verifyDuplicateUserNameUrl; }
    private get getFilterUserUrl(): string { return this.configurations.baseUrl + this._filterUserUrl; }
    private get getTemplateDownloadUrl(): string { return this.configurations.baseUrl + this._templateDownloadUrl; }
    private get getUserListDownloadUrl(): string { return this.configurations.baseUrl + this._userListDownloadUrl; }
}

