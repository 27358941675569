import { Injectable } from '@angular/core';
import { interval, Subscription, ReplaySubject } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Constants } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class InactivityService implements HttpInterceptor {  
  private sessionDuration: number = 0; 
  private warningThreshold: number = Constants.DefaultSessionWarningTime * 1000; 
  private inactivityThreshold: number = 0; 
  private lastActivityTime: number = new Date().getTime(); 
  private timeElapsed: number = 0; 
  private activitySubscription: Subscription = new Subscription(); 
  private pollingActive: boolean = true;
  private resetDisabled: boolean = false; 

  inactivityAlert: ReplaySubject<number> = new ReplaySubject(1);

  constructor() { }

  public setSessionDuration(durationInHours: number) {
    // // this.sessionDuration = durationInHours * 60 * 60 * 1000;  
    // // this.inactivityThreshold = this.sessionDuration - this.warningThreshold; 

    this.sessionDuration = 6 * 60 * 1000;  
    this.inactivityThreshold = this.sessionDuration - this.warningThreshold; 
   
  }

  public resetInactivityTimer() {
    if(!this.resetDisabled){
    this.inactivityThreshold = this.sessionDuration - this.warningThreshold;
    this.lastActivityTime = new Date().getTime();  
    this.timeElapsed = 0; 
    this.pollingActive = true; 
    }
  }

  public startInactivityCheck() {
    this.stopInactivityCheck();  
    this.activitySubscription = interval(1000).subscribe(() => {  
      const currentTime = new Date().getTime();
      this.timeElapsed = currentTime - this.lastActivityTime; 

      if (this.timeElapsed >= this.inactivityThreshold) {
        this.inactivityAlert.next(this.warningThreshold);  
        this.resetDisabled = true; 
        this.stopInactivityCheck();  
      }
    });
  }

  public stopInactivityCheck() {
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();  
      this.pollingActive = false; 
    }
  }

  public enableResetTimer() {
    this.resetDisabled = false; 
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.status === 200 || event.status === 204) {
            this.resetInactivityTimer();  
            this.startInactivityCheck(); 
          }
        }
      },
      (error: HttpErrorResponse) => {
        console.error('HTTP Interceptor: HTTP error occurred:', error);
      })
    );
  }
}
