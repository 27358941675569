import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    public parse(url: string): UrlTree {
        let browserUtilsService: BrowserUtilsService = new BrowserUtilsService();

        try {
            const normalizedUrl = browserUtilsService.getUrlPart(url).toLowerCase() + browserUtilsService.getQueryString(url);
            return super.parse(normalizedUrl);
        } catch (error) {
            console.error('Error occurred while parsing the URL:', error);
            // Return a fallback UrlTree
            return super.parse('/error'); 
        }
    }
}
