export class ActionBarOptions {
    public actionsContext: string;
    public openMenuUp: boolean;
    public hideSearch: boolean;
    public helpId: string;

    constructor(obj: Partial<ActionBarOptions>) {
        if (!obj) {
            obj = {};
        }

        this.actionsContext = obj.actionsContext;
        this.openMenuUp = obj.openMenuUp || false;
        this.hideSearch = obj.hideSearch || false;
        this.helpId = obj.helpId || null;
    }
}