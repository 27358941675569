/**
 * Created by spentland on 2016-06-16.
 */
//TODO: Disabling no-input-rename because this is an old component and it would take a long time to fix.
/*tslint:disable:no-input-rename*/
import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {IContextualActionBarItem} from './model/IContextualActionBarItem';
import {PopUpTrigger} from '../../dialogs/pop-up';
import { logger } from '../../../utils/Logger';

/**
 * Generic action bar to receive a number of selected items and allowed actions
 */

@Component({
    selector: 'action-bar',
    templateUrl: './action-bar.ctrl.html',
    styleUrls: ['./action-bar.ctrl.scss']
})
export class ContextualActionBar implements OnChanges {
    /*
     All actions this particular CAB instance will hold
     */
    @Input()
    public actions: IContextualActionBarItem[] = [];

    // @Input('more-actions')
    // public moreActions: IContextAction[] = [];
    @Input() public deviceCount: number;

    @Input('menu-up')
    public menuUp: boolean = false;

    @Input('actions-context')
    public actionsContext: string = '';

    @Input('hide-search')
    public hideSearch: boolean = false;

    @Input('help-id')
    public helpId: string = '';

    @Input()
    public placement: string = '';

    /*
     Event emitted when clicked
     */
    @Output()
    public actionClicked: EventEmitter<{}> = new EventEmitter<{}>();

    @ViewChild(PopUpTrigger)
    public popUpTrigger: PopUpTrigger;

    public isExpanded: boolean = false;
    public primaryActions: IContextualActionBarItem[] = [];
    public prominentActions: IContextualActionBarItem[] = [];


    public constructor() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.isExpanded = false;

        if (changes['actions']) {
            this.primaryActions = this._getPrimaryActions();
            this.prominentActions = this._getProminentActions();
        }

        if (this.deviceCount == 0 && this.popUpTrigger) {
            this.popUpTrigger.close();
        }
    }


    //region Template bound functions
    public allActions(): IContextualActionBarItem[] {
        return this.actions;
    }

    public onActionClicked(item: IContextualActionBarItem): void {
        if (this.popUpTrigger) {
            this.popUpTrigger.close();
        }

        this.actionClicked.emit(item.data);
    }

    //endregion

    private _getPrimaryActions(): IContextualActionBarItem[] {
        if (!this.actions || this.actions.length === 0) {
            return [];
        }

        return this.actions.filter(a => a.isPrimary && !a.isProminentAction);

    }

    private _getProminentActions(): IContextualActionBarItem[] {
        if (!this.actions || this.actions.length === 0) {
            return [];
        }

        return this.actions.filter(a => a.isProminentAction);
    }
}
