import { PortalTypes } from './../../shared/enums/PortalTypes';
import { LanguageTypeEnum } from './../../shared/enums/LanguageTypeEnum';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyService } from '../company-services/company.service';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { TenantBackgroundImages } from 'src/app/models/tenantBackgroundImages.model';
import { BackgroundTypeEnum } from 'src/app/shared/enums/backgroundTypeEnum';
import { Utilities } from '../utilities';
import { TenantLogo } from 'src/app/models/tenantLogoImages.model';
import { LocalStoreManager } from '../local-store-manager.service';
import { PreferenceLocalStorageModel } from 'src/app/models/PreferenceLocalStorageModel';
import { UserService } from '../user-services/user.service';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { ViewType } from 'src/app/shared/controls/listgrid-btn-switcher/listgrid-btn-switcher.ctrl';
import { UserBackgroundImage } from 'src/app/models/UserBackgroundImage';
import { User } from 'src/app/models/user.model';
import { DBkeys } from '../db-keys';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/constants';

////////////////////////////////////////////////////////////////////////
// Service to get all preference of a user
// With Update function to update the local storage when value changes
////////////////////////////////////////////////////////////////////////


@Injectable()
export class UserPreferenceService {
    public getImagePath: string;
    private _disposalBag: DisposalBag = new DisposalBag();
    private preference: PreferenceLocalStorageModel;
    
    private backgroundImageSubject = new BehaviorSubject<any>(null);
    private logoImageSubject = new BehaviorSubject<any>(null);
    private isLogoDefaultSubject = new BehaviorSubject<any>(null);
    private themeSubject = new BehaviorSubject<any>(null);
    private dashboardViewSubject = new BehaviorSubject<any>(null);
    private languageSubject = new BehaviorSubject<any>(null);
    private portalSubject = new BehaviorSubject<any>(null);
    private hasPortalPreferenceSubject = new BehaviorSubject<any>(null);
    private hasUserBackgroundPreferenceSubject = new BehaviorSubject<any>(null);
    private hasShowDashboardAppInfoSubject = new BehaviorSubject<any>(null);

    public backgroundImage$ = this.backgroundImageSubject.asObservable();
    public logoImage$ = this.logoImageSubject.asObservable();
    public isLogoDefault$ = this.isLogoDefaultSubject.asObservable();
    public theme$ = this.themeSubject.asObservable();
    public dashboardView$ = this.dashboardViewSubject.asObservable();
    public language$ = this.languageSubject.asObservable();
    public portal$ = this.portalSubject.asObservable();
    public hasPortalPreference$ = this.hasPortalPreferenceSubject.asObservable();
    public hasUserBackgroundPreference$ = this.hasUserBackgroundPreferenceSubject.asObservable();
    public hasShowDashboardAppInfo$ = this.hasShowDashboardAppInfoSubject.asObservable();
    
    
    constructor(
        private userService: UserService,
        private companyService: CompanyService,
        private localStorage: LocalStoreManager,
        private authService: AuthService
        ){
      this.updateData();
    }

    public cleanUp(): void {
        this._disposalBag.dispose();
    }
  
    // Method to load data from local storage or API
    public updateData(): void {
        this.preference = this.localStorage.getData(Constants.PREFERENCE);
        this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
        this.getImagePath = Utilities.getAssestPath();
        
        if (!this.preference.backgroundImage || !this.preference.logoImage || !this.preference.isLogoDefault || !this.preference.theme || !this.preference.dashboardView || !this.preference.language || !this.preference.portal || !this.preference.hasUserBackgroundPreference|| !this.preference.hasShowDashboardAppInfo) 
        {
            this._disposalBag.nextSub = this.companyService.getTenantPreferences().subscribe(tenantPreferences => {
                tenantPreferences = tenantPreferences ? tenantPreferences : [];
                if(!this.preference.logoImage || !this.preference.isLogoDefault)
                {
                    const selectedLogoReferenceId = tenantPreferences.find(element => element.TypeId === UserPreferenceType.TENANTDASHBOARDLOGO)?.Preference;
                    this.loadLogoImage(selectedLogoReferenceId);
                }
                if (!this.preference.hasShowDashboardAppInfo)
                {
                    let hasShowDashboardAppInfo = tenantPreferences.find(x=> x.TypeId == UserPreferenceType.SHOWDASHBOARDAPPINFO)?.Preference;
                    hasShowDashboardAppInfo = hasShowDashboardAppInfo ? hasShowDashboardAppInfo : 'false';
                    this.preference.hasShowDashboardAppInfo = hasShowDashboardAppInfo;
                    this.hasShowDashboardAppInfoSubject.next(hasShowDashboardAppInfo);
                }
                this._disposalBag.nextSub = this.userService.getPreferences().subscribe(userPreferences => {
                    userPreferences = userPreferences? userPreferences : [];
                    if(!this.preference.theme)
                    {
                        let theme = userPreferences.find(x=> x.TypeId == UserPreferenceType.THEME)?.Preference;
                        theme = theme? theme: ThemeType.LIGHT;
                        this.preference.theme = theme.toString();
                        this.themeSubject.next(theme.toString());
    
                    }
                    if(!this.preference.dashboardView)
                    {
                        let dashboardView = userPreferences.find(x=> x.TypeId == UserPreferenceType.DASHBOARDVIEW)?.Preference;
                        dashboardView = dashboardView? dashboardView: ViewType.Tile;
                        this.preference.dashboardView = dashboardView.toString();
                        this.dashboardViewSubject.next(dashboardView.toString());
    
                    }
                    if(!this.preference.language)
                    {
                        let language = userPreferences.find(x=> x.TypeId == UserPreferenceType.LANGUAGE)?.Preference;
                        language = language? language: LanguageTypeEnum.ENGLISH;
                        this.preference.language = language.toString();
                        this.languageSubject.next(language.toString());
                    }
                    if(!this.preference.portal)
                    {
                        let portal = userPreferences.find(x=> x.TypeId == UserPreferenceType.PORTALTYPE)?.Preference;
                        let hasportalpref = true;
                        if(!portal)
                        {
                            hasportalpref = false;
                            portal = PortalTypes.SOTIONEAPPS;
                        }
                        this.preference.hasPortalPreference = hasportalpref.toString();
                        this.preference.portal = portal.toString();
                        this.portalSubject.next(portal.toString());
                        this.hasPortalPreferenceSubject.next(hasportalpref.toString());
                    }
                    if(!this.preference.hasUserBackgroundPreference || !this.preference.backgroundImage)
                        {
                            let hasUserBackgroundPreference = false;
                            let userBackgroundPreference = userPreferences.find(x=>x.TypeId == UserPreferenceType.USERBACKGROUNDIMAGE)
                            if(userBackgroundPreference){
                                this.loadUserBackgroundImage(userBackgroundPreference.Preference);
                                hasUserBackgroundPreference = true;
                            }else{
                                const selectedBackgroundReferenceId = tenantPreferences.find(element=>element.TypeId == UserPreferenceType.BACKGROUNDIMAGE)?.Preference;
                                this.loadTenantBackgroundImage(selectedBackgroundReferenceId);
                            }
                            this.preference.hasUserBackgroundPreference = hasUserBackgroundPreference.toString();
                            this.hasUserBackgroundPreferenceSubject.next(hasUserBackgroundPreference.toString());
                        }
                    });
                this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            });
        }
        else{
            this.backgroundImageSubject.next(this.preference.backgroundImage);
            this.logoImageSubject.next(this.preference.logoImage);
            this.isLogoDefaultSubject.next(this.preference.isLogoDefault);
            this.themeSubject.next(this.preference.theme);
            this.dashboardViewSubject.next(this.preference.dashboardView);
            this.languageSubject.next(this.preference.language);
            this.portalSubject.next(this.preference.portal);
            this.hasPortalPreferenceSubject.next(this.preference.hasPortalPreference);
            this.hasUserBackgroundPreferenceSubject.next(this.preference.hasUserBackgroundPreference);
            this.hasShowDashboardAppInfoSubject.next(this.preference.hasShowDashboardAppInfo);


        }
    }

    public updateBackgroundImage(backgroundImageUrl:string)
    {
            this.preference = this.localStorage.getData(Constants.PREFERENCE);
            this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
            this.preference.backgroundImage = backgroundImageUrl ? backgroundImageUrl : "";
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            this.backgroundImageSubject.next(backgroundImageUrl);
    }

    public updateLogoImage(logoImageUrl: string, isdefault: boolean)
    {
        if(logoImageUrl)
        {
            this.preference = this.localStorage.getData(Constants.PREFERENCE);
            this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
            this.preference.logoImage = logoImageUrl;
            this.preference.isLogoDefault = isdefault.toString();
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            this.logoImageSubject.next(logoImageUrl);
            this.isLogoDefaultSubject.next(isdefault.toString());
        }
    }

    public updateLanguage(language:string)
    {
        if(language)
        {
            this.preference = this.localStorage.getData(Constants.PREFERENCE);
            this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
            this.preference.language = language;
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            this.languageSubject.next(language);
        }
    }

    public updateTheme(theme:string)
    {
        if(theme)
        {
            this.preference = this.localStorage.getData(Constants.PREFERENCE);
            this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
            this.preference.theme = theme;
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            this.themeSubject.next(theme);
        }
    }

    public updateDashboardView(dashboardView:string)
    {
        if(dashboardView)
        {
            this.preference = this.localStorage.getData(Constants.PREFERENCE);
            this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
            this.preference.dashboardView = dashboardView;
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            this.dashboardViewSubject.next(dashboardView);
        }
    }

    public updatePortalPref(portal:string)
    {
        if(portal)
        {
            this.preference = this.localStorage.getData(Constants.PREFERENCE);
            this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
            this.preference.portal = portal;
            this.preference.hasPortalPreference = "true";
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            this.hasPortalPreferenceSubject.next("true");
            this.portalSubject.next(portal);
        }
    }

    public updateHasShowDashboardAppInfo(hasShowDashboardAppInfo:string)
    {
        this.preference = this.localStorage.getData(Constants.PREFERENCE);
        this.preference = this.preference ? this.preference : new PreferenceLocalStorageModel();
        this.preference.hasShowDashboardAppInfo = hasShowDashboardAppInfo;
        this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
    }
    
    private loadTenantBackgroundImage(selectedBackgroundReferenceId: string)
    {
        let selectedBackgroundImgUrl = "";
        this._disposalBag.nextSub = this.companyService.getTenantBackgroundImages().subscribe((tenantBackgroundImages: TenantBackgroundImages[]) => {
            const selectedtenantBackgroundImage = selectedBackgroundReferenceId ? tenantBackgroundImages.find(x => x.ReferenceId === selectedBackgroundReferenceId) : tenantBackgroundImages[0];
            if (selectedtenantBackgroundImage.Type == BackgroundTypeEnum.Default) {
                selectedBackgroundImgUrl = this.getImagePath + selectedtenantBackgroundImage.ImageUrl;
            } else if (selectedtenantBackgroundImage.Type == BackgroundTypeEnum.Custom) {
                selectedBackgroundImgUrl = selectedtenantBackgroundImage.ImageUrl;
            };
            this.preference.backgroundImage = selectedBackgroundImgUrl;
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            this.backgroundImageSubject.next(selectedBackgroundImgUrl);

        });
    }
    
    private loadUserBackgroundImage(selectedBackgroundImageId: number)
    {
        let selectedBackgroundImgUrl = "";
        this._disposalBag.nextSub = this.authService._isUserFullyLoggedIn.subscribe(value=>{
            if(value){
                let currentUser = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
                this._disposalBag.nextSub = this.userService.getUserBackgroundImages(currentUser.ReferenceId).subscribe((userBackgroundImages: UserBackgroundImage[]) => {
                    const selectedtenantBackgroundImage = selectedBackgroundImageId ? userBackgroundImages.find(x => x.Id === selectedBackgroundImageId) : userBackgroundImages[0];
                    if (selectedtenantBackgroundImage.Type == BackgroundTypeEnum.Default) {
                        selectedBackgroundImgUrl = this.getImagePath + selectedtenantBackgroundImage.ImageUrl;
                    } else if (selectedtenantBackgroundImage.Type == BackgroundTypeEnum.Custom) {
                        selectedBackgroundImgUrl = selectedtenantBackgroundImage.ImageUrl;
                    };
                    this.preference.backgroundImage = selectedBackgroundImgUrl;
                    this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
                    this.backgroundImageSubject.next(selectedBackgroundImgUrl);
                });
            }
        })
    }

    private loadLogoImage(selectedLogoReferenceId: string)
    {
        let selectedLogoImgUrl = "";
        let isdefaultlogo = true;
        this._disposalBag.nextSub = this.companyService.getTenantLogos().subscribe((tenantLogoImages: TenantLogo[]) => {
            const selectedLogoImage = selectedLogoReferenceId? tenantLogoImages.find(x => x.ReferenceId === selectedLogoReferenceId): tenantLogoImages.find(x=>x.Type == UserPreferenceType.TENANTDASHBOARDLOGO && !x.IsCustom);
            if (selectedLogoImage?.IsCustom == false) {
                selectedLogoImgUrl = this.getImagePath + selectedLogoImage.LogoUrl;
                isdefaultlogo = true;
            } else if (selectedLogoImage?.IsCustom == true) {
                selectedLogoImgUrl = selectedLogoImage.LogoUrl;
                isdefaultlogo = false;
            };
            this.preference.logoImage = selectedLogoImgUrl;
            this.preference.isLogoDefault = isdefaultlogo.toString();
            this.localStorage.saveSessionData(this.preference, Constants.PREFERENCE);
            
            this.logoImageSubject.next(selectedLogoImgUrl);
            this.isLogoDefaultSubject.next(isdefaultlogo.toString());
        });
    }
}