import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Directive, Host, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { scan, takeUntil, filter, combineLatest } from 'rxjs/operators';
import { Subscription, merge, fromEvent } from 'rxjs';
import { SotiHeaderCell } from '../table/cell';
import { SotiTable } from '../table/table';
import { SotiOrderable, SotiReorder } from './reorder';
type ClientRect = DOMRect;
const ORDERABLE_CLASS = 'orderable';

type Direction = 'left' | 'right';
type MovingHTMLElement = HTMLElement & { animating: boolean; moved: number; direction: Direction };
interface Position {
  /**
   * Normalized to 0
   */
  x: number;
  /**
   * Normalized to 0
   */
  y: number;
  /**
   * Position of the mouse on the window
   */
  clientX: number;
  /**
   * Position of the mouse on the window
   */
  clientY: number;
  event: MouseEvent | TouchEvent;
  direction: Direction;

  /**
   * Flag set if the user moved enough to trigger a drag event
   */
  moved: boolean;
}

interface Sibling {
  el: MovingHTMLElement;
  rect: ClientRect;
}

@Directive({
  selector: '[sotiReorderHeader]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiReorderHeader implements SotiOrderable, OnInit, OnDestroy, AfterViewInit {
  public id: string;
  public index: number;
  public updatedIndex: number;

  public get element(): HTMLElement {
    return this._headerCell.elementRef.nativeElement;
  }

  private _mouseup: Subscription;
  private _mousemove: Subscription;

  private _siblings: Map<number, Sibling> = new Map<number, Sibling>();

  private _totalSiblings: number;
  /**
   * Keep Track of which siblings were moved
   */
  private _movedSiblings: Set<HTMLElement> = new Set<HTMLElement>();

  private _totalMoved = 0;
  private _hasSkippedColumn = false;

  constructor(
    @Host() private _reorder: SotiReorder,
    @Host() private _headerCell: SotiHeaderCell,
    @Host() private _table: SotiTable<object>,
    private _zone: NgZone,
    private _platform: Platform
  ) {
    /**
     * All mouse movement events should be run outside of angular for maximum performance
     * Bind all event functions to itself.
     */
    this.start = this.start.bind(this);

    this._headerCell.elementRef.nativeElement.classList.add(ORDERABLE_CLASS);
  }

  public ngAfterViewInit(): void {
    this._zone.runOutsideAngular(() => {
      if (this._platform.IOS || this._platform.ANDROID) {
        this.element.addEventListener('touchstart', this.start);
      } else {
        this.element.addEventListener('mousedown', this.start);
      }
    });
  }

  public ngOnInit(): void {
    if (!this.id && this._headerCell) {
      this.id = this._headerCell.columnDef.name;
    }

    this._reorder.register(this);
  }

  public ngOnDestroy(): void {
    this._reorder.deregister(this);
    this.element.removeEventListener('mousedown', this.start);
    this.element.removeEventListener('touchstart', this.start);

    this._removeGlobalListeners();
  }

  /**
   * Adds event listeners for mouse up and move.
   *
   * We use `Observable.fromEvent` because we need to make sure that we have a easy way
   * to manage past events and current ones
   *
   * TODO: move this logic to a seperate 'drag' class for reusablility
   *
   * @param {(MouseEvent | TouchEvent)} startEvent event
   */
  public start(startEvent: MouseEvent | TouchEvent): void {
    startEvent.stopPropagation();
    startEvent.preventDefault();

    this._siblings.clear();
    this._totalMoved = 0;
    this._hasSkippedColumn = false;

    const clientRect = this.element.getBoundingClientRect();

    // Get the bounding rects of all sibling elements. Only those with the `ORDERABLE_CLASS` are queried
    const siblings = this._table.headerRowElement.children;
    this._totalSiblings = siblings.length;
    for (let i = 0; i < siblings.length; i++) {
      const child = siblings[i] as MovingHTMLElement;

      if (!child.classList.contains(ORDERABLE_CLASS)) {
        this._hasSkippedColumn = true;
        continue;
      }
      child.moved = 0;
      this._siblings.set(i, { el: child, rect: child.getBoundingClientRect() });
    }
    this._movedSiblings.clear();

    /** Find current index */
    this.index = Array.from(this._reorder.columns).findIndex((column) => column === this.id);
    this.updatedIndex = this.index;

    /**
     * Merge both touch and mouse events
     */
    const move = merge(fromEvent<MouseEvent>(document, 'mousemove'), fromEvent<TouchEvent>(document, 'touchmove'));
    const up = merge(fromEvent<MouseEvent>(document, 'mouseup'), fromEvent<TouchEvent>(document, 'touchend'));

    /**
     * Mousemove uses scan to check the previous value. This allows us to see if the user moved enough pixels
     * to start the drag, and to see how far the user dragged from the starting point
     */
    const drag = move.pipe(
      takeUntil(up),
      scan<MouseEvent | TouchEvent, Position>(
        (acc, event, index) => {
          // eslint-disable-next-line prefer-const
          let { x, y, clientX, clientY } = acc;
          let updatedClientX, updatedClientY;

          if (event instanceof MouseEvent) {
            ({ clientX: updatedClientX, clientY: updatedClientY } = event);
          } else {
            ({ clientX: updatedClientX, clientY: updatedClientY } = event.touches[0]);
          }

          const updatedX = x + (updatedClientX - clientX);
          const updatedY = y + (updatedClientY - clientY);

          const direction = x > updatedX ? 'left' : 'right';

          // Skip the first emit when calculating x and moved
          let moved = acc.moved;
          if (index > 0) {
            x = updatedX;
            y = updatedY;
            /**
             * Calculate if the user moved enough. If they moved 4px, they moved.
             *
             * This gets rid of a start delay, and just checks if the user moved.
             */
            if (!acc.moved) {
              moved = Math.abs(updatedX) > 4;
            }
          }

          return {
            x,
            y,
            clientX: updatedClientX,
            clientY: updatedClientY,
            event,
            direction,
            moved
          };
        },
        { x: 0, y: 0, clientX: 0, clientY: 0, event: null, direction: 'left', moved: false }
      )
    );

    this._mousemove = drag
      .pipe(
        filter((pos) => {
          return pos.moved;
        })
      )
      .subscribe((event) => this.move(event, clientRect));

    // TODO (jcammisuli): change to static `combineLatest`
    // eslint-disable-next-line import/no-deprecated
    this._mouseup = up.pipe(combineLatest(drag)).subscribe(([evt, pos]) => {
      this.stop(evt, pos);
    });
  }

  /**
   * Move the current column then calculate what other columns need to move.
   *
   * It currently does this check:
   *  Get the clientRect of the sibling
   *  If the left or right (depending on the direction) point of the moving column is
   *   halfway through the sibling, move that sibling the opposite direction
   *  Repeat
   *
   *  **Left (Opposite is true when moving right)
   *  ***********
   *  |   Sib   | (MOVE ==>)
   *  ***********
   *       ***********
   *       |   Col   |
   *       ***********
   *                /\ <- mouse
   *
   *
   * @param {Position} pos - position
   * @param {ClientRect} movingHeaderRect - moving rect
   */
  public move(pos: Position, movingHeaderRect: ClientRect): void {
    this._moveDraggable(pos);
    this._setElementStyles(true);

    const rightPoint = movingHeaderRect.right + pos.x;
    const leftPoint = movingHeaderRect.left + pos.x;

    let startIndex = this._siblings.keys().next().value;
    let endIndex = this.updatedIndex;
    if (pos.direction === 'right') {
      startIndex = this.updatedIndex;
      endIndex = this._hasSkippedColumn ? this._totalSiblings - 1 : this._siblings.size - 1;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      const sibling = this._siblings.get(i);
      if (!sibling || sibling.el.className === this.element.className) {
        continue;
      }

      const siblingDimension = sibling.rect.width / 2 + sibling.rect.left + (sibling.el.moved || 0);

      if (pos.direction === 'left' && leftPoint <= siblingDimension) {
        if (sibling.el.moved <= 0) {
          this.updatedIndex--;
        }
        this._moveSiblingElement(sibling.el, sibling.rect.width, pos.direction, movingHeaderRect.width);
      } else if (pos.direction === 'right' && rightPoint >= siblingDimension) {
        if (sibling.el.moved >= 0) {
          this.updatedIndex++;
        }
        this._moveSiblingElement(sibling.el, sibling.rect.width, pos.direction, movingHeaderRect.width);
      }
    }

    /**
     * When there are no moved elements, always set the udpatedIndex to the current index
     */
    if (this._movedSiblings.size === 0) {
      this.updatedIndex = this.index;
    }

    // Make sure that the updated index is never less than the actual start index from the siblings.
    this.updatedIndex = Math.max(this.updatedIndex, startIndex);
  }

  public stop(event: MouseEvent | TouchEvent, position: Position): void {
    this._removeGlobalListeners();
    this._setElementStyles(false);

    if (position.moved) {
      this._zone.run(() => {
        this._reorder.updateOrder(this, this.updatedIndex);
      });
    }
  }

  private _removeGlobalListeners(): void {
    if (this._mousemove) {
      this._mousemove.unsubscribe();
    }
    if (this._mouseup) {
      this._mouseup.unsubscribe();
    }
  }

  private _moveDraggable(pos: Position): void {
    this.element.style.transform = `translate3d(${pos.x}px, 0, 0)`;
  }

  private _setElementStyles(add: boolean): void {
    if (add) {
      this.element.style.transition = '';
      this.element.classList.add('column-moving');
      this._table.headerRowElement.classList.add('column-moving');
    } else {
      // When the user releases the mouse, we should move the element nicely into place, without any janks
      this.element.style.transition = `transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)`;
      this.element.style.transform = `translate3d(${this._totalMoved + 1}px, 0px, 0px)`;

      this.element.classList.remove('column-moving');
      this._table.headerRowElement.classList.remove('column-moving');
    }
  }

  private _moveSiblingElement(
    el: MovingHTMLElement,
    elWidth: number,
    direction: Direction,
    totalMovement: number
  ): void {
    if (direction === 'left') {
      if (el.moved < 0) {
        this._totalMoved -= elWidth;
        el.moved = 0;
        this._movedSiblings.delete(el);
      } else {
        el.moved = totalMovement;
        if (!this._movedSiblings.has(el)) {
          this._movedSiblings.add(el);
          this._totalMoved -= elWidth;
        }
      }
    } else if (direction === 'right') {
      if (el.moved > 0) {
        this._totalMoved += elWidth;
        el.moved = 0;
        this._movedSiblings.delete(el);
      } else {
        el.moved = -totalMovement;
        if (!this._movedSiblings.has(el)) {
          this._movedSiblings.add(el);
          this._totalMoved += elWidth;
        }
      }
    }
    el.direction = direction;
    el.style.transform = `translateX(${el.moved}px)`;
    el.animating = true;
    /*
     * Add an event listener for transition end.
     * This makes sure that we remove the animating flag after it's done transitioning
     */
    const cb = (evt: Event): void => {
      el.animating = false;
      event.currentTarget.removeEventListener(event.type, cb);
    }
    el.addEventListener('transitionend', cb);
  }
}
