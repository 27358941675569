
import { Injectable } from '@angular/core';

import * as moment from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
moment.extend(advancedFormat);

// /**
//  * MomentJs is added as a global script in the ngui.config.js file. Temporary workaround to fix lazy loading
//  * https://github.com/angular/angular-cli/issues/2496
//  */
// declare var moment: any;

type momentType = string | Date | number;

@Injectable()
export class DateService {

    public static get defaultMinDate(): Date {
        //NOTE: Month = 0 -> January
        return new Date(1900, 0, 1, 0, 0, 0, 0);
    }

    public static get defaultMaxDate(): Date {
        //NOTE: Month = 11 -> December
        return new Date(2100, 11, 31, 23, 59, 59, 999);
    }

    private _userPreferredDateTimeFormat: string;


    public static getTwoDigitsValue(value: string | number): string {
        return (!!value ? `0${value}`.slice(-2) : '');
    }

    constructor() {
        //TODO: When localization for dates are needed, it should be configured here
        //http://momentjs.com/docs/#/i18n/loading-into-browser/
    }


    /**
     * Format a date object or string with the specified format string.
     * http://momentjs.com/docs/#/displaying/format/
     *
     * @param {(string | Date)} date
     * @param {string} format
     *
     * @memberOf DateService
     */
    public customFormat(date: momentType, format: string): string {


        let dateMoment = moment(date);
        if (date && dateMoment.isValid()) {
            return dateMoment.format(format);
        }

        return '';
    }

    /**
     * Returns a date formated as:
     *   'YYYY-MM-DD h:mm:ss A' if user using 12 Hour Format (Default)
     *   'YYYY-MM-DD H:mm:ss' if user using 24 Hour Format
     *
     * @param {(string | Date)} date
     * @returns string
     *
     * @memberOf DateService
     */
    public format(date: momentType): string {

        if (this._userPreferredDateTimeFormat === DateTimeTargetEnum[DateTimeTargetEnum.TwentyFourHourFormat]) {
            return this.customFormat(date, 'YYYY-MM-DD H:mm:ss');
        }

        return this.customFormat(date, 'YYYY-MM-DD h:mm:ss A');
    }

    /**
     * Returns a date formated as YYYY-MM-DD
     *
     * @param {(string | Date)} date
     * @returns string
     *
     * @memberOf DateService
     */
    public formatDate(date: momentType): string {
        return this.customFormat(date, 'YYYY-MM-DD');
    }

    /**
     * Returns a time formated as:
     *   'h:mm:ss A' if user using 12 Hour Format (Default)
     *   'H:mm:ss' if user using 24 Hour Format
     *
     * @param {(string | Date)} date
     * @returns string
     *
     * @memberOf DateService
     */
    public formatTime(date: momentType): string {

        if (this._userPreferredDateTimeFormat === DateTimeTargetEnum[DateTimeTargetEnum.TwentyFourHourFormat]) {
            return this.customFormat(date, 'H:mm:ss');
        }

        return this.customFormat(date, 'h:mm:ss A');
    }

    /**
     * Returns how many days a specific month from a specific year have.
     * @param {number | string} year
     * @param {number | string} month
     * @returns {number}
     */
    public getDaysInMonth(year: number | string, month: number | string): number {
        let date = moment(`${year}-${month}-01`, 'YYYY-MM-DD');
        return date.isValid() ? date.daysInMonth() : 31;
    }

    /**
     * Returns a Date object from a valid date-time string.
     * @param {string} date
     * @param {string} datePrefix
     * @returns {Date}
     */
    public getDate(date: string, datePrefix?: string): Date {
        if (!date) {
            return null;
        }

        let fixedValue = this._fixDateValue(date);
        let newDate = new Date(fixedValue);
        if (!this._isDateValid(newDate)) {
            newDate = new Date(date);
        }

        //If value still not valid, check if string is a timeString and needs to add a date in front of it.
        if (date.indexOf(':') >= 0 && !this._isDateValid(newDate) && datePrefix) {
            newDate = this.getDate(`${datePrefix} ${date}`);
        }

        return (newDate && !Number.isNaN(newDate.getTime()) ? newDate : null);
    }

    public isFutureDate(date: string): boolean {
        return this.getDate(date) > new Date();
    }


    public getPartialDateTimeString(year: string, month: string, day: string, hours: string, minutes: string, amPm?: string): string {

        year = (!year ? 'YYYY' : year);
        month = (!month ? 'MM' : DateService.getTwoDigitsValue(month));
        day = (!day ? 'DD' : DateService.getTwoDigitsValue(day));
        hours = (!hours ? 'HH' : hours);
        minutes = (!minutes ? 'mm' : DateService.getTwoDigitsValue(minutes));

        if (this._userPreferredDateTimeFormat === DateTimeTargetEnum[DateTimeTargetEnum.TwentyFourHourFormat]) {
            amPm = '';
        } else {
            amPm = (!amPm ? 'AA' : amPm);
        }

        return `${year}/${month}/${day} ${hours}:${minutes} ${amPm}`.trim();
    }

    public parsePartialDateTimeString(defaultData: Date, partialDateTime: string): IParsedDateTime {

        let finalDate = moment(new Date());
        if (defaultData && !isNaN(defaultData.getTime())) {
            finalDate = moment(defaultData);
        }

        const yearPattern = 'YYYY';
        const monthPattern = 'MM';
        const dayPattern = 'DD';
        const hourPattern = 'HH';
        const minutePattern = 'mm';
        const amPmPattern = 'AA';
        const validationRegExp = `(${yearPattern}|\\d{4})/(${monthPattern}|\\d{1,2})/(${dayPattern}|\\d{1,2}) (${hourPattern}|\\d{1,2}):(${minutePattern}|\\d{1,2})[ ]?(${amPmPattern}|AM|PM)?`;

        let parsedDateTime: IParsedDateTime = {
            date: null,
            yearChanged: false,
            monthChanged: false,
            dayChanged: false,
            hourChanged: false,
            minuteChanged: false,
            amPmChanged: false
        };

        let regexGroup = new RegExp(validationRegExp).exec(partialDateTime);
        if (!regexGroup || regexGroup.length === 0) {
            return parsedDateTime;
        }

        let year = 0;
        if (regexGroup[1].indexOf(yearPattern) >= 0) {
            year = finalDate.year();
            partialDateTime = partialDateTime.replace(yearPattern, year.toString());
        } else {
            year = Number.parseInt(regexGroup[1]);
            parsedDateTime.yearChanged = true;
        }

        let month = 0;
        if (regexGroup[2].indexOf(monthPattern) >= 0) {
            month = finalDate.month() + 1;
            partialDateTime = partialDateTime.replace(monthPattern, month.toString());
        } else {
            month = Number.parseInt(regexGroup[2]);
            parsedDateTime.monthChanged = true;
        }

        if (regexGroup[3].indexOf(dayPattern) >= 0) {
            partialDateTime = partialDateTime.replace(dayPattern, finalDate.date().toString());
        } else {
            parsedDateTime.dayChanged = true;
        }

        if (regexGroup[4].indexOf(hourPattern) >= 0) {
            let hour = finalDate.hour();
            if (this._userPreferredDateTimeFormat === DateTimeTargetEnum[DateTimeTargetEnum.TwelveHourFormat] && hour >= 12) {
                hour = hour - 12;
            }

            partialDateTime = partialDateTime.replace(hourPattern, hour.toString());
        } else {
            parsedDateTime.hourChanged = true;
        }

        if (regexGroup[5].indexOf(minutePattern) >= 0) {
            partialDateTime = partialDateTime.replace(minutePattern, finalDate.minute().toString());
        } else {
            parsedDateTime.minuteChanged = true;
        }

        if (regexGroup[6] && regexGroup[6].indexOf(amPmPattern) >= 0) {
            let amPmValue = finalDate.format('A');
            partialDateTime = partialDateTime.replace(amPmPattern, amPmValue);
        } else {
            parsedDateTime.amPmChanged = true;
        }

        parsedDateTime.date = moment(partialDateTime).toDate();

        if (parsedDateTime.date.getMonth() === month) {
            //Month was changed because the day is bigger then the maxDays in this month.
            //So, the datetime is updated to the last day of the set month.
            let maxDays = this.getDaysInMonth(year, month);
            parsedDateTime.date.setMonth(month - 1);
            parsedDateTime.date.setDate(maxDays);
        }

        return parsedDateTime;
    }




    private _fixDateValue(date: string): string {
        let values = date.split(' ');

        /**
         * 24 hour times come in as `0:0`. So it does not get split above.
         * Logic below needs to check to make sure that the date actually has a match with `date.match(/-/g)`
         */
        let dateMatch = date.match(/-/g);
        //'2017-01-01'.length === 10.
        if (values.length > 1 || ((dateMatch && dateMatch.length === 2) && date.length === 10)) {
            //Replace all the "-" characters by "/" from the Date part.
            // Creating dates with "-" does not work in some browsers (e.g. Firefox).
            values[0] = values[0].replace(/-/g, '/');
        }

        return values.join(' ');
    }

    private _isDateValid(date: Date): boolean {
        return date && !Number.isNaN(date.getTime());
    }
}

export interface IParsedDateTime {
    date: Date;
    yearChanged: boolean;
    monthChanged: boolean;
    dayChanged: boolean;
    hourChanged: boolean;
    minuteChanged: boolean;
    amPmChanged: boolean;
}
export enum DateTimeTargetEnum {
    TwelveHourFormat,
    TwentyFourHourFormat,
}
