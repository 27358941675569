import { Injectable } from '@angular/core';
import { List } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssignUserGroupModel } from 'src/app/models/AssignUserGroup.model';
import { ChangePassword } from 'src/app/models/ChangePassword.model';
import { UserBackgroundImage } from 'src/app/models/UserBackgroundImage';
import { UserPasskeyList } from 'src/app/models/UserPasskeyList';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { UserSearchParams } from 'src/app/models/UserSearchParams.model';
import { AddBulkUsersCsv } from 'src/app/models/addBulkUsersCsv';
import { ApplicationUser } from 'src/app/models/applicationUser.model';
import { AssignedApplicationModel } from 'src/app/models/assignedApplication.model';
import { GAuthModel } from 'src/app/models/gAuth.model';
import { GroupListUI } from 'src/app/models/group-list-ui.model';
import { ListResponse } from 'src/app/models/list-response.model';
import { MicrosoftAuthModel } from 'src/app/models/microsoftAuthModel';
import { NotificationResponse } from 'src/app/models/notificationResponse.model';
import { PrincipalUserRoles } from 'src/app/models/principalUserRoles.model';
import { UserApplication } from 'src/app/models/userApplication.model';
import { UserApplications } from 'src/app/models/userApplications.model';
import { ExpiredPasswordLinkModel } from '../../models/ExpiredPasswordLinkModel';
import { ForgotCompany } from '../../models/ForgotCompany.modal';
import { Roles } from '../../models/applicationRoles.model';
import { FilteringParams } from '../../models/filteringparams';
import { LdapUserGroups } from '../../models/ldapUserGroups.model';
import { ResetPassword } from '../../models/resetpassword.model';
import { UserListUI } from '../../models/user-list-ui.model';
import { User } from '../../models/user.model';
import { Group } from './../../models/group.model';
import { Ldap } from './../../models/ldapdirectory';
import { UserEndpoint } from './user-endpoint.service';
import { CompanyListUI } from 'src/app/models/company-list-ui.model';

@Injectable()
export class UserService {

    public selectedUsers: BehaviorSubject<UserListUI[]> = new BehaviorSubject([]);
    public selectedGroups: BehaviorSubject<GroupListUI[]> = new BehaviorSubject([]);

    constructor(private userEndPoint: UserEndpoint) {

    }

    public getUserDetails(): Observable<User> {
        return this.userEndPoint.getUserDetails();
    }

    public getGroups(filteringParams?: FilteringParams): Observable<ListResponse<GroupListUI>> {
        return this.userEndPoint.getGroupsEndpoint(filteringParams);
    }

    getUsers(filteringParams?: FilteringParams) {
        return this.userEndPoint.getUsersEndpoint(filteringParams);
    }

    getUserDetailsByReferenceId(userRefereneId: string): Observable<User> {

        return this.userEndPoint.getUserDetailsByReferenceId(userRefereneId);
    }

    getSelectedUsers(): Observable<UserListUI[]> {
        return this.selectedUsers;
    }

    createLdapUser(user: LdapUserGroups) {
        return this.userEndPoint.createLdapUserEndpoint(user);
    }

    createDbUser(user: User) {
        return this.userEndPoint.createDbUserEndpoint(user);
    }

    updateDbUser(user: User): Observable<string> {
        return this.userEndPoint.updateLocalUser(user);
    }

    resetPassword(userReferenceId: string, resetpassword: ResetPassword) {
        return this.userEndPoint.resetPassword(userReferenceId, resetpassword);
    }

    setUpGAuth(userReferenceId: string, gAuthModel: GAuthModel, languageCode: string): Observable<boolean> {
        return this.userEndPoint.setUpGAuth(userReferenceId, gAuthModel, languageCode);
    }

    public setUpMicrosoftAuth(userReferenceId: string, gAuthModel: MicrosoftAuthModel, languageCode: string): Observable<boolean> {
        return this.userEndPoint.setUpMicrosoftAuth(userReferenceId, gAuthModel, languageCode);
    }

    public forceResetPassword(userIds: string[]): Observable<void> {
        return this.userEndPoint.forceResetPassword(userIds);
    }

    public resetPasswordByAdmin(userId: string, resetpassword: ChangePassword) {
        return this.userEndPoint.resetPasswordByAdmin(userId, resetpassword);
    }

    public SendExpiredPasswordLink(captchaCode: string, catpchaHash: string, resetToken: string): Observable<void> {
        var model = new ExpiredPasswordLinkModel();
        model.captchaCode = captchaCode;
        model.captchaHash = catpchaHash;
        model.resetToken = resetToken;
        return this.userEndPoint.SendExpiredPasswordLink(model);
    }

    public getApplications(userReferenceId: string) {
        return this.userEndPoint.getApplicationsEndpoint(userReferenceId);
    }

    public getLongLoadingApplications(applicationType: number ,applicationreferenceIds: string[])
    {
        return this.userEndPoint.getLongLoadingApplications(applicationType, applicationreferenceIds);
    }

    public deleteUsers(userIds: string[]): Observable<string> {
        return this.userEndPoint.deleteUsersEndpoint(userIds);
    }
    public unlockUsers(userIds: string[]): Observable<void> {
        return this.userEndPoint.unlockUsers(userIds);
    }
    public deactivateUsers(userIds: string[]): Observable<string> {
        return this.userEndPoint.deactivateUsers(userIds);
    }
    public activateUsers(userIds: string[]): Observable<string> {
        return this.userEndPoint.activateUsers(userIds);
    }
    public searchUser(keyword: string, rolesToBeSearch:string[]): Observable<ApplicationUser[]> {
        let model = new UserSearchParams();
        model.Keyword = keyword;
        model.SearchUserInRolesReferenceIds = rolesToBeSearch;
        return this.userEndPoint.searchUserEndpoint(model);
    }

    public verifyDuplicateEmail(userEmail: string): Observable<boolean> {
        return this.userEndPoint.verifyDuplicateEmail(userEmail);
    }

    public getUsersRoles(userReferenceId: string): Observable<PrincipalUserRoles[]> {
        return this.userEndPoint.getUserRolesEndpoint(userReferenceId);
    }

    public verifyLastIdentityAccountAdministrator(userId: string): Observable<boolean> {
        return this.userEndPoint.verifyLastIdentityAccountAdministrator(userId);
    }

    public getUserPasskeyData(): Observable<List<UserPasskeyList>>{
        return this.userEndPoint.getUserPasskeyData();
    }

    public deletePasskeys(userRefId: string, passkeyRefIds: string[]): Observable<any>{
        return this.userEndPoint.deletePasskeys(userRefId,passkeyRefIds);
    }

    public updatePasskeysStatus(userRefId: string, passkeyRefIds: string[], status: number): Observable<any>{
        return this.userEndPoint.updatePasskeysStatus(userRefId,passkeyRefIds,status);
    }

    public getPasskeyData(userRefId:string): Observable<List<UserPasskeyList>>{
        return this.userEndPoint.getPasskeyData(userRefId);
    }
    
    public deletePasskey(passkeyRefId:string): Observable<any>{
        return this.userEndPoint.deletePasskeyEndpoint(passkeyRefId);
    }
    public updatePasskey(passkeyRefId: string,passkeyName:string): Observable<any>{
        return this.userEndPoint.updatePasskeyEndpoint(passkeyRefId,passkeyName);
    }


    public getPasskeyConfigData(): Observable<any> {
        return this.userEndPoint.getPasskeyConfigData();
    }

    public registerPasskeys(model:any): Observable<any>{
        return this.userEndPoint.registerPasskeys(model);
    }

    public getUsersConnection(userReferenceId: string): Observable<Ldap> {
        return this.userEndPoint.getUserConnectionEndpoint(userReferenceId);
    }

    public updateLdapUserRoles(userReferenceId: string, applicationReferenceId: string, roleIds: string[]): Observable<void> {
        return this.userEndPoint.updateLdapUserRoles(userReferenceId, applicationReferenceId, roleIds);
    }

    public updateIdpUserGroup(userGroup: Group): Observable<void> {
        return this.userEndPoint.updateIdpUserGroup(userGroup);
    }

    public verifyDuplicateUserName(userName: string): Observable<boolean> {
        return this.userEndPoint.verifyDuplicateUserName(userName);
    }

    public acceptEula(userReferenceId: string): Observable<void> {
        return this.userEndPoint.acceptEula(userReferenceId);
    }

    public changePassword(token: string, model: ChangePassword): Observable<void> {
        return this.userEndPoint.changePassword(token, model);
    }

    public userAssignApplications(userId: number, userApplications: UserApplications[]): Observable<void> {
        return this.userEndPoint.userAssignApplications(userId, userApplications);
    }

    public UserAssignApplication(userId: string, applicationId: string, userApplication: UserApplication): Observable<any> {
        return this.userEndPoint.UserAssignApplicationEndPoint(userId, applicationId, userApplication);
    }


    public getAssignedApplications(userReferenceId: string): Observable<AssignedApplicationModel[]> {
        return this.userEndPoint.getAssignedApplications(userReferenceId);
    }

    public forgotCompany(forgotCompany: ForgotCompany): Observable<boolean> {
        return this.userEndPoint.forgotCompany(forgotCompany);
    }
    public gettemplateDownloadUrl(): string {
        return this.userEndPoint.templateDownloadUrlEndPoint();
    }
    public getUserListDownloadUrl(): string {
        return this.userEndPoint.userListDownloadUrlEndPoint();
    }
    public getUserApplicationroles(applicationReferenceId: string, userReferenceId: string): Observable<Roles[]> {
        return this.userEndPoint.getUserRolesByApplicationIdEndPoint(applicationReferenceId, userReferenceId);
    }

    public ValidateBulkUsersCsv(param: AddBulkUsersCsv): Observable<void> {
        return this.userEndPoint.ValidateBulkUsersCsv(param);
    }

    public BulkUsersUpload(param: AddBulkUsersCsv): Observable<NotificationResponse> {
        return this.userEndPoint.BulkUsersUpload(param);
    }

    public UnassignUserApplication(userReferenceId: string, applicationReferenceId: string): Observable<void> {
        return this.userEndPoint.UnassignUserApplication(userReferenceId, applicationReferenceId);
    }

    public AssignGroups(userReferenceId: string, groups: AssignUserGroupModel[]): Observable<void> {
        return this.userEndPoint.AssignGroups(userReferenceId, groups);
    }

    public getPreferences(): Observable<UserPreferenceModel[]> {
        return this.userEndPoint.getPreferences();
    }

    public savePreferences(userPreferenceModel: UserPreferenceModel[]): Observable<void> {
        return this.userEndPoint.savePreferences(userPreferenceModel);
    }
    public getUserBackgroundImages(userRedId: string): Observable<UserBackgroundImage[]> {
        return this.userEndPoint.getUserBackgroundImages(userRedId);
    }
    public postCustomBackgroundImage(backgroundImage: File): Observable<void> {
        return this.userEndPoint.postCustomBackgroundImage(backgroundImage)
    }
    public deleteCustomBackgroundImage(refId: string): Observable<void> {
        return this.userEndPoint.deleteCustomBackgroundImage(refId)
    }

    public getAssignedMspTenants(userReferenceId: string): Observable<CompanyListUI[]> {
        return this.userEndPoint.getAssignedMspTenants(userReferenceId);
    }

    public AssignTenantsToUser(userReferenceId: string, childTenantList: string[]): Observable<void> {
        return this.userEndPoint.AssignTenantsToUser(userReferenceId, childTenantList);
    }

    public UnassignTenantFromUser(childTenantReferenceId: string, userReferenceId: string): Observable<void> {
        return this.userEndPoint.UnassignTenantFromUser(childTenantReferenceId, userReferenceId);
    }

    public getUserEmailsByGroupdId(groupReferenceIds: string[]): Observable<string[]> {
        return this.userEndPoint.getUserEmailsByGroupdId(groupReferenceIds);
    }
    
}
