import {Component, Input} from '@angular/core';

@Component({
    selector: 'soti-items-count',
    templateUrl: './items-count.ctrl.html',
    styleUrls: ['./items-count.ctrl.scss']
})

export class ItemsCountControl {

    @Input('itemIcon')
    public itemIcon: string = null;
    @Input('itemDescription')
    public itemDescription: string = '';

    @Input('firstPageItem')
    public firstPageItem: number = 0;
    @Input('lastPageItem')
    public lastPageItem: number = 0;
    @Input('pageSize')
    public pageSize: number = 0;
    @Input('totalItems')
    public totalItems: number = 0;
    @Input() public hidden: boolean = false;

    public readonly noItemsLabel: string = '(0 / 0)';

    constructor() {
    }
}