import { Directive, HostListener, Input, Renderer2, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: `input:not([readonly]):not([type="password"]):not([type="checkbox"]):not([type="radio"]):not(.soti-trim-ignore),textarea:not([readonly]):not(.soti-trim-ignore)`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TrimValueAccessorDirective,
      multi: true
    }
  ]
})
export class TrimValueAccessorDirective implements ControlValueAccessor {
  @Input('soti-trim-ignore') trimIgnore = false;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: any): void {
    // Do not trim here, just pass the value to onChange
    this.onChange(value);
  }

  @HostListener('blur')
  onBlur(): void {
    // Trim only on blur
    const value = this.el.nativeElement.value;
    if (!this.trimIgnore && typeof value === 'string') {
      const trimmed = value.trim();
      this.renderer.setProperty(this.el.nativeElement, 'value', trimmed);
      this.onChange(trimmed);
    }
    this.onTouched();
  }

  writeValue(value: any): void {
    if (typeof value === 'string') {
      this.renderer.setProperty(this.el.nativeElement, 'value', value.trim());
    } else if (value != undefined) {
      this.renderer.setProperty(this.el.nativeElement, 'value', value);
    }
    else{
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
    }
  }
  

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }
}
