import {Component, EventEmitter, Input, Output} from '@angular/core';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';

@Component({
    selector: 'soti-search-box',
    templateUrl: './search-box.ctrl.html',
    styleUrls: ['./search-box.ctrl.scss']
})

export class SearchBoxControl {

    @Input()
    public placeholder: string = '';

    @Input()
    public autoFocus: boolean = false;

    @Input()
    public value: string = '';

    @Input()
    public disableButton: boolean = false;

    public isSearchdone : boolean = false;

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();


    constructor() {
    }


    public onInputKeypress(event: KeyboardEvent) : void {
        if (!event) {
            return;
        }
        if(this.value === '')
        {
            if(this.isSearchdone === true){
                this.isSearchdone = false;
                this.valueChange.emit('');
            }
            event.preventDefault();
            //this.onSearchClick();
            return;
        }

        switch (event.keyCode) {
            case KeyCodeEnum.ENTER:
                event.preventDefault();
                this.onSearchClick();
                break;

            default:
                break;
        }
    }

    public onClear(element: HTMLElement): void {
        this.value = '';
        if(this.isSearchdone === true){
            this.isSearchdone = false;
            this.valueChange.emit('');
        }
        element.focus();
    }

    public onSearchClick(): void {
        if (this.disableButton) {
            return;
        }
        
        if(this.value === undefined || this.value.trim().length === 0){
            return;
        }

        if(this.isSearchdone === undefined || this.isSearchdone === false){
            this.isSearchdone = true;
        }
        this.valueChange.emit(this.value);
    }
}