export abstract class HighlightPropertiesService {
    constructor() {}

    public highlightData(data:string|string[], filter:string = '', startsWith:boolean = false): string {
        let indices = [];
        if (Array.isArray(data)) {
            data = data.toString();
        }
        let value = data.split(/(<[^>]*>)/g).map(data => data.trim()).filter(data => data.length > 0);
        for (var i = 0; i < value.length; i++) {
            if (!value[i].match(/(<[^>]*>)/g)) {
                indices.push(i);
            }
        }

        for (i = 0; i < indices.length; i++) {
            value[indices[i]] = this._highlightValue(filter, value[indices[i]], startsWith);
        }

        return value.join('');
    }

    private _highlightValue<T>(filter, value: string, startsWith: boolean): string {
        if (filter == null || filter.length == 0) {
            return value;
        }
        let term: string = filter.toLowerCase();
        let idx = value.toLocaleLowerCase().indexOf(term);
        if (startsWith) {
            value.split(' ').forEach(split => {
                if (split.length > 0 && split.toLocaleLowerCase().startsWith(filter)) {
                    idx = value.indexOf(split);
                }
            });
        }

        if (idx < 0) {
            return value;
        }

        let parts = {
            p1: value.substr(0, idx),
            p2: value.substr(idx, term.length),
            p3: value.substr(idx + term.length)
        };

        return `<span>${parts.p1}<strong>${parts.p2}</strong>${parts.p3}</span>`;
    }
}