
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Location } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChangePassword } from 'src/app/models/ChangePassword.model';
import { AccountService } from 'src/app/services/account-services/account.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { UserService } from 'src/app/services/user-services/user.service';
import { InfoMessageBarOptions } from 'src/app/shared/controls/info-message-bar/InfoMessageBarOptions';
import { InfoMessageBarTypeEnum } from 'src/app/shared/controls/info-message-bar/InfoMessageBarTypeEnum';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { ErrorCode } from 'src/app/shared/enums/ErrorCode';
import { HeaderService } from 'src/app/shared/header/header.service';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { CheckPasswordService } from '../../../../shared/services/check-password/check-password.service';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { DarkTheme, LightTheme } from 'src/app/services/soti-theme/theme';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { DisposalBag } from '../../../../shared/utils/DisposalBag';
import { Permissions } from 'src/app/shared/enums/Permissions';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.ctrl.html',
    styleUrls: ['./change-password.ctrl.scss']
})

export class ChangePasswordComponent implements OnInit, OnDestroy {
    @Input()
    public changePasswordData: ChangePassword = new ChangePassword();
    
    public SupportAccountLogin: boolean = false;
    public IsSupportCustomerTenant: boolean = false;
    public passwordPolicyRegex: string;
    public atleastonedigit: boolean = false;
    public atleastoneuppercase: boolean = false;
    public atleastonelowercase: boolean = false;
    public atleastonespecialchar: boolean = false;
    public noWhiteSpace: boolean = true;
    public showCriteriaError: boolean = true;

    public charLength: number;
    public existingPasswordIncorrect: boolean = false;
    public infoMessageBarOptions: InfoMessageBarOptions = null;
    
    @Input()
    public mobileView: boolean = false;

    @Input()
    public footerHide: boolean = true;

    @Input()
    public isChangedByAdmin: boolean = false; 

    @Input()
    public userId: string; 
    public showCurrentPassword: boolean = false;
    public showNewPassword: boolean = false;
    public showConfirmPassword: boolean = false;
    @Input()
    public buttonText: string;
    public showPasswordIcon: boolean = true;
    @Output() 
    public onClose: EventEmitter<void> = new EventEmitter();
    public passwordConditions: string = "";
    public passwordPristine: boolean = true;
    public confirmPasswordPristine: boolean = true;
    public wrongOldPassword: boolean = false;
    public disableConfirmPassword: boolean = false;
    public isMobileView: boolean;
    public sotionetheme: boolean;

    public passwordEyeIconShownLabel: string;
    public passwordEyeIconHiddenLabel: string; 
    public passwordShowHideStatusLabel: string;
    public themeMode: UserPreferenceModel = {
        TypeId: UserPreferenceType.THEME,
        Preference: ThemeType.LIGHT
    };
    public lightThemeType: number = ThemeType.LIGHT;
    public darkThemeType: number = ThemeType.DARK;
    @HostBinding('class.dark-mode-bg')
    public isDarkMode: boolean = false;
    public passkeyAuthentication: Permissions[];
    private _disposalBag: DisposalBag = new DisposalBag();

    public addButtonLoader = false;

    constructor(
        private translationService: AppTranslationService,
        private location: Location,
        private accountService: AccountService,
        private userService: UserService,
        private _browserUtilService: BrowserUtilsService,
        private companyService: CompanyService,
        private _sotiThemeService: SotiThemeService,
        private authService: AuthService,
        private _headerService: HeaderService,
        private _liveAnnouncer: LiveAnnouncer,
        private checkPasswordService: CheckPasswordService,
        private themeService: SotiThemeService,
        ) {
            this.isMobileView = this._browserUtilService.isMobileOrTablet();
    }

    public ngOnInit(): void {
        this.passkeyAuthentication = [Permissions.PASSKEYAUTHENTICATION];
        if (this.authService.currentUser.IsSupportAccountLogin) {
            this.SupportAccountLogin = true;
        }
        if (this.authService.currentUser.IsSupportCustomerTenant) {
            this.IsSupportCustomerTenant = true;
        }

        this.accountService.getPasswordPolicyRegex(
            this.authService.currentUser.RolesReferenceIds,
            this.isChangedByAdmin ? this.userId : null)
            .subscribe((result: string) => {
            this.passwordPolicyRegex = this.changePasswordData.AppliedPolicyRegex = result;            
            this.passwordConditions = this.checkPasswordService.getPasswordPolicyTooltip(this.passwordPolicyRegex, this.changePasswordData.NewPassword);
        });

        this.buttonText = this.buttonText? this.translationService.getTranslation(this.buttonText) : this.translationService.getTranslation('lbl_change_password');
        
        
        if (this.isMobileView) {
            this.authService.isHeaderRequired.next(false);
        }

        this.passwordEyeIconShownLabel = this.translationService.getTranslation('lbl_passwordEyeIcon_shown')
        this.passwordEyeIconHiddenLabel = this.translationService.getTranslation('lbl_passwordEyeIcon_hidden')
        this.passwordShowHideStatusLabel = this.translationService.getTranslation('lbl_header_status')
        
        this.sotionetheme = this._sotiThemeService._isSotiTheme;
        if (this.isMobileView && this.sotionetheme) {
            this.themeService.setTheme(new LightTheme());
            this.loadPreferences();
        }

    }

    public backClicked(): void {        
        this.onClose.emit();

        if (this.isMobileView) {
            this.location.back();
        }
        
    }
    public passwordToggle(passwordField: string): void {
        if ( passwordField === 'showCurrentPassword') {
            this.showCurrentPassword = !this.showCurrentPassword;
            this._liveAnnouncer.announce(this.showCurrentPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel, 'assertive');
        }
        if ( passwordField === 'showNewPassword') {
            this.showNewPassword = !this.showNewPassword;
            this._liveAnnouncer.announce(this.showNewPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel, 'assertive');
        }
        if ( passwordField === 'showConfirmPassword' && !this.disableConfirmPassword) {
            this.showConfirmPassword = !this.showConfirmPassword;
            this._liveAnnouncer.announce(this.showConfirmPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel, 'assertive');
        }
    }

    public passwordToggleStatus(passwordField: string): void {
        if ( passwordField === 'showCurrentPassword') {
            this._liveAnnouncer.announce(this.passwordShowHideStatusLabel + ", " + (this.showCurrentPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel), 'polite');
        }
        if ( passwordField === 'showNewPassword') {
            this._liveAnnouncer.announce(this.passwordShowHideStatusLabel + ", " + (this.showNewPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel), 'polite');
        }
        if ( passwordField === 'showConfirmPassword' && !this.disableConfirmPassword) {  
            this._liveAnnouncer.announce(this.passwordShowHideStatusLabel + ", " + (this.showConfirmPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel), 'polite');
        }
    }

    public getCharacterLengthMessage(): string {
        return this.translationService.getTranslation('msg_character_length').replace('<no>', this.charLength);
    }
    public changePristine(value: string): void {
        this.passwordConditions = this.checkPasswordService.getPasswordPolicyTooltip(this.passwordPolicyRegex, value);
        this.disableConfirmPassword = false;
    }

    public changePassword(): void {
        this.infoMessageBarOptions = null;
        this.existingPasswordIncorrect = false;

        if(this.isChangedByAdmin){
            this.changePasswordByAdmin();
        } else {    
            this.changePasswordByUser();
        }
        this._headerService._isPopOpne = false;
    }

    public hideError()
    {
        this.showCriteriaError = false;
    }
    
    public unhideError()
    {
        this.showCriteriaError = true;
    }
    public changePasswordByAdmin(): void {
        this.userService.resetPasswordByAdmin(this.userId,this.changePasswordData).subscribe(() => {
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('mgs_password_updated_successfully'),
                this.translationService.getTranslation('mgs_password_updated_successfully'),
                NotificationType.Success,
                AlertSeverityEnum.Minor));
            this.backClicked();
        }, error => {
                let message = this.translationService.getTranslation('msg_Somethingwentworng');
                appMessenger.postMessage(new ClientNotificationMessage(this.translationService.getTranslation('msg_Error'),
                message,
                NotificationType.Error,
                AlertSeverityEnum.Minor));
                    this.backClicked();
        });
    }

    public changePasswordByUser(): void {
        this.accountService.changePassword(this.changePasswordData).subscribe(() => {
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('mgs_password_changed_successfully'),
                this.translationService.getTranslation('mgs_password_changed_successfully'),
                NotificationType.Success,
                AlertSeverityEnum.Minor));
            this.backClicked();
        }, error => {
            if (error.error.ErrorCode === ErrorCode.InvalidCredentials) {
                this.existingPasswordIncorrect = true;
            } else if (error.error.ErrorCode === ErrorCode.PasswordPolicyChanged) {
                this.infoMessageBarOptions = new InfoMessageBarOptions(
                    InfoMessageBarTypeEnum.Error,
                    this.translationService.getTranslation('password_policy_updated'),
                    'identity-icon-error-warning'
                );
            } else if (error.error.ErrorCode === ErrorCode.LastPasswordMatch) {
                let msg = this.translationService.getTranslation('msg_last_password_match').replace('<CompareOldPasswordCount>', error.error.ErrorInfo.CompareOldPasswordCount);
                this.infoMessageBarOptions = new InfoMessageBarOptions(
                    InfoMessageBarTypeEnum.Error,
                    msg,
                    'identity-icon-error-warning'
                );
                setTimeout(() => {
                    this._liveAnnouncer.announce(msg, 'polite');
                }, 1000);
            } else {
                let message = this.translationService.getTranslation('msg_Somethingwentworng');
                appMessenger.postMessage(new ClientNotificationMessage(this.translationService.getTranslation('msg_Error'),
                message,
                NotificationType.Error,
                AlertSeverityEnum.Minor));
                    this.backClicked();
            }
        });
    }

    public currentPasswordChange(): void {
        this.existingPasswordIncorrect = false;
    }

    public generateRandomPassword(): void {
        this.companyService.getGeneratedPassword(this.authService.currentUser.RolesReferenceIds, this.isChangedByAdmin ? this.userId : null).subscribe ((password) => {
            this.changePasswordData.NewPassword = this.changePasswordData.ConfirmPassword = password;
            this.disableConfirmPassword = true;
            this.showConfirmPassword = false;
            this.passwordConditions = this.checkPasswordService.getPasswordPolicyTooltip(this.passwordPolicyRegex, password);
            this._browserUtilService.copyTextToClipboard(password);
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('msg_password_generated_successfully'),
                this.translationService.getTranslation('msg_password_generated_successfully'),
                NotificationType.Success,
                AlertSeverityEnum.Minor));
        }); 
    }
    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }
    private savePreferences(userPreference) {
        this.userService.savePreferences([userPreference]).subscribe();
    }

    public setTheme(themeType: ThemeType, savePreference: boolean = true): void {
        this.themeMode.Preference = themeType;

        if (savePreference) {
            this.savePreferences(this.themeMode);
        }

        if (themeType == ThemeType.LIGHT) {
            this.themeService.setTheme(new LightTheme());
            this.isDarkMode = false;
        } else {
            this.themeService.setTheme(new DarkTheme());
            this.isDarkMode = true;
        }
    }

    

    private checkRegExValidation(regEx:string, control:string) : boolean {
         return RegExp(regEx).test(control);
    }
    private loadPreferences() {
        this.sotionetheme = this._sotiThemeService._isSotiTheme;
        this._disposalBag.nextSub = this.userService.getPreferences().subscribe((data) => {
            const themePreference = data.find(x => x.TypeId == UserPreferenceType.THEME);
            if (themePreference) {
                this.setTheme(themePreference.Preference, false);
            }
        });
    }
}
