import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';

import { IContextualActionBarItem } from '../model/IContextualActionBarItem';
import { KeyCodeEnum } from '../../../../enums/KeyCodeEnum';
import { HighlightPropertiesService } from '../../../../../services/highlight-properties.service';
import { AppTranslationService } from '../../../../../services/app-translation.service';

/**
 * Created by oldanchuk on 2016-09-08.
 */
/**
 * Generic action bar to receive a number of selected items and allowed actions
 */

@Component({
    selector: 'more-action-items',
    templateUrl: './more-action-items.ctrl.html',
    styleUrls: ['./more-action-items.ctrl.scss']
})
export class MoreActionItemsComponent extends HighlightPropertiesService implements OnInit {
    @Input('actions')
    public actions: IContextualActionBarItem[];

    @Input('place-bottom')
    public placeBottom: boolean = false;

    @Input('actions-context')
    public actionsContext: string = '';

    @Input('hide-search')
    public hideSearch: boolean = false;

    @Input('help-id')
    public helpId: string = '';

    @Output('actionClicked')
    public actionClicked: EventEmitter<IContextualActionBarItem> = new EventEmitter<IContextualActionBarItem>();

    public filteredActions: IContextActionFilterable[] = [];

    public noActionFoundLabel: string;

    public actionLabel: string;

    private _actionFilter: string;
    private _index: number;

    constructor(private _localizationService: AppTranslationService, private _element: ElementRef) {
        super();
    }

    public ngOnInit(): void {
        this._index = -1;
        this._actionFilter = '';        
        this.noActionFoundLabel = 'lbl_no_' + this.actionsContext + '_actions_found';
        this.actionLabel = 'lbl_' + this.actionsContext + '_actions';
        this.refreshItems();
    }

    public refreshItems(): void {
        this.filteredActions = this.actions
            .map((actionBarItem, idx) => {
                const localized = this._localizationService.getTranslation(actionBarItem.label);
                let filteredItem: IContextActionFilterable = Object.assign({
                    displayName: localized,
                    highlightedDisplayName: this.highlightData(localized, this._actionFilter),
                    selected: '',
                    isDisabled: actionBarItem.isDisabled
                }, actionBarItem);
                return filteredItem;
            })
            .filter(filteredItem => !this._actionFilter
                                    || filteredItem.displayName.toLowerCase().includes(this._actionFilter))
            .sort((a:IContextActionFilterable, b:IContextActionFilterable) => {
                //  1 = Ascending (A to Z)
                // -1 = Descending (Z to A)
                const sortingFactor = this.placeBottom && !this.hideSearch ? -1 : 1;

                return Intl.Collator().compare(a.displayName, b.displayName) * sortingFactor;
            })
             .map((filteredItem, idx, arr) => {
                filteredItem.selected = this._index === idx ? 'selected' : '';
                return filteredItem;
            });
            // when a filter is applied, select the first item automatically
            if (this._actionFilter && this.filteredActions.length > 0 && this._index < 0) {
                if (this.placeBottom) {
                    this.filteredActions[this.filteredActions.length - 1].selected = 'selected';
                    this._index = this.filteredActions.length - 1;
                } else {
                    this.filteredActions[0].selected = 'selected';
                    this._index = 0;
                }
            }
            this._scrollToElement(this._index);
    }

    public onNewFilter(actionSearch: string): void {
        if (this._actionFilter !== actionSearch) {
            this._index = -1;
        }
        this._actionFilter = actionSearch ? actionSearch.toLowerCase() : '';
        this.refreshItems();
    }

    public onKeyDown(code: KeyCodeEnum): void {
        switch (code) {
            case KeyCodeEnum.UP_ARROW:
                this._index = (this._index <= 0) ? this.filteredActions.length - 1 : this._index - 1;
                break;
            case KeyCodeEnum.DOWN_ARROW:
                this._index = (this._index == this.filteredActions.length - 1) ? 0 : this._index + 1;
                break;
            case KeyCodeEnum.LEFT_ARROW:
                break;
            case KeyCodeEnum.RIGHT_ARROW:
                break;
            case KeyCodeEnum.HOME:
                break;
            case KeyCodeEnum.END:
                break;
            case KeyCodeEnum.ENTER:
                if (this._index >= 0 && this._index < this.filteredActions.length) {
                    this.onActionClicked(this.filteredActions[this._index]);
                }
                break;
            default:
                this._index = -1;
        }

        this.refreshItems();
    }

    public onActionClicked(item: IContextualActionBarItem): void {
        this.actionClicked.emit(item);
    }

    public onClick(event: MouseEvent | TouchEvent): void {
        event.stopPropagation();
        if (this.placeBottom && event.currentTarget instanceof HTMLElement) {
            event.currentTarget.offsetParent.scrollTop = event.currentTarget.offsetParent.scrollHeight;
        }
    }

    private _scrollToElement(itemIndex: number): void {
        let nodeElement: HTMLElement = this._getItemNode(itemIndex);
        if (!nodeElement || !nodeElement.parentElement) {
            return;
        }

        const scrollStart = nodeElement.parentElement.scrollTop;
        const scrollEnd = scrollStart + nodeElement.parentElement.offsetHeight;
        const nodeOffsetTop = nodeElement.offsetTop;
        if (nodeOffsetTop >= scrollStart && nodeOffsetTop <= scrollEnd) {
            //Item is already visible
            return;
        }

        const topBuffer = 20; //in pixels.
        nodeElement.parentElement.scrollTop = nodeOffsetTop - topBuffer;
    }

    
    private _getItemNode(itemIndex: number): HTMLElement {
        return this._element.nativeElement.querySelector(`soti-dropdown-node:nth-child(${itemIndex + 1})`) as HTMLElement;
    }
}

interface IContextActionFilterable extends IContextualActionBarItem {
    displayName: string;
    highlightedDisplayName: string;
    selected: string;
    isDisabled: boolean;
}