/**
 * Created by spentland on 2016-03-23.
 */

import {Component, Input, ViewChild} from '@angular/core';

@Component({
    selector: 'pagination-control-item',
    templateUrl: './pagination-control-item.ctrl.html',
    styleUrls: ['./pagination-control-item.ctrl.scss']
})

export class ControlItem {
    @Input('text') public text: string;
    @ViewChild('ref') public ref;
}