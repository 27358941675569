/**
 * Created by spentland on 2016-03-23.
 */

import {
    Component, Input, Output, EventEmitter, ViewChild, OnInit, AfterViewInit, OnChanges, OnDestroy, ViewChildren, QueryList,
} from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { logger } from '../../utils/Logger';
import { KeyCodeEnum } from '../../enums/KeyCodeEnum';
import { ISelectionChangeArgs, SelectBoxControl } from '../select-box/select-box.ctrl';
import { PopUpTrigger } from '../../controls/dialogs/pop-up/pop-up-trigger.directive';
import { Subject } from 'rxjs/Subject';
import { DisposalBag } from '../../utils/DisposalBag';
import { DropdownNodeComponent } from '../../controls/dialogs/dropdown/dropdown-component/dropdown-node/dropdown-node.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';


@Component({
    selector: 'soti-pagination2',
    templateUrl: './pagination2.ctrl.html',
    styleUrls: ['./pagination2.ctrl.scss']
})

export class PaginationComponent2 implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input()
    public pageSizeOptions: number[] = [50, 75, 100, 125, 150, 175, 200, 225, 250];
    /** The number of items total */
    @Input() public totalItems: number;
    /** The type of items we are displaying, e.g.: 'Devices', 'Users', etc... */
    @Input() public itemType: string;
    /** The number of the first item on the page, essentially API 'skip' + 1 */
    @Input() public firstItem: number;
    /** The number of items that are displayed per page, essentially API 'take' */
    @Input() public pageSize: number;
    /** Check to determine if in modal which would indicate a smaller 'window' for width of pagination control */
    @Input() public isModal: boolean;

    /** Emitter used when the user requests a different page size */
    @Output() public pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();
    /** Emitter used when the user moves to a different page */
    @Output() public pageNumberRequested: EventEmitter<number> = new EventEmitter<number>();

    public currentPage: number;
    public numPages: number;
    public pagesArray: number[];
    public targetPage: number;
    public popup: boolean = false;

    public maxHeight: number = window.innerHeight / 3;

    @ViewChild(PopUpTrigger)
    private _popupTrigger: PopUpTrigger;

    @ViewChild(SelectBoxControl)
    private selectBoxControl: SelectBoxControl;
    @ViewChildren(DropdownNodeComponent)
    private _dropdownNodeList: QueryList<DropdownNodeComponent>;
    private _pageRequestSubject: Subject<number> = new Subject();
    private _disposalBag: DisposalBag = new DisposalBag();
    private _isViewInit: boolean = false;

    constructor(private _liveAnnouncer: LiveAnnouncer) { }

    public ngOnInit(): void {
        this._disposalBag.nextSub = this._pageRequestSubject.debounceTime(200).subscribe((num: number) => {
            this.pageNumberRequested.emit(num);
            setTimeout(() => {
                this._liveAnnouncer.announce(this.currentPage+"of"+this.numPages, 'assertive');
            }, 300);
        });
        this._layoutControlItems();
    }

    public ngAfterViewInit(): void {
        this._isViewInit = true;
    }

    public ngOnChanges(changes: {}): void {
        if (!this._isViewInit) {
            return;
        }
        this.pagesArray = [];
        this._layoutControlItems();
    }

    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }

    /**
     * Event handler that is called when a number is clicked
     * @param page The page to go to
     */
    public requestPage(page: number): void {
        if (page === this.currentPage) {
            return;
        }
        this._updateCurrentPage(page);
        this._pageRequestSubject.next(this.currentPage);
    }

    /**
     * Event handler for when the > button is pressed
     */
    public next(): void {
        if (this._canGoNext()) {
            this.requestPage(this.currentPage + 1);
        }
    }

    /**
     * Event handler for when the < button is pressed
     */
    public previous(): void {
        if (this._canGoPrevious()) {
            this.requestPage(this.currentPage - 1);
        }
    }

    // This is most likely a temporary method to satisfy the requirements. It is very possible the the dropdown
    // that has been envisioned will actually emits its own value and we'll pass it.
    public goToPage(form: NgForm, event: KeyboardEvent): void {
        if (form.invalid) {
            return;
        }
        const formModel: { targetPage: number } = form.value;
        let numeric = +formModel.targetPage;
        
        // Make sure it is a value we can use
        if (isNaN(numeric) || numeric < 1 || numeric > this.numPages) {
            this.targetPage = this.currentPage;
            return;
        }
        // Round down to whole number
        numeric = Math.floor(numeric);

        // Don't bother emitting if we are on the entered page
        if (numeric === this.currentPage) {
            this.targetPage = numeric;
            return;
        }

        // hide keyboard on tablets:
        event.target[0].blur();
        this.pageNumberRequested.emit(numeric);
        setTimeout(() => {
            this.hidePopup();
        }, 500);
    }

    /**
     * Prevent a modal dialog from navigating left or right when in the input for page number.
     * @param event The keyboard event fired by user key down.
     */
    public preventNavigation(event: KeyboardEvent): void {
        if (event.keyCode == KeyCodeEnum.LEFT_ARROW || event.keyCode == KeyCodeEnum.RIGHT_ARROW) {
            event.stopImmediatePropagation();
        }
    }

    public onPageSizeChange(change: ISelectionChangeArgs): void {
        let numeric = +change.value;
        if (numeric === this.pageSize) {
            return;
        }
        if (change.isUserInitiated) {
            this.pageSizeChanged.emit(numeric);
        }
    }

    public showPopup(e: Event): void {
        e.preventDefault();
        e.stopPropagation();
        this.popup = !this.popup;
        this._popupTrigger.open();
        this._scrollSelectedPageIntoView();
    }

    public hidePopup(): void {
        this.popup = false;
        this._popupTrigger.close();
    }

    public close($event?: MouseEvent): void {
        if (this.popup) {
            $event && $event.stopImmediatePropagation();
            this.hidePopup();
        }
    }

    public closeSelectBox() : void
    {
        this.selectBoxControl.optionsOpened = true;
        this.selectBoxControl.toggleOpen();
    }

    /**
     * Helper used whenever we need to layout all of the elements again
     * @private
     */
    private _layoutControlItems(): void {
        this._calculateNumberOfPages();
        this._calculateCurrentPage();
    }

    private _calculateNumberOfPages(): void {
        this.numPages = Math.ceil(this.totalItems / this.pageSize) || 0;
        this.pagesArray = [...Array(this.numPages).keys()].map((v, i) => ++i);

    }

    /**
     * Based on the first number we are given, determine what page we are on and by extension what page is highlighted
     * @private
     */
    private _calculateCurrentPage(): void {
        this.currentPage = this.firstItem > this.pageSize ? (this.firstItem / this.pageSize >> 0) + 1 : 1;
        this.targetPage = this.currentPage;
    }

    private _updateCurrentPage(page: number): void {
        if (page < 1) {
            this.currentPage = 1;
        } else if (page > this.numPages) {
            this.currentPage = this.numPages;
        } else {
            this.currentPage = page;
        }
    }

    private _scrollSelectedPageIntoView():void {
        let selected = this._dropdownNodeList.filter((node: DropdownNodeComponent) => node.selected)[0];

        if (selected) {
            selected.scrollToSelectedNode();
        }
    }

    private _canGoPrevious(): boolean {
        return this.currentPage !== 1 && this.numPages !== 1;
    }

    private _canGoNext(): boolean {
        return this.currentPage !== this.numPages && this.numPages !== 1;
    }
}
