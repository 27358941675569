import { BlockScrollStrategy } from '@angular/cdk/overlay';
import { ViewportRuler } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Host,
  NgZone,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import { SotiHeaderCell } from '../table/cell';
import { SotiTable } from '../table/table';
import { Platform } from '@angular/cdk/platform';
type ClientRect = DOMRect;
@Directive({
  selector: '[sotiColumnResize]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiColumnResize implements AfterViewInit, OnDestroy {
  @Output() public resizeChange: EventEmitter<number> = new EventEmitter<number>();
  public resizeElement: HTMLDivElement;
  private _startWidth = 0;
  private _startX = 0;
  private _width: number;
  private _minWidth: number;
  private _minCalcWidth: number;
  private _headerCellElement: HTMLElement;
  private _resizeWrap: HTMLElement;
  private _resizeBackground: HTMLElement;
  private _fillerCell: HTMLElement;
  private _scrollStrategy: BlockScrollStrategy;
  private _headerClientRect: ClientRect;
  private _tableClientRect: ClientRect;

  constructor(
    @Host() private _table: SotiTable<object>,
    @Host() private _headerCell: SotiHeaderCell,
    private _renderer: Renderer2,
    private _zone: NgZone,
    private _viewPortRuler: ViewportRuler,
    private _platform: Platform
  ) {
    this.startResize = this.startResize.bind(this);
    this._doDrag = this._doDrag.bind(this);
    this._stopDrag = this._stopDrag.bind(this);

    this._headerCellElement = _headerCell.element;

    this.resizeElement = this._renderer.createElement('div');
    this.resizeElement.classList.add('column-resize');
    this.resizeElement.setAttribute("aria-hidden","true");
    this._headerCellElement.appendChild(this.resizeElement);

    this._scrollStrategy = new BlockScrollStrategy(_viewPortRuler, document);
  }

  public ngAfterViewInit(): void {
    this._zone.runOutsideAngular(() => {
      this.resizeElement.addEventListener('mousedown', this.startResize);
    });
  }

  public ngOnDestroy(): void {
    this.resizeElement.removeEventListener('mousedown', this.startResize);
    this._removeGlobalListeners();
  }

  public startResize(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    this._createScrollBlocker();

    this._headerClientRect = this._headerCellElement.getBoundingClientRect();
    this._tableClientRect = this._table.element.getBoundingClientRect();
    this._startX = event.clientX;
    const computedStyle = window.getComputedStyle(this._headerCell.elementRef.nativeElement);
    this._startWidth = parseInt(computedStyle.width, 10);
    this._minWidth = parseInt(computedStyle.minWidth, 10) || 100;
    this._minCalcWidth = this._headerClientRect.left + this._minWidth;
    this._renderer.addClass(this._headerCellElement.parentElement, 'resizing');
    this._moveColumns(event, this._headerClientRect);
    document.addEventListener('mousemove', this._doDrag);
    document.addEventListener('mouseup', this._stopDrag);
  }

  private _doDrag(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this._moveColumns(event, this._headerClientRect);
    if (event.clientX > this._minCalcWidth) {
      this._width = this._startWidth + event.clientX - this._startX;
    } else {
      this._width = this._minWidth;
    }
  }

  private _stopDrag(event: MouseEvent): void {
    this._removeGlobalListeners();
    requestAnimationFrame(() => {
      this._resizeColumn();
      this._releaseColumns();
      this._removeScrollBlocker();
      this._renderer.removeClass(this._headerCellElement.parentElement, 'resizing');
    });
  }

  private _removeGlobalListeners(): void {
    document.removeEventListener('mousemove', this._doDrag);
    document.removeEventListener('mouseup', this._stopDrag);
  }

  private _moveColumns(event: MouseEvent, clientRect: ClientRect): void {
    if (!this._resizeWrap) {
      this._resizeWrap = this._renderer.createElement('div');
      this._resizeWrap.classList.add('resize-wrap');

      let top = null;
      const computedHeaderStyle = getComputedStyle(this._headerCellElement.parentElement);
      if (computedHeaderStyle.position === 'sticky') {
        top = computedHeaderStyle.top;
      }

      const rowWidth: number = +computedHeaderStyle.width.match(/\d+/)[0];
      const offset = clientRect.right - this._tableClientRect.left;

      const resizeWidth = rowWidth - offset;
      this._resizeWrap.style.cssText = `
                padding-left:${offset}px;
                width: ${resizeWidth}px;
                top: ${top};
            `;

      while (this._headerCellElement.nextSibling) {
        this._resizeWrap.appendChild(this._headerCellElement.nextSibling);
      }
      // set the resizebackground div to cover the whole header row
      this._resizeBackground.style['width'] = `${offset}px`;
      this._resizeBackground.appendChild(this._resizeWrap);
      this._table.element.appendChild(this._resizeBackground);

      // insert a div in mc-header-row to fill in space and create white space
      this._fillerCell = this._renderer.createElement('div');
      this._fillerCell.style.width = `${resizeWidth}px`;

      this._headerCellElement.parentElement.appendChild(this._fillerCell);
    }

    if (event) {
      requestAnimationFrame(() => {
        if (event.clientX > this._minCalcWidth) {
          this._resizeWrap.style.transform = `translateX(${event.clientX - this._startX}px)`;
        }
      });
    }
  }

  private _releaseColumns(): void {
    // if (this._resizeWrap) {
    const parent = this._headerCellElement.parentNode;
    if (parent) {
      this._table.element.removeChild(this._resizeBackground);
      if (this._fillerCell) {
        parent.removeChild(this._fillerCell);
      }
      while (this._resizeWrap.firstChild) {
        parent.appendChild(this._resizeWrap.firstChild);
      }
      this._resizeWrap = null;
      this._fillerCell = null;
    }
    // }
  }

  private _resizeColumn(): void {
    const columns = Array.from(
      this._table.element.getElementsByClassName(`soti-column-${this._headerCell.columnDef.cssClassFriendlyName}`)
    );

    // Caches the column width. This is used when more than one column definition is present.
    this._table.headerWidths.set(this._headerCell.columnDef.name, this._width);

    for (let i = 0; i < columns.length; i++) {
      this._renderer.setStyle(columns[i], 'margin-right', null);
      this._renderer.setStyle(columns[i], 'width', `${this._width}px`);
      this._renderer.setStyle(columns[i], 'max-width', `${this._width}px`);
    }
    this.resizeChange.emit(this._width);
  }

  private _createScrollBlocker(): void {
    this._scrollStrategy.enable();
    this._resizeBackground = this._renderer.createElement('div');
    this._resizeBackground.classList.add('resize-background');
  }

  private _removeScrollBlocker(): void {
    this._scrollStrategy.disable();
    this._resizeBackground = null;
  }
}
