import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, UrlSerializer } from '@angular/router';
import { GlobalVariables } from './services/group-panel-switch.service';
import { JwtHelper } from './services/jwt-helper';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppErrorHandler } from './app-error.handler';
import { AppRoutingModule } from './app-routing.module';
import { AlertService } from './services/alert.service';
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { ToastNotificationService } from './shared/services/toast-services/toast-notification.service';

import { GroupByPipe } from './pipes/group-by.pipe';

import { AppComponent } from './components/app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { AuthService } from './services/auth.service';
import { ControlStatusService } from './services/controls-status.service';
import { HttpHeaderService } from './services/http-header.service';

import { AccountEndpoint } from './services/account-services/account-endpoint.service';
import { AccountService } from './services/account-services/account.service';

import { ConnectionOAuthResponseControl } from './components/ConnectionManagement/connection-oauth-response/connection-oauth-response.ctrl';
import { CookieDisableComponent } from './components/CookieDisable/cookie-disable.component';
import { CookieDisableModule } from './components/CookieDisable/cookie-disable.module';
import { EulaManagementModule } from './components/EulaManagement/eula-management.module';
import { PasswordManagementModule } from './components/PasswordManagement/password-management.module';
import { PreferencesManagementtModule } from './components/PreferencesManagement/preferences-management.module';
import { ProfileManagementModule } from './components/ProfileManagement/profile-management.module';
import { UnderMaintenance } from './components/UnderMaintenance/under-maintenance.view';
import { CopyrightModule } from './components/copyright/copyright.module';
import { HomeComponentModule } from './components/home/home.component.module';
import { LocalizationModule } from './components/localization/localization.module';
import { LowerCaseUrlSerializer } from './lowercase-url-serializer';
import { ApplicationListService } from './services/application-list-service';
import { AuthInterceptor } from './services/authInterceptor-service';
import { AzureADEndpoint } from './services/azure-ad-services/azure-ad-endpoint.services';
import { AzureADService } from './services/azure-ad-services/azure-ad.service';
import { BrowserUtilsService } from './services/browserUtil/browserUtils.service';
import { CompanyEndpoint } from './services/company-services/company-endpoint.service';
import { CompanyListService } from './services/company-services/company-list-service';
import { CompanyService } from './services/company-services/company.service';
import { ConnectionListService } from './services/connection-list-service';
import { DataInitEndPointService } from './services/data-init-services/data-init-endpoint.service';
import { DataInitService } from './services/data-init-services/data-init.service';
import { DirectoryEndpoint } from './services/directory-service/directory-endpoint.service';
import { DirectoryService } from './services/directory-service/directory.Service';
import { EulaEndpointService } from './services/eula-services/eula-endpoint.service';
import { EulaService } from './services/eula-services/eula.service';
import { ExternalIdpEndpoint } from './services/external-idp-services/external-idp-endpoint.service';
import { ExternalIdpService } from './services/external-idp-services/external-idp.service';
import { GSuiteEndpoint } from './services/gsuite-services/gsuite-endpoint.service';
import { GSuiteService } from './services/gsuite-services/gsuite.service';
import { FedratedLogoutService } from './services/logout-services/fedrated-logout.service';
import { NotificationService } from './services/notification.service';
import { PolicyListService } from './services/policy-list-service';
import { SotiThemeService } from './services/soti-theme/soti-theme.service';
import { TenantLinkingListService } from './services/tenant-linking-list-service';
import { UserListService } from './services/user-list-service';
import { SharedInputIconModule } from './shared/controls/input-icon';
import { AboutSotiIdentityModule } from './shared/header/Modals/aboutsotiidentity/aboutsotiidentity.module';
import { ContactUsModule } from './shared/header/Modals/contactUs/contactus.module';
import { HeaderService } from './shared/header/header.service';
import { SharedModule } from './shared/shared.module';
import { VerifyDomainModule } from './components/VerifyDomain/verify-domain.module';
import { StatusNotificationBarModule } from './shared/controls/toaster-messages/shared-status-notification-bar.module';
import { SharedTooltipModule } from './shared/controls/tooltip';
import { DefaultXsightServerManagementModule } from './components/DefaultXsightServerManagement/default-xsight-server-management.module';
import { UserPreferenceService } from './services/user-preference-service/user-preference-service';
import { SessionWarningComponent } from './components/SessionService/session-warning.component';
import { InactivityService } from './services/user-session-services/inactivity.service'; 

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        RouterModule,
        LocalizationModule,
        SharedInputIconModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),

        HomeComponentModule,
        ProfileManagementModule,
        PasswordManagementModule,
        DefaultXsightServerManagementModule,
        CookieDisableModule,
        CopyrightModule,
        ContactUsModule,
        AboutSotiIdentityModule,
        EulaManagementModule,
        SharedModule,
        VerifyDomainModule,
        StatusNotificationBarModule,
        SharedTooltipModule,
        ProfileManagementModule,
        PreferencesManagementtModule
    ],

    declarations: [
        AppComponent,
        NotFoundComponent,
        CookieDisableComponent,
        GroupByPipe,
        UnderMaintenance,
        ConnectionOAuthResponseControl,
        SessionWarningComponent
    ],

    providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        },
        AuthService,
        EndpointFactory,
        AlertService,
        ConfigurationService,
        NotificationService,
        HttpHeaderService,
        AppTitleService,
        ControlStatusService,
        AppTranslationService,
        CompanyService,
        UserPreferenceService,
        ToastNotificationService,
        JwtHelper,
        CompanyEndpoint,
        NotificationEndpoint,
        AccountService,
        AccountEndpoint,
        LocalStoreManager,
        HeaderService,
        BrowserUtilsService,
        DirectoryService,
        DirectoryEndpoint,
        ExternalIdpService,
        GSuiteService,
        GSuiteEndpoint,
        AzureADService,
        ExternalIdpEndpoint,
        AzureADEndpoint,
        DataInitEndPointService,
        DataInitService,
        BrowserUtilsService,
        UserListService,
        ApplicationListService,
        PolicyListService,
        ConnectionListService,
        CompanyListService,
        GlobalVariables,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AppTranslationService,
        FedratedLogoutService,
        EulaEndpointService,
        EulaService,
        SotiThemeService,
        TenantLinkingListService,
        { provide: HTTP_INTERCEPTORS, useClass: InactivityService, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}


export function getBaseUrl(): string {
    return document.getElementsByTagName('base')[0].href;
}
