/**
 * Device column definition
 * @class GenericColumnDef
 */
export class GenericColumnDef  {
        public Name: string;
        public Width: number;
        public MinWidth: number;

    constructor(obj:Partial<GenericColumnDef>) {
        if (!obj) obj = {};
        var deserializerInput: any;
        deserializerInput = obj.Name;
        this.Name = (input => {
            if (input === undefined || input === null) return null;
            var mapItem: (item: any) => any = item => item;

            return mapItem(input);
        })(deserializerInput);
        deserializerInput = obj.Width;
        this.Width = (input => {
            if (input === undefined || input === null) return null;
            var mapItem: (item: any) => any = item => item;

            return mapItem(input);
        })(deserializerInput);
        deserializerInput = obj.MinWidth;
        this.MinWidth = (input => {
            if (input === undefined || input === null) return null;
            var mapItem: (item: any) => any = item => item;

            return mapItem(input);
        })(deserializerInput);
    }

}