// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  width: 100%;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
:host .virtual-container {
  height: 100%;
}
:host .virtual-container .contents {
  top: 0;
  width: 100%;
  height: 100%;
}

:host(.absolute) {
  position: absolute;
}
:host(.absolute) .contents {
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/controls/virtual-scroll/virtual-scroll.ctrl.scss","webpack://./../../../Admin%20Code/UI/App/src/app/shared/controls/virtual-scroll/virtual-scroll.ctrl.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,iCAAA;ACCF;ADAE;EACE,YAAA;ACEJ;ADDI;EACE,MAAA;EACA,WAAA;EACA,YAAA;ACGN;;ADEA;EACE,kBAAA;ACCF;ADAE;EACE,kBAAA;ACEJ","sourcesContent":[":host {\r\n  height: 100%;\r\n  width:100%;\r\n  display: block;\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n  -webkit-overflow-scrolling: touch;\r\n  .virtual-container {\r\n    height: 100%;\r\n    .contents {\r\n      top: 0;\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n  }\r\n}\r\n\r\n:host(.absolute) {\r\n  position: absolute;\r\n  .contents {\r\n    position: absolute;\r\n  }\r\n}",":host {\n  height: 100%;\n  width: 100%;\n  display: block;\n  overflow-y: auto;\n  overflow-x: hidden;\n  -webkit-overflow-scrolling: touch;\n}\n:host .virtual-container {\n  height: 100%;\n}\n:host .virtual-container .contents {\n  top: 0;\n  width: 100%;\n  height: 100%;\n}\n\n:host(.absolute) {\n  position: absolute;\n}\n:host(.absolute) .contents {\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
