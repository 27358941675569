import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { BaseNotification } from 'src/app/models/base-notification.model';
import { BulkOperationNotification } from 'src/app/models/bulk-operation-notification.model';
import { DownloadEventLogNotification } from 'src/app/models/download-eventLog-notification.model';
import { RedirectNotification } from 'src/app/models/redirect-notification.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { NotificationStatusEnum } from 'src/app/shared/enums/NotificationStatusEnum';
import { FileDownloadService } from 'src/app/shared/services/file-download/file-download.service';
import { NotificationService } from '../../../services/notification.service';
import { PopUp2 } from '../../../shared/controls/dialogs/pop-up/pop-up2.ctrl';
import { UserService } from 'src/app/services/user-services/user.service';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { LanguageTypeEnum } from 'src/app/shared/enums/LanguageTypeEnum';
import { NotificationTypeEnum } from 'src/app/shared/enums/notification-type.enum';


@Component({
    selector: 'app-simple-notification',
    templateUrl: './simple-notification.component.html',
    styleUrls: ['./simple-notification.component.scss']
})

export class SimpleNotificationComponent implements OnInit, DoCheck, AfterViewInit {

    @Input('notification')
    public notification: BaseNotification;
    private bulkNotification: BulkOperationNotification;
    private eventLogNotification: DownloadEventLogNotification;
    private redirectNotification: RedirectNotification;

    public smallToolTip: boolean = false;

    public icon: string;
    public showIcon: boolean = true;
    public title: string;
    public message: string;
    public date: string;
    public innerContent: string;
    public notificationIdsToDelete: string[] = [];
    public downloadReportName: string;
    public unReadNotification: boolean = false;

    public buttonLabel: string;
    public isReportAvailable: boolean = false;
    public redirectUrl: string;

    public maxMessageSize: number = 45;

    public isReadMore:boolean = false;
    public label:string;
    public more:string;
    public less:string;
    private _disposalBag: DisposalBag = new DisposalBag();
    @Output() deleteNotificationout: EventEmitter<{ notificationId: string, eventType: string }> = new EventEmitter<{ notificationId: string, eventType: string }>();
    @Output() afterViewRender: EventEmitter<void> = new EventEmitter<void>();
    @Output() backdropClick: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('msgContentSection') msgContentSection: ElementRef;

    public displayShowMore: boolean = false;

    constructor(
        private userService: UserService,
        private translationService: AppTranslationService,
        private notificationService: NotificationService,
        private authService: AuthService,
        private fileDownloadService: FileDownloadService,
        private datePipe: DatePipe,
        private browserUtilService: BrowserUtilsService,
        private router : Router) {
    }

    ngAfterViewInit(): void {
        this.afterViewRender.emit();
        setTimeout(() => {
            if (this.calculateLinesOccupiedByMessage() > 1) {
                this.displayShowMore = true;
                this.isReadMore = true;
            }
        });
    }

    ngDoCheck(): void {
        this.unReadNotification = !this.notification.read
    }

    @ViewChild('popup')
    public attributesPopup: PopUp2;

    public ngOnInit(): void {
        this._disposalBag.nextSub = this.userService.getPreferences().subscribe((data) => {
            
            const languagePreference = data.find(x => x.TypeId == UserPreferenceType.LANGUAGE);
            if(languagePreference && languagePreference.Preference == LanguageTypeEnum.JAPANESE){
                this.maxMessageSize = 30;
            }
            else{
                this.maxMessageSize = 45;
            }
        });
        this.setNotificationIcon();
        this.setNotificationTitle();
        this.setDate();
        this.label = this.translationService.getTranslation('lbl_more');

        if (this.notification instanceof BulkOperationNotification) {
            this.bulkNotification = (<BulkOperationNotification>this.notification);
            this.handleBulkUserNotification();
        } else if (this.notification instanceof RedirectNotification) {
            this.redirectNotification = (<RedirectNotification>this.notification);
            this.handleRedirectNotification();
        } else {
            this.eventLogNotification = (<DownloadEventLogNotification>this.notification);
            this.handleEventLogNotification();
        }

        this.setNotificationMessage();

        if (!this.notification.read) {
            this.unReadNotification = true;
        }
    }

    /**
     *Action use to check weather notification
     *can be deleted or not based on status
     * @return {*}  {boolean}
     */
    public isDeletable(): boolean {
        switch (this.notification.Status) {
            case NotificationStatusEnum.Success: return true;
            case NotificationStatusEnum.Error: return true;
            case NotificationStatusEnum.InProgress: return false;
            default: return true;
        }
    }

    /**
     *Action use to deleteNotification.
     */
    public deleteNotification(event:Event): void {
        this.notificationIdsToDelete = [];
        this.notificationIdsToDelete.push(this.notification.id);
        this.deleteNotificationout.emit({notificationId: this.notification.id, eventType: event.type});
        this.notificationService.deleteNotifications(this.authService.currentUser.ReferenceId, this.notificationIdsToDelete, NotificationTypeEnum.Alert);
        this.attributesPopup.close();
    }

    public markAsReadNotification() {
        this.unReadNotification = false;
        this.notificationService.updateNotificationReadStatus(this.authService.currentUser.ReferenceId, [this.notification.id], NotificationTypeEnum.Alert);        
        this.attributesPopup.close();
        this.redirect()
    }

    public downloadFile() {
        this.notificationService.download(this.notification.id).subscribe((result) => {
            this.fileDownloadService.downloadFile(result, this.downloadReportName, false);
        });
    }

    public redirect() {
        if (!this.redirectUrl || this.redirectUrl == '') {
            this.redirect();
        } else {
            this.backdropClick.emit();
        }
        this.router.navigateByUrl(this.redirectUrl);
    }

    private calculateLinesOccupiedByMessage(): number {
        const lineHeight = 16;
        const msgContentHeight = this.msgContentSection.nativeElement.offsetHeight;
        const linesOccupied = Math.floor(msgContentHeight / lineHeight);
        return linesOccupied;
    }

    private handleEventLogNotification() {
        if (this.eventLogNotification.followUpUri) {
            this.isReportAvailable = true;
            this.downloadReportName = this.eventLogNotification.fileName ? this.eventLogNotification.fileName : (Constants.downloadEventLogName).replace('<TimeSpan>', new Date().toISOString().slice(0, 10));
            this.innerContent = this.translationService.getTranslation('info_logs_download');
            this.buttonLabel = this.translationService.getTranslation('lbl_download');
        } else {
            this.smallToolTip = true;
            this.innerContent = this.translationService.getTranslation('info_clear_notification');
        }
    }

    private handleRedirectNotification() {
        if (this.redirectNotification.redirectUri) {
            this.redirectUrl = this.redirectNotification.redirectUri;
        }

        this.smallToolTip = true;
        this.innerContent = this.translationService.getTranslation('info_clear_notification');
    }

    private handleBulkUserNotification() {
        if (this.bulkNotification.followUpUri) {
            this.isReportAvailable = true;
            this.downloadReportName = (Constants.downloadErrorReportName).replace('<TimeSpan>', new Date().toString().slice(0, 25));
            this.innerContent = this.translationService.getTranslation('info_errorReport_download');
            this.buttonLabel = this.translationService.getTranslation('lbl_download_error_report');
        }
        else {
            this.smallToolTip = true;
            this.innerContent = this.translationService.getTranslation('info_clear_notification');
        }
    }

    private setDate(): void {
        if (this.notification.createdOn) {
            const difference: number = (new Date()).getTime() - this.notification.createdOn.getTime();
            if (difference / 86400000 > 1) {
                this.date = this.datePipe.transform(this.notification.createdOn.toString(), 'medium');
            } else if ((difference % 86400000 / 3600000) > 1) {
                this.date = Math.round((difference % 86400000) / 3600000).toString() + ' ' + this.translationService.getTranslation('lblHrs') + ' ' + this.translationService.getTranslation('lblAgo');
            } else {
                this.date = Math.round(((difference % 86400000) % 3600000) / 60000).toString() + ' ' + this.translationService.getTranslation('lblMins') + ' ' + this.translationService.getTranslation('lblAgo');
            }
        }
    }
    private setNotificationTitle(): void {
        if (this.notification.translated) {
            this.title = this.notification.title;
        } else {
            this.title = this.translationService.getTranslation(this.notification.title);
        }
    }
    private setNotificationMessage(): void {
        if (this.bulkNotification) {
            this.setBulkUserNotificationMessage();
        } 
        if (this.redirectNotification) {
            this.setRedirectNotificationMessage();
        } else if(this.eventLogNotification) {
            this.setEventLogNotificationMessage();
        }
        this.more = this.translationService.getTranslation('lbl_more');
        this.less = this.translationService.getTranslation('lbl_less');
    }

    private setBulkUserNotificationMessage() {
        if (this.bulkNotification.translated) {
            this.message = this.notification.text;
        } else {
            if (this.bulkNotification.text === 'UserCreationInProgresssBody') {
                this.message = this.translationService.getTranslation(this.bulkNotification.text)
                    .replace('<SuccessRecords>', this.bulkNotification.successRecords ? this.bulkNotification.successRecords : '0')
                    .replace('<TotalRecords>', this.bulkNotification.totalRecords);
            } else if (this.bulkNotification.text === 'UserCreationCompletedBody') {
                this.setUserUploadCompletedText();
            } else {
                this.message = this.translationService.getTranslation(this.bulkNotification.text);
            }
        }
    }

    private setRedirectNotificationMessage() {
        if (this.redirectNotification.translated) {
            this.message = this.notification.text;
        } else {
            this.message = this.translationService.getTranslation(this.redirectNotification.text);
            if (this.redirectNotification.data && this.redirectNotification.data["TenantIdentifier"]) {
                this.message = this.message.replace('<TenantIdentifier>', this.redirectNotification.data["TenantIdentifier"]);
            }
        }
    }

    private setEventLogNotificationMessage() {
        if (this.eventLogNotification.translated) {
            this.message = this.notification.text;
        } else {
            this.message = this.translationService.getTranslation(this.eventLogNotification.text);
        }

        if (this.eventLogNotification.text == 'DownloadLogsCompletedBody') {
            let fileNameWithExtension = this.eventLogNotification.fileName.substring(this.eventLogNotification.fileName.indexOf(Constants.eventLogStartKey) + Constants.eventLogStartKey.length);
            let fileName = fileNameWithExtension.substring(0, fileNameWithExtension.indexOf(Constants.eventLogExtensionKey));
            fileName = fileName.replace(/_/g, " ");

            this.message = this.message + fileName;
        }
    }
    private setUserUploadCompletedText(): void {
        let failedRecords: number;
        if (this.bulkNotification.successRecords && this.bulkNotification.totalRecords) {
            failedRecords = this.bulkNotification.totalRecords - this.bulkNotification.successRecords;
            if (failedRecords === 0) {
                this.message = this.translationService.getTranslation(this.bulkNotification.text + 'Success')
                    .replace('<SuccessRecords>', this.bulkNotification.successRecords)
                    .replace('<TotalRecords>', this.bulkNotification.totalRecords);
            } else {
                this.message = this.translationService.getTranslation(this.bulkNotification.text + 'Failed')
                    .replace('<FailedRecords>', failedRecords ? failedRecords : this.bulkNotification.totalRecords)
                    .replace('<TotalRecords>', this.bulkNotification.totalRecords);
            }
        } else {
            this.message = this.translationService.getTranslation(this.bulkNotification.text + 'Failed')
                .replace('<FailedRecords>', failedRecords ? failedRecords : this.bulkNotification.totalRecords)
                .replace('<TotalRecords>', this.bulkNotification.totalRecords);
        }
    }
    private setNotificationIcon(): void {
        switch (this.notification.Status) {
            case NotificationStatusEnum.Success: this.icon = 'identity-icon-toast-msg-success'; break;
            case NotificationStatusEnum.Error: this.icon = 'identity-icon-error-warning'; break;
            case NotificationStatusEnum.InProgress: this.showIcon = false; break;
            default: this.icon = 'identity-icon-toast-msg-info';
        }
    }

    showText() {
        this.isReadMore = !this.isReadMore
        this.label = this.isReadMore ? this.more : this.less;
     }
}
