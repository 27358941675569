

import { Injectable } from "@angular/core";
import { HttpResponseBase, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { IntergratedApplicationTypes } from "../models/IntergratedApplicationTypes";
import { CompanyFeatureEnum } from "../shared/enums/CompanyFeatureEnum";
import { Constants } from "../constants";


@Injectable()
export class Utilities {

    static readonly captionAndMessageSeparator = ":";
    static readonly noNetworkMessageCaption = "No Network";
    static readonly noNetworkMessageDetail = "The server cannot be reached";
    static readonly accessDeniedMessageCaption = "Access Denied!";
    static readonly accessDeniedMessageDetail = "";

    static getHttpResponseMessage(data: HttpResponseBase | any): string[] {

        const responses: string[] = [];

        if (data instanceof HttpResponseBase) {

            if (this.checkNoNetwork(data)) {
                responses.push(
                    `${this.noNetworkMessageCaption}${this.captionAndMessageSeparator} ${this.noNetworkMessageDetail}`);
            } else {
                const responseObject = this.getResponseBody(data);

                if (responseObject && (typeof responseObject === "object" || responseObject instanceof Object)) {

                    for (let key in responseObject) {
                        if (key)
                            responses.push(`${key}${this.captionAndMessageSeparator} ${responseObject[key]}`);
                        else if (responseObject[key])
                            responses.push(responseObject[key].toString());
                    }
                }
            }

            if (!responses.length && this.getResponseBody(data))
                responses.push(`${data.statusText}: ${this.getResponseBody(data).toString()}`);
        }

        if (!responses.length)
            responses.push(data.toString());

        if (this.checkAccessDenied(data))
            responses.splice(0,
                0,
                `${this.accessDeniedMessageCaption}${this.captionAndMessageSeparator} ${this.accessDeniedMessageDetail
                }`);


        return responses;
    }


    static findHttpResponseMessage(messageToFind: string,
        data: HttpResponse<any> | any,
        seachInCaptionOnly = true,
        includeCaptionInResult = false): string {

        const searchString = messageToFind.toLowerCase();
        const httpMessages = this.getHttpResponseMessage(data);

        for (let message of httpMessages) {
            const fullMessage = Utilities.splitInTwo(message, this.captionAndMessageSeparator);

            if (fullMessage.firstPart && fullMessage.firstPart.toLowerCase().indexOf(searchString) != -1) {
                return includeCaptionInResult ? message : fullMessage.secondPart || fullMessage.firstPart;
            }
        }

        if (!seachInCaptionOnly) {
            for (let message of httpMessages) {

                if (message.toLowerCase().indexOf(searchString) != -1) {
                    if (includeCaptionInResult) {
                        return message;
                    } else {
                        const fullMessage = Utilities.splitInTwo(message, this.captionAndMessageSeparator);
                        return fullMessage.secondPart || fullMessage.firstPart;
                    }
                }
            }
        }

        return null;
    }


    static getResponseBody(response: HttpResponseBase) {
        if (response instanceof HttpResponse)
            {
                return response.body;
            }
        if (response instanceof HttpErrorResponse)
            {
                return response.error || response.message || response.statusText;
            }

    }


    static checkNoNetwork(response: HttpResponseBase) {
        if (response instanceof HttpResponseBase) {
            return response.status == 0;
        }

        return false;
    }

    static checkAccessDenied(response: HttpResponseBase) {
        if (response instanceof HttpResponseBase) {
            return response.status == 403;
        }

        return false;
    }

    static checkNotFound(response: HttpResponseBase) {
        if (response instanceof HttpResponseBase) {
            return response.status == 404;
        }

        return false;
    }

    static checkIsLocalHost(url: string, base?: string) {
        if (url) {
            const location = new URL(url, base);
            return location.hostname === "localhost" || location.hostname === "127.0.0.1";
        }

        return false;
    }


    static getQueryParamsFromString(paramString: string) {

        if (!paramString){
            return null;
        }


        const params: { [key: string]: string } = {};

        for (let param of paramString.split("&")) {
            const keyValue = Utilities.splitInTwo(param, "=");
            params[keyValue.firstPart] = keyValue.secondPart;
        }

        return params;
    }


    static splitInTwo(text: string, separator: string): { firstPart: string, secondPart: string } {
        const separatorIndex = text.indexOf(separator);

        if (separatorIndex == -1) {
            return { firstPart: text, secondPart: null };
        }


        const part1 = text.substr(0, separatorIndex).trim();
        const part2 = text.substr(separatorIndex + 1).trim();

        return { firstPart: part1, secondPart: part2 };
    }


    static safeStringify(object) {

        let result: string;

        try {
            result = JSON.stringify(object);
            return result;
        } catch (error) {

        }

        const simpleObject = {};

        for (let prop in object) {
            if (!object.hasOwnProperty(prop)) {
                continue;
            }
            if (typeof (object[prop]) == "object") {
                continue;
            }
            if (typeof (object[prop]) == "function") {
                continue;
            }
            simpleObject[prop] = object[prop];
        }

        result = `[***Sanitized Object***]: ${JSON.stringify(simpleObject)}`;

        return result;
    }


    static JSonTryParse(value: string) {
        try {
            return JSON.parse(value);
        } catch (e) {
            if (value === "undefined"){
                return void 0;
            }
            return value;
        }
    }


    static TestIsObjectEmpty(obj: any) {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    }


    static TestIsUndefined(value: any) {
        return typeof value === "undefined";
        //return value === undefined;
    }


    static TestIsString(value: any) {
        return typeof value === "string" || value instanceof String;
    }


    static capitalize(text: string) {
        if (text) {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        }
        else {
            return text;
        }

    }


    static toTitleCase(text: string) {
        return text.replace(/\w\S*/g,
            (subString) => {
                return subString.charAt(0).toUpperCase() + subString.substr(1).toLowerCase();
            });
    }


    static toLowerCase(items: string);
    static toLowerCase(items: string[]);
    static toLowerCase(items: any): string | string[] {

        if (items instanceof Array) {
            const loweredRoles: string[] = [];

            for (let i = 0; i < items.length; i++) {
                loweredRoles[i] = items[i].toLowerCase();
            }

            return loweredRoles;
        } else if (typeof items === "string" || items instanceof String) {
            return items.toLowerCase();
        }
    }


    static uniqueId() {
        return this.randomNumber(1000000, 9000000).toString();
    }


    static randomNumber(min: number, max: number) {
        const crypto = window.crypto;
        let array = new Uint32Array(1);
        crypto.getRandomValues(array);
        return Math.floor(array[0] * (max - min + 1) + min);
    }


    static baseUrl() {
        let base = "";

        if (window.location.origin)
            base = window.location.origin;
        else
            base = window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port ? `:${window.location.port}` : "");

        return base.replace(/\/$/, "");
    }


    static printDateOnly(date: Date) {

        date = new Date(date);

        const dayNames = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
        const monthNames = new Array("January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December");

        const dayOfWeek = date.getDay();
        const dayOfMonth = date.getDate();
        let sup = "";
        const month = date.getMonth();
        const year = date.getFullYear();

        if (dayOfMonth == 1 || dayOfMonth == 21 || dayOfMonth == 31) {
            sup = "st";
        } else if (dayOfMonth == 2 || dayOfMonth == 22) {
            sup = "nd";
        } else if (dayOfMonth == 3 || dayOfMonth == 23) {
            sup = "rd";
        } else {
            sup = "th";
        }

        const dateString = dayNames[dayOfWeek] + ", " + dayOfMonth + sup + " " + monthNames[month] + " " + year;

        return dateString;
    }

    static printTimeOnly(date: Date) {

        date = new Date(date);

        let period = "";
        let minute = date.getMinutes().toString();
        let hour = date.getHours();

        period = hour < 12 ? "AM" : "PM";

        if (hour == 0) {
            hour = 12;
        }
        if (hour > 12) {
            hour = hour - 12;
        }

        if (minute.length == 1) {
            minute = `0${minute}`;
        }

        const timeString = hour + ":" + minute + " " + period;


        return timeString;
    }

    static printDate(date: Date, separator = "at") {
        return `${Utilities.printDateOnly(date)} ${separator} ${Utilities.printTimeOnly(date)}`;
    }


    static printFriendlyDate(date: Date, separator = "-") {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const test = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        if (test.toDateString() == today.toDateString())
            return `Today ${separator} ${Utilities.printTimeOnly(date)}`;
        if (test.toDateString() == yesterday.toDateString())
            return `Yesterday ${separator} ${Utilities.printTimeOnly(date)}`;
        else
            return Utilities.printDate(date, separator);
    }

    static printShortDate(date: Date, separator = "/", dateTimeSeparator = "-") {

        let day = date.getDate().toString();
        let month = (date.getMonth() + 1).toString();
        const year = date.getFullYear();

        if (day.length == 1)
            day = `0${day}`;

        if (month.length == 1)
            month = `0${month}`;

        return `${month}${separator}${day}${separator}${year} ${dateTimeSeparator} ${Utilities.printTimeOnly(date)}`;
    }


    static parseDate(date) {

        if (date) {

            if (date instanceof Date) {
                return date;
            }

            if (typeof date === "string" || date instanceof String) {
                if (date.search(/[a-su-z+]/i) == -1)
                    date = date + "Z";

                return new Date(date);
            }

            if (typeof date === "number" || date instanceof Number) {
                return new Date(date as any);
            }
        }
    }


    static printDuration(start: Date, end: Date) {

        start = new Date(start);
        end = new Date(end);

        // get total seconds between the times
        let delta = Math.abs(start.valueOf() - end.valueOf()) / 1000;

        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        // what's left is seconds
        const seconds = delta % 60; // in theory the modulus is not required


        let printedDays = "";

        if (days)
            printedDays = `${days} days`;

        if (hours)
            printedDays += printedDays ? `, ${hours} hours` : `${hours} hours`;

        if (minutes)
            printedDays += printedDays ? `, ${minutes} minutes` : `${minutes} minutes`;

        if (seconds)
            printedDays += printedDays ? ` and ${seconds} seconds` : `${seconds} seconds`;


        if (!printedDays)
            printedDays = "0";

        return printedDays;
    }


    static getAge(birthDate, otherDate) {
        birthDate = new Date(birthDate);
        otherDate = new Date(otherDate);

        let years = (otherDate.getFullYear() - birthDate.getFullYear());

        if (otherDate.getMonth() < birthDate.getMonth() ||
            otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
            years--;
        }

        return years;
    }


    static searchArray(searchTerm: string, caseSensitive: boolean, ...values: any[]) {

        if (!searchTerm)
            return true;


        if (!caseSensitive)
            searchTerm = searchTerm.toLowerCase();

        for (let value of values) {

            if (value != null) {
                let strValue = value.toString();

                if (!caseSensitive)
                    strValue = strValue.toLowerCase();

                if (strValue.indexOf(searchTerm) !== -1)
                    return true;
            }
        }

        return false;
    }


    static moveArrayItem(array: any[], oldIndex, newIndex) {

        while (oldIndex < 0) {
            oldIndex += this.length;
        }

        while (newIndex < 0) {
            newIndex += this.length;
        }

        if (newIndex >= this.length) {
            let k = newIndex - this.length;
            while ((k--) + 1) {
                array.push(undefined);
            }
        }

        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    }


    static expandCamelCase(text: string) {

        if (!text)
            return text;

        return text.replace(/([A-Z][a-z]+)/g, " $1")
            .replace(/([A-Z][A-Z]+)/g, " $1")
            .replace(/([^A-Za-z ]+)/g, " $1");
    }


    static testIsAbsoluteUrl(url: string) {

        const r = new RegExp("^(?:[a-z]+:)?//", "i");
        return r.test(url);
    }


    static convertToAbsoluteUrl(url: string) {

        return Utilities.testIsAbsoluteUrl(url) ? url : `//${url}`;
    }


    static removeNulls(obj) {
        const isArray = obj instanceof Array;

        for (let k in obj) {
            if (obj[k] === null) {
                isArray ? obj.splice(Number(k), 1) : delete obj[k];
            } else if (typeof obj[k] == "object") {
                Utilities.removeNulls(obj[k]);
            }

            if (isArray && obj.length == Number(k)) {
                Utilities.removeNulls(obj);
            }
        }

        return obj;
    }


    static debounce(func: (...args) => any, wait: number, immediate?: boolean) {
        var timeout: any;

        return function() {
            var context = this;
            var args_ = arguments;

            const later = function() {
                timeout = null;
                if (!immediate)
                    func.apply(context, args_);
            };

            const callNow = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);

            if (callNow)
                func.apply(context, args_);
        };
    }

    static getFeatureNamelbl(value: CompanyFeatureEnum): string {
        switch(value){
            case CompanyFeatureEnum.ADDTHIRDPARTYAPPLICATIONS:
                return 'lbl_add_third_party_app';
            case CompanyFeatureEnum.DUOAUTHENTICATIONFACTOR:
                return 'lbl_duo_auth_factor';
            case CompanyFeatureEnum.EMAILAUTHENTICATIONFACTOR:
                return 'lbl_email_auth_factor';
            case CompanyFeatureEnum.GOOGLEAUTHENTICATIONFACTOR:
                return 'lbl_google_auth_factor';
            case CompanyFeatureEnum.IMPORTDIRECTORYUSERS:
                return 'lbl_import_directory_user';
            case CompanyFeatureEnum.MICROSOFTAUTHENTICATIONFACTOR:
                    return 'lbl_microsoft_auth_factor';
            default:
                return '';
        }
    }

    static getFeatureNamelblForAccountAdmin(value: CompanyFeatureEnum): string {
        switch(value){
            case CompanyFeatureEnum.ADDTHIRDPARTYAPPLICATIONS:
                return 'lbl_add_third_party_app';
            case CompanyFeatureEnum.DUOAUTHENTICATIONFACTOR:
                return 'lbl_duo';
            case CompanyFeatureEnum.EMAILAUTHENTICATIONFACTOR:
                return 'lbl_otp_by_email';
            case CompanyFeatureEnum.GOOGLEAUTHENTICATIONFACTOR:
                return 'lbl_google_authentication';
            case CompanyFeatureEnum.IMPORTDIRECTORYUSERS:
                return 'lbl_import_directory_user';
            case CompanyFeatureEnum.MICROSOFTAUTHENTICATIONFACTOR:
                return 'lbl_microsoft_authentication';
            default:
                return '';
        }
    }

    static getFeatureIcon(value: CompanyFeatureEnum): string {
        switch(value){
            case CompanyFeatureEnum.DUOAUTHENTICATIONFACTOR:
                return this.getImagePath('icon-duo.png');
            case CompanyFeatureEnum.EMAILAUTHENTICATIONFACTOR:
                return this.getImagePath('icon-email-auth.png')
            case CompanyFeatureEnum.GOOGLEAUTHENTICATIONFACTOR:
                return this.getImagePath('icon-googleauth.png')
            case CompanyFeatureEnum.MICROSOFTAUTHENTICATIONFACTOR:
                return this.getImagePath('icon-microsoft-authenticator.png')
            default:
                return '';
        }
    }

    static getAppLogoByType(applicationType: number): string {

        var noImage;
        switch (applicationType) {
            case IntergratedApplicationTypes.SotiSnap:
                noImage = this.getSnapSvgImage();
                break;
            case IntergratedApplicationTypes.SotiInsight:
                noImage = this.getInsightSvgImage();
                break;
            case IntergratedApplicationTypes.SotiCentral:
                noImage = this.getCentralSvgImage();
                break;
            case IntergratedApplicationTypes.MobiControl:
                noImage = this.getMobicontrolSvgImage();
                break;
            case IntergratedApplicationTypes.SotiConnect:
                noImage = this.getConnectSvgImage();
                break;
            case IntergratedApplicationTypes.SotiAssist:
                noImage = this.getAssistSvgImage();
                break;
            default:
                noImage = this.getDefaultAppSvgImage();
                break;
        }
        return noImage;
    }

    static getUrl(url:string): string{
        return new URL(url,import.meta.url).href;
    }

    static getImagePath(imageName:string): string{
        return `${Constants.ImagesPath}/${imageName}`;
    }
    static getAssetPath(): string{
        return `${Constants.ImagesPath}/`;
    }

    static getAssestPath = () => Utilities.getAssetPath();
    static getSnapSvgImage = () => Utilities.getImagePath(Constants.SnapSvgImage);
    static getInsightSvgImage = () => Utilities.getImagePath(Constants.InsightSvgImage);
    static getCentralSvgImage = () => Utilities.getImagePath(Constants.CentralSvgImage);
    static getMobicontrolSvgImage = () => Utilities.getImagePath(Constants.MobiSvgImage);
    static getConnectSvgImage = () => Utilities.getImagePath(Constants.ConnectSvgImage);
    static getAssistSvgImage = () => Utilities.getImagePath(Constants.AssistSvgImage);
    static getXsightSvgImage = () => Utilities.getImagePath(Constants.XsightSvgImage);
    static getDefaultAppSvgImage = () => Utilities.getImagePath(Constants.DefaultAppSvgImage);
    static getIconBrowseFilesSvgImage = () => Utilities.getImagePath(Constants.IconBrowseFilesSvgImage);
    static getDefaultAppPngImage = () => Utilities.getImagePath(Constants.DefaultAppPngImage);
    static getLogoSotiOneDarkMode = () => Utilities.getImagePath(Constants.LogoSotiOneDarkMode);
    static getLogoSotiOneDefaultMode = () => Utilities.getImagePath(Constants.LogoSotiOneDefaultMode);
    static getLogoSotiOne = () => Utilities.getImagePath(Constants.LogoSotiOne);

    static cookies = {
        getItem: (sKey) => {
            return decodeURIComponent(document.cookie.replace(
                    new RegExp(
                        `(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&")
                        }\\s*\\=\\s*([^;]*).*$)|^.*$`),
                    "$1")) ||
                null;
        },
        setItem: (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
            if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
                return false;
            }

            var sExpires = "";

            if (vEnd) {
                switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : `; max-age=${vEnd}`;
                    break;
                case String:
                    sExpires = `; expires=${vEnd}`;
                    break;
                case Date:
                    sExpires = `; expires=${vEnd.toUTCString()}`;
                    break;
                }
            }

            document.cookie = encodeURIComponent(sKey) +
                "=" +
                encodeURIComponent(sValue) +
                sExpires +
                (sDomain ? `; domain=${sDomain}` : "") +
                (sPath ? `; path=${sPath}` : "") +
                (bSecure ? "; secure" : "");
            return true;
        },
        removeItem: (sKey, sPath, sDomain) => {
            if (!sKey) {
                return false;
            }
            document.cookie = encodeURIComponent(sKey) +
                "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
                (sDomain ? `; domain=${sDomain}` : "") +
                (sPath ? `; path=${sPath}` : "");
            return true;
        },
        hasItem: (sKey) => {
            return (new RegExp(`(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&")}\\s*\\=`)).test(
                document.cookie);
        },
        keys: () => {
            var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
                .split(/\s*(?:\=[^;]*)?;\s*/);
            for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
                aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
            }
            return aKeys;
        }
    };
}
