import { SystemParams } from './../models/systemparams.model';

import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, CanMatchFn, Route, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, from, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Constants } from '../constants';
import { Company } from '../models/company.model';
import { LogoutResponse } from '../models/logoutResponse.model';
import { PermissionValues } from '../models/permission.model';
import { User } from '../models/user.model';
import { Permissions } from '../shared/enums/Permissions';
import { AccountService } from './account-services/account.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys';
import { LocalStoreManager } from './local-store-manager.service';

export const canActivateGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const authService = inject(AuthService);
    const url = state.url;
    const routePermissions = route.data['permission'];

    return authService.checkLogin(url, routePermissions);  // Check login and permissions
};

// CanActivateChild Guard
export const canActivateChildGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const result = canActivateGuard(route, state);

    // Convert possible return types to Observable<boolean>
    if (result instanceof Observable) {
        return result.pipe(
            map(res => typeof res === 'boolean' ? res : true) // Map UrlTree or other non-boolean values to `true`
        );
    } else if (result instanceof Promise) {
        return from(result).pipe(
            map(res => typeof res === 'boolean' ? res : true)
        );
    } else {
        // Handle the case when `result` is directly boolean or UrlTree
        return of(typeof result === 'boolean' ? result : true);
    }
};

// CanLoad Guard
export const canMatchGuard: CanMatchFn = (route: Route): Observable<boolean> => {
    const authService = inject(AuthService);
    const url = `/${route.path}`;

    return authService.checkLogin(url, []);  // Check login when loading a route
};

export const canActivateCookieGuard : CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {        
    const authService = inject(AuthService);
    return authService.checkCookie();
}

export const canActivateChildCookieGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    const result = canActivateCookieGuard(route, state);
    return typeof result === 'boolean' ? result : false;
}

export const canMatchCookieGuard: CanMatchFn = (route: Route): boolean => {        
    const authService = inject(AuthService);
    return authService.checkCookie();
}

@Injectable()
export class AuthService {
    public get homeUrl() { return this.configurations.homeUrl; }
    public underMaintenance: Subject<boolean> = new Subject<boolean>();
    public isHeaderRequired: Subject<boolean> = new Subject<boolean>();
    public loginRedirectUrl: string;
    public logoutRedirectUrl: string;
    public shouldRedirectUserToLoginPage = new BehaviorSubject<boolean>(false);
    public reLoginDelegate: () => void;
    public showLoadingIconOnLogin = false;
    private relayState: string = '/';
    private previousIsLoggedInCheck = false;
    public _loginStatus = new BehaviorSubject(false);
    public _isUserFullyLoggedIn = new BehaviorSubject(false);
    public tempAccessToken = '';
    private _logoutNotifier = new BehaviorSubject(false);
    private _logoutResponse: LogoutResponse = new LogoutResponse();

    private loginRoute: string = '/login';
    private logoutRoute: string = '/logout';
    private errorRoute: string = '/error';
    private eulaRoute: string = '/eula';
    private dashBoardRoute: string = './';
    private cookieRoute = "./cookiedisable";

    constructor(
        private accountService: AccountService, 
        private router: Router, 
        private configurations: ConfigurationService, 
        private localStorage: LocalStoreManager,
        ) {
        
    }

    public subScribeToLoginPage(): void {
        this.shouldRedirectUserToLoginPage.subscribe((value) => {
            if (value == true) {
                this.showLoadingIconOnLogin = true;
                this.redirectToLogin();                
            }
        });

    }

    public HasPermission(permissions: Permissions[]): boolean {
        let user: User = this.currentUser;
        if (user && user.UserPermissions) {
            return user.UserPermissions.some(x => permissions.indexOf(x) >= 0);
        }
        return false;
    }

    public redirectToLogoutUrl(): void {
        this.router.navigate(['logout']);
    }

    public redirectLogoutUser(): void {
        this.router.navigate(['']);
    }

    public redirectLogoutUsertoLogin(): void {
        //    this.router.navigate(['login']);
    }

    public redirectToReloginUrl(relayState: string): void {
        this.relayState = relayState;
        this.router.navigate(['logout'], { queryParams: { IsRestricted: 'true'}});
    }

    public logout(): void {
        this.accountService.logout().subscribe(
            response => {
                this._logoutResponse.SamlRequest = response.SamlRequest;
                this._logoutResponse.Destination = response.Destination;
                this._logoutResponse.RelayState = response.RelayState;
                this._logoutNotifier.next(true);
                this.clearLocalStorage();
            },
            error => {
                this.clearLocalStorage();
            }
        );
    }

    public logoutForRestrictedUser(): void {
        this.accountService.logoutForRestrictedUser(this.relayState).subscribe(
            response => {
                this._logoutResponse.SamlRequest = response.SamlRequest;
                this._logoutResponse.Destination = response.Destination;
                this._logoutResponse.RelayState = response.RelayState;
                this._logoutNotifier.next(true);
                this.clearLocalStorage();
            },
            error => {
                this.clearLocalStorage();
            }
        );
    }

    public ssoLogout(): void {
        var idpGenericUrl = this.localStorage.getData(DBkeys.IDP_GENERIC_URL);
        console.log('logout fired from singalR context');
        this.clearLocalStorage();
        window.location.href = idpGenericUrl;
    }

    public clearLocalStorage() {
        this.localStorage.deleteData(DBkeys.IsUserLoggedIn);
        this.localStorage.deleteData(DBkeys.CURRENT_USER);
        this.localStorage.deleteData(DBkeys.CURRENT_COMPANY);
        this.localStorage.deleteData(DBkeys.CURRENT_USER_CUSTOMSETTIGNS);
        this.localStorage.deleteData(Constants.PREFERENCE);
        // this.eraseCookieFromAllPaths(); //You can not delete host cookie first from here, otherwise host logout endpoint would be unauthorized and whole flow break.
        this.configurations.clearLocalChanges();


    }

    public logoutNotifier(): Observable<boolean> {
        return this._logoutNotifier.asObservable();
    }

    public get logoutResponse(): LogoutResponse {
        return this._logoutResponse;
    }

    public setCurrentUser(userData: User): void {
        userData.LastName = (userData.LastName === null || userData.LastName === undefined) ? '' : userData.LastName;
        let user = this.localStorage.saveSessionData(userData, DBkeys.CURRENT_USER);
        this._isUserFullyLoggedIn.next(true);
        this.reevaluateLoginStatus(userData);

    }

    eraseCookieFromAllPaths() {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
            var d = window.location.hostname.split(".");
            while (d.length > 0) {
                var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                var p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                }
                d.shift();
            }
        }
    }

    private redirectToLogin(): void {
        this.loginRedirectUrl = this.loginRedirectUrl ? this.loginRedirectUrl : this.relayState;
                this.accountService
                    .getredirectSSO(this.loginRedirectUrl)
                    .subscribe(
                        response => {

                            if (this.loginRedirectUrl !== undefined && this.loginRedirectUrl !== null) {
                                this.relayState = this.loginRedirectUrl;
                                this.loginRedirectUrl = null;
                            }
                            this.showLoadingIconOnLogin = false;
                            this.shouldRedirectUserToLoginPage.complete();
                            window.location.href = response;

                        },
                        error => {
                            this.showLoadingIconOnLogin = false;
                        });
    }

    private reevaluateLoginStatus(currentUser?: User) {
        let user = currentUser || this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
        let isLoggedIn = user != null;

        if (this.previousIsLoggedInCheck != isLoggedIn) {
            setTimeout(() => {

                this._loginStatus.next(isLoggedIn);
                if (!isLoggedIn) {
                    this.redirectLogoutUsertoLogin();
                }
            });
        }

        this.previousIsLoggedInCheck = isLoggedIn;



    }

    public checkLogin(url: string, routePermissions: Permissions[]): Observable<boolean> {
        if ((localStorage.getItem(DBkeys.IsUserLoggedIn) !== undefined && localStorage.getItem(DBkeys.IsUserLoggedIn) !== null) || 
            (localStorage.getItem(DBkeys.CURRENT_USER) !== undefined && localStorage.getItem(DBkeys.CURRENT_USER) !== null)) {

            if (url === this.loginRoute) {
                this.router.navigate([this.dashBoardRoute]);
                return of(false);  // Use 'of()' instead of 'Observable.of()'
            }

            let userData: User = this.currentUser;
            if (userData && !userData.EulaAccepted && url !== '/logout') {
                this.router.navigate([this.eulaRoute]);
                return of(false);
            }
            var restrictedSuperAdminRoutes = [Constants.sotionePortalRoutingPath, Constants.newDeviceSignInRoutingPath]
            if (restrictedSuperAdminRoutes.map(str => str.toLowerCase()).includes(url.toLowerCase()) && this.currentUser.IsIdentityAdministrator)
            {
                this.router.navigate([this.dashBoardRoute]);
                return of(false);
            }

            if (routePermissions != undefined && routePermissions.length != 0 && userData != null) {
                if (routePermissions.length > 0 && this.isUserHasPermission(routePermissions, userData.UserPermissions)) {
                    return of(true);
                } else {
                    this.router.navigate([this.dashBoardRoute]);
                    return of(false);
                }
            } else if (routePermissions != undefined && routePermissions.length != 0) {
                return this._isUserFullyLoggedIn.pipe(
                    filter(isLoggedIn => isLoggedIn),  // Emit only true values
                    take(1),  // Take the first true value and then complete
                    switchMap(() => {
                        return this.checkLogin(url, routePermissions);
                    })
                );
            } else {
                return of(true);
            }
        } else {
            if (url.indexOf(this.logoutRoute) !== -1 || url.indexOf(this.errorRoute) !== -1) {
                return of(true);
            }
            this.loginRedirectUrl = url;
            this.shouldRedirectUserToLoginPage.next(true);
            return of(false);
        }
    }

    private isUserHasPermission(routePermissions: Permissions[], userPermissions: Permissions[]): boolean {
        return userPermissions.filter(perm => routePermissions.find(f => f === perm)).length > 0;
    }

    public checkCookie(): boolean { 
        if (window.navigator.cookieEnabled === false) {
            this.router.navigate([this.cookieRoute]);         
        }        
        return true;
    }

    get currentUser(): User {
        let user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
        this.reevaluateLoginStatus(user);
        return user;
    }

    get currentCompany(): any {
        let company = this.localStorage.getDataObject<Company>(DBkeys.CURRENT_COMPANY);

        return company;
    }


    setCurrentCompany(companyData: Company) {
        this.localStorage.saveSessionData(companyData, DBkeys.CURRENT_COMPANY);
    }
    setSystemParameters(SystemParamsData: SystemParams) {
        this.localStorage.saveSessionData(SystemParamsData, DBkeys.SYSTEM_PARAMTER);
    }
    get systemParams(): SystemParams {
        let systemParams = this.localStorage.getDataObject<SystemParams>(DBkeys.SYSTEM_PARAMTER);

        return systemParams;
    }
    get userPermissions(): PermissionValues[] {
        return this.localStorage.getDataObject<PermissionValues[]>(DBkeys.USER_PERMISSIONS) || [];
    }

    get accessToken(): string {
        return this.localStorage.getData(DBkeys.IsUserLoggedIn);
    }
    setAccessToken(key, value) {
        this.localStorage.deleteData(DBkeys.IsUserLoggedIn);
        this._loginStatus.next(true);
        this.localStorage.saveSessionData(value, DBkeys.IsUserLoggedIn);
    }
    get accessTokenExpiryDate(): Date {

        this.reevaluateLoginStatus();
        return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
    }

    get isSessionExpired(): boolean {

        if (this.accessTokenExpiryDate == null) {
            return true;
        }

        return !(this.accessTokenExpiryDate.valueOf() > new Date().valueOf());
    }

    get isLoggedIn(): boolean {

        return this.currentUser != null;
    }

}
