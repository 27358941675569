/**
 * Returns an error to be thrown when attempting to find an unexisting column.
 * @param id Id whose lookup failed.
 */
export function getTableUnknownColumnError(id: string): Error {
  return Error(`Could not find column with id "${id}".`);
}

/**
 * Returns an error to be thrown when two column definitions have the same name.
 */
export function getTableDuplicateColumnNameError(name: string): Error {
  return Error(`Duplicate column definition name provided: "${name}".`);
}

/**
 * Returns an error to be thrown when there are multiple rows that are missing a when function.
 */
export function getTableMultipleDefaultRowDefsError(): Error {
  return Error(`There can only be one default row without a when predicate function.`);
}

/**
 * Returns an error to be thrown when there are no matching row defs for a particular set of data.
 */
export function getTableMissingMatchingRowDefError(): Error {
  return Error(`Could not find a matching row definition for the provided row data.`);
}

/**
 * Returns an error to be thrown when there is no row definitions present in the content.
 */
export function getTableMissingRowDefsError(): Error {
  return Error('Missing definitions for header and row, ' + 'cannot determine which columns should be rendered.');
}

/**
 * Returns an error to be thrown when the data source does not match the compatible types.
 */
export function getTableUnknownDataSourceError(): Error {
  return Error(`Provided data source did not match an array, Observable, or DataSource`);
}

export function getTableNoScrollableAncestorRegistered(): Error {
  return Error('No scrollable anscestor found. Please add cdkScrollable to your scrollable parent');
}
