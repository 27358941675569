/* eslint-disable @angular-eslint/no-input-rename */

import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { CdkCell, CdkCellDef, CdkColumnDef, CdkHeaderCell, CdkHeaderCellDef } from '@angular/cdk/table';
import { SotiTable } from './table';

/**
 * Cell definition for the soti-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
  selector: '[sotiCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: SotiCellDef }]
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiCellDef extends CdkCellDef {}

/**
 * Header cell definition for the soti-table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[sotiHeaderCellDef]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: SotiHeaderCellDef }]
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiHeaderCellDef extends CdkHeaderCellDef {}

/**
 * Column definition for the soti-table.
 * Defines a set of cells available for a table column.
 */
@Directive({
  selector: '[sotiColumnDef]',
  providers: [{ provide: CdkColumnDef, useExisting: SotiColumnDef }]
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiColumnDef extends CdkColumnDef {
  /** Unique name for this column. */

  @Input('sotiColumnDef')
  public set name(name: string) {
    super._setNameInput(name);
  }

  public get name(): string {
    return this._name;
  }
}

/** Header cell template container that adds the right classes and role. */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'soti-header-cell',
  host: {
    // eslint-disable-line
    class: 'soti-header-cell',
    role: 'columnheader'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiHeaderCell extends CdkHeaderCell implements OnInit {
  public get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    public columnDef: CdkColumnDef,
    public elementRef: ElementRef,
    private _renderer: Renderer2,
    private _table: SotiTable<any>
  ) {
     super(columnDef, elementRef);
     _renderer.addClass(elementRef.nativeElement, `soti-column-${columnDef.cssClassFriendlyName}`);

  }

  public ngOnInit(): void {
    // When table has more than one column definition, the cells are destroyed and recreated when changing page.
    // In this case, we have to re-set the column width it had before otherwise it will fallback to default width.
    if (
      this._table.headerWidths.has(this.columnDef.name) &&
      (this._table.hasMultipleRowDefinitions || this._table.saveColumnWidth)
    ) {
      const width = this._table.headerWidths.get(this.columnDef.name);
      this._renderer.setStyle(this.elementRef.nativeElement, 'width', `${width}px`);
      this._renderer.setStyle(this.elementRef.nativeElement, 'max-width', `${width}px`);
    }
  }
}

/** Cell template container that adds the right classes and role. */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'soti-cell',
  host: {
    // eslint-disable-line
    class: 'soti-cell',
    role: 'gridcell'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiCell extends CdkCell implements OnInit {
  constructor(
    public columnDef: CdkColumnDef,
    public elementRef: ElementRef,
    private _renderer: Renderer2,
    private _table: SotiTable<any>
  ) {
    super(columnDef, elementRef);
    _renderer.addClass(elementRef.nativeElement, `soti-column-${columnDef.cssClassFriendlyName}`);
  }

  public ngOnInit(): void {
    // When table has more than one column definition, the cells are destroyed and recreated when changing page.
    // In this case, we have to re-set the column width it had before otherwise it will fallback to default width.
    if (
      this._table.headerWidths.has(this.columnDef.name) &&
      (this._table.hasMultipleRowDefinitions || this._table.saveColumnWidth)
    ) {
      const width = this._table.headerWidths.get(this.columnDef.name);
      this._renderer.setStyle(this.elementRef.nativeElement, 'width', `${width}px`);
      this._renderer.setStyle(this.elementRef.nativeElement, 'max-width', `${width}px`);
    }
  }
}
