import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  OnDestroy,
  OnInit,
  HostListener
} from '@angular/core';
import { Subscription, merge } from 'rxjs';

import { SotiColumnDef } from '../table/cell';
import { SotiSort, SotiSortable } from './sort';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[sotiSortHeader]',
  templateUrl: './sort-header.ctrl.html',
  styleUrls: ['./sort-header.ctrl.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sorter', [
      transition(
        ':enter',
        animate(
          '225ms cubic-bezier(0.4,0.0,0.2,1)',
          keyframes([style({ transform: 'scale(0.3)', opacity: 0 }), style({ transform: 'none', opacity: 1 })])
        )
      ),
      transition(
        ':leave',
        animate(
          '225ms cubic-bezier(0.4,0.0,0.2,1)',
          keyframes([style({ transform: 'none', opacity: 1 }), style({ transform: 'scale(0.3)', opacity: 0 })])
        )
      )
    ])
  ]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SotiSortHeader implements SotiSortable, OnInit, OnDestroy {
  public hover = false;

  public id: string;

  public get sorted(): boolean {
    return this.sort.active === this.id && (this.sort.direction === 'asc' || this.sort.direction === 'desc');
  }

  private _renderSubscription: Subscription;

  constructor(private _cdr: ChangeDetectorRef, @Host() public sort: SotiSort, @Host() private _colDef: SotiColumnDef) {
    this._renderSubscription = merge(sort.sortChange, sort.directionChange).subscribe(() => {
      _cdr.markForCheck();
    });
  }

  public ngOnInit(): void {
    if (!this.id && this._colDef) {
      this.id = this._colDef.name;
    }

    this.sort.register(this);
  }

  public ngOnDestroy(): void {
    this.sort.deregister(this);
    this._renderSubscription.unsubscribe();
  }

  @HostListener('click')
  public _sort() {
    this.sort.sort(this);
  }

  @HostListener('keydown', ['$event'])
  public _sortByEnterOrSpace(event:KeyboardEvent) {
    if(event.key === 'Enter' || event.key === ' ')
    this.sort.sort(this);
  }
}
