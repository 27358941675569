import {OnInit, Directive, ElementRef, Renderer2, Input} from '@angular/core';
import { AppTranslationService } from '../../../services/app-translation.service';

@Directive({
  selector: '[character-count]',
  exportAs: 'character-count',
  host: {
    '(keydown)': 'characterCalculation()',
    '(keyup)':'characterCalculation()',
    '(blur)': 'characterCalculation()',
    '(paste)': 'onPaste()',
    '(cut)': 'onPaste()'
  }
})
export class CharacterCountDirective {
  @Input('MaxCount') _limit: number;

  private _defaultLimit: number = 400;
  private _countDisplayElement: any;
  private _countDisplayText:string;
  @Input ('currValue') cv;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2, private _translate: AppTranslationService) {}

  private characterCalculation() {
    let count: number = this._elRef.nativeElement.value.length;
    this._renderer.setValue(this._countDisplayText,''+ (count));
  }

  private onPaste(){
    setTimeout(()=> this.characterCalculation() , 0);
  }

  ngOnInit():any {       
     
    this._limit = this._limit || this._defaultLimit;

    let _labelElement: any = this._renderer.createElement('span');
    let characterText: string = this._translate.getTranslation("lbl_characterText");
    let _labelText = this._renderer.createText(characterText);
    this._renderer.appendChild(_labelElement, _labelText);
    this._renderer.appendChild(this._elRef.nativeElement.parentElement.parentElement, _labelElement);
    this._renderer.addClass(_labelElement, 'characters-label');

    this._countDisplayElement = this._renderer.createElement('span');
    if(this.cv !=undefined && this.cv.length >0)
    {
      this._countDisplayText = this._renderer.createText(''+(this.cv.length));
    }
    else
    {
      this._countDisplayText = this._renderer.createText('0');
    }
    
    this._renderer.appendChild(this._countDisplayElement, this._countDisplayText);
    this._renderer.appendChild(this._elRef.nativeElement.parentElement.parentElement, this._countDisplayElement);
    this._renderer.addClass(this._countDisplayElement, 'characters-count-current');

    let _totalCountDisplayElement = this._renderer.createElement('span');
    let _totalCountDisplayText = this._renderer.createText('/'+this._limit+'');
    this._renderer.appendChild(_totalCountDisplayElement, _totalCountDisplayText);
    this._renderer.appendChild(this._elRef.nativeElement.parentElement.parentElement, _totalCountDisplayElement);
    this._renderer.addClass(_totalCountDisplayElement, 'characters-count-total');
  }
}
