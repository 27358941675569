import {NgModule} from '@angular/core';
import {ItemsCountControl} from './items-count.ctrl';
import {CoreCommonModule} from '../../core-common.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../../../services/app-translation.service';

@NgModule({
    imports: [
        CoreCommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [
        ItemsCountControl
    ],
    providers: [],
    exports: [
        ItemsCountControl
    ]
})
export class ItemsCountControlModule {}