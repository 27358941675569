import { OnInit, Component, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {
    SOTI_MODAL_DATA,
    ModalDialogRef,
    ModalDialogService2} from '../../../../shared/controls/dialogs';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AssociatedMspTenants } from 'src/app/models/AssociatedMspTenants';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';

@Component({
    selector: 'msp-tenant-aliasname-modal',
    styleUrls: ['./msp-tenant-aliasname-modal.ctrl.scss'],
    templateUrl: './msp-tenant-aliasname-modal.ctrl.html'
})
export class MspTenantAliasNameModal implements OnInit, AfterViewInit {
    @ViewChild('aliassName') aliassName : ElementRef;
    public isLoaded: boolean = false;
    public submitted: boolean = false;
    public modalParam: any;
    public usedAliasNames: any;
    public title: string = 'lbl_add_alias_title';
    public icon: string = 'identity-icon-alias';
    public helpId: string = 'MSPAliasName';
    public addAlias: boolean = true;
    public aliasName:string = ""
    private _disposalBag: DisposalBag = new DisposalBag();

    constructor(
        private _modalRef: ModalDialogRef<MspTenantAliasNameModal>,
        private translationService: AppTranslationService,
        private companyService: CompanyService,
        private _modalDialogService: ModalDialogService2,
        @Inject(SOTI_MODAL_DATA) public data: { tenant: AssociatedMspTenants; associatedMspTenants: AssociatedMspTenants[] }
    ) {
        this.modalParam = this.data.tenant;
        this.usedAliasNames = this.data.associatedMspTenants.map(x=>x.ChildTenantAliasName !=null ? x.ChildTenantAliasName.toLowerCase(): x.ChildTenantAliasName)
    }
    
    ngOnInit() : void{
        this.aliasName = this.modalParam.ChildTenantAliasName != undefined && this.modalParam.ChildTenantAliasName != null ? this.modalParam.ChildTenantAliasName : "";
        if(this.modalParam.ChildTenantAliasName != undefined && this.modalParam.ChildTenantAliasName.trim()!= "")
        {
            this.addAlias = false;
            this.title = "lbl_edit_alias_title";
            this.icon = "identity-icon-edit";
        }
    }

    ngAfterViewInit(): void {
        this.aliassName.nativeElement.focus();
    }
    
    ngOnDestroy(): void {
        this._disposalBag.dispose();
        this._modalDialogService.closeModal(this.modalParam);
    }

    public onCancelClick(): void {
        this._modalDialogService.closeModal(this.modalParam);
    }

    public Submit(): void {
        
        this.aliasName = this.aliasName.split(/\s+/).join(" ").trim() ? this.aliasName.split(/\s+/).join(" ").trim() : null;
        this._disposalBag .nextSub = this.companyService.patchMspChildTenantAliasName(this.modalParam.ChildTenantReferenceId, this.aliasName).subscribe(() => {
            let message = this.translationService.getTranslation(this.addAlias ? 'msg_TenantAliasName_Add_Success' :'msg_TenantAliasName_Edit_Success', {
                TenantName: this.modalParam.ChildTenantNameIdentifier
              })
            this.modalParam.ChildTenantAliasName = this.aliasName
            appMessenger.postMessage(new ClientNotificationMessage(
                "",
                message,
                NotificationType.Success,
                AlertSeverityEnum.Minor));
            this._modalDialogService.closeModal(this.modalParam);
        }, (error) => {
            let message = this.translationService.getTranslation(this.addAlias ? 'msg_TenantAliasName_Add_Failure' :'msg_TenantAliasName_Edit_Failure', {
                TenantName: this.modalParam.ChildTenantNameIdentifier
              })
            appMessenger.postMessage(new ClientNotificationMessage(
                "",
                message,
                NotificationType.Error,
                AlertSeverityEnum.Minor));
            this._modalDialogService.closeModal(this.modalParam);
        });
    }
    public removeExtraSpaces(aliasName: string): string {
        return aliasName.split(/\s+/).join(" ").trim();
    }
}
