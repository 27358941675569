import { NgModule } from '@angular/core';
import { ControlItem } from './pagination-control-item/pagination-control-item.ctrl';
import { PaginationComponent } from './pagination.ctrl';
import { CoreCommonModule } from '../../core-common.module';
import { SharedDialogsModule } from '../../controls/dialogs/shared-dialogs.module';
import { PaginationComponent2 } from './pagination2.ctrl';
import { SharedSelectBoxModule } from '../select-box/shared-select-box.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../../../services/app-translation.service';
import { DropdownComponent } from '../../controls/dialogs/dropdown/dropdown-component/dropdown/dropdown.component';
import { DropdownNodeComponent } from '../../controls/dialogs/dropdown/dropdown-component/dropdown-node/dropdown-node.component';

@NgModule({
    imports: [
        CoreCommonModule,
        SharedDialogsModule,
        SharedSelectBoxModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [
        ControlItem,
        PaginationComponent,
        PaginationComponent2,
        DropdownComponent,
        DropdownNodeComponent
    ],
    exports: [
        ControlItem,
        PaginationComponent,
        PaginationComponent2,
        DropdownComponent,
        DropdownNodeComponent
    ]
})
export class SharedPaginationModule { }