import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MspManagementViewComponent } from './msp-management-view';

const mspManagementRoutes: Routes = [
    {
        path: '',
        component: MspManagementViewComponent,
        children: [
             { path: 'view', component: MspManagementViewComponent }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(mspManagementRoutes)

    ],
    declarations: [

    ],
    exports: [
        RouterModule
    ]
})
export class MspManagementRoutingModule { }
