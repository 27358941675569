import { ChangeDetectionStrategy, Component, Directive, ElementRef } from '@angular/core';
import { CdkHeaderRow, CdkRow, CDK_ROW_TEMPLATE, CdkRowDef, CdkHeaderRowDef } from '@angular/cdk/table';

/**
 * Header row definition for the mc-table.
 * Captures the header row's template and other header properties such as the columns to display.
 */
@Directive({
  selector: '[sotiHeaderRowDef]',
  providers: [{ provide: CdkHeaderRowDef, useExisting: SotiHeaderRowDef }],
  inputs: ['columns: sotiHeaderRowDef'] // eslint-disable-line
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiHeaderRowDef extends CdkHeaderRowDef {}

/**
 * Data row definition for the soti-table.
 * Captures the header row's template and other row properties such as the columns to display.
 */
@Directive({
  selector: '[sotiRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: SotiRowDef }],
  inputs: ['columns: sotiRowDefColumns', 'when: sotiRowDefWhen'] // eslint-disable-line
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiRowDef<T=object> extends CdkRowDef<T> {}

/** Header template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'soti-header-row',
  template: CDK_ROW_TEMPLATE,
  host: {
    // eslint-disable-line
    class: 'soti-header-row',
    role: 'row'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SotiHeaderRow extends CdkHeaderRow {
  public get element(): HTMLElement {
    return this._el.nativeElement;
  }

  constructor(private _el: ElementRef) {
    super();
  }
}

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'soti-row',
  template: CDK_ROW_TEMPLATE,
  host: {
    // eslint-disable-line
    class: 'soti-row',
    role: 'row'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SotiRow extends CdkRow {
  public get element(): HTMLElement {
    return this._el.nativeElement;
  }

  constructor(private _el: ElementRef) {
    super();
  }
}
