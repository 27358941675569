import {NgModule} from '@angular/core';

import {SearchBoxControl} from './search-box.ctrl';
import {CoreCommonModule} from '../../core-common.module';

@NgModule({
    imports: [
        CoreCommonModule
    ],
    declarations: [
        SearchBoxControl
    ],
    exports: [
        SearchBoxControl
    ],
    providers: []
})
export class SharedSearchBoxModule {
}
