import { Component, OnInit, Input, OnChanges, ElementRef, ViewChild, AfterViewInit, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { AssociatedMspTenants } from 'src/app/models/AssociatedMspTenants';
import { ModalDialogService2 } from 'src/app/shared/controls/dialogs';
import { MspTenantAliasNameModal } from '../Modals/msp-tenant-aliasname-modal/msp-tenant-aliasname-modal.ctrl';
import { PopUp2 } from 'src/app/shared/controls/dialogs/pop-up/pop-up2.ctrl';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { appMessenger } from 'src/app/shared/controls/toaster-messages/AppMessenger';
import { ClientNotificationMessage, NotificationType } from 'src/app/shared/utils/toast-utils/ClientNotificationMessage';
import { AlertSeverityEnum } from 'src/app/shared/enums/AlertSeverityEnum';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Permissions } from '../../../shared/enums/Permissions';



@Component({
    selector: 'msp-tenant-listing',
    templateUrl: './msp-tenant-listing.component.html',
    styleUrls: ['./msp-tenant-listing.component.scss'],
})
export class MspTenantListingComponent implements OnChanges, AfterViewInit, OnInit {
    @ViewChildren('divToMeasure') public divToMeasure: QueryList<ElementRef>;
    @ViewChild('parentdiv') public parentdiv: ElementRef;
    public IsIdentityAdministrator: boolean = false;

    @Input('associatedMspTenants')
    public associatedMspTenants: AssociatedMspTenants[] = null;
    
    @ViewChild('popup')
    private attributesPopup: PopUp2;
    
    @Output()
    public selectChildTenant: EventEmitter<AssociatedMspTenants> = new EventEmitter<AssociatedMspTenants>();

    public childTenantName: string;
    public selectedChild: AssociatedMspTenants;
    private _disposalBag: DisposalBag = new DisposalBag();
    public keyWord: string = '';
    public filteredTenants: any[];
    public noApplicationFound: boolean = false;
    public showTooltip: boolean = false;
    public childReferenceId: string;
    public threeDotSelected: number = -1;
    public showEditAliasName: boolean = false;
    public manageMspSupport: Permissions[];

    constructor(
        private _modalDialogService: ModalDialogService2,
        private translationService: AppTranslationService,
        private companyService: CompanyService
    ) {
    }
    ngOnInit(): void {
        this.manageMspSupport = [Permissions.MANAGEMSPSUPPORT];
    }

    ngOnChanges(): void {
        this.filteredTenants = this.associatedMspTenants;
    }
    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.isShowTooltip();
            this.childTenantName = this.associatedMspTenants[0].ChildTenantName;
            this.childReferenceId = this.filteredTenants[0].ChildTenantReferenceId;
        }, 0);

    }
    public ngOnDestroy(): void {
        this._disposalBag.dispose();
    }

    public selectTenant(tenantName: string, tenantReferenceId: string): void 
    {
        if(this.childReferenceId != tenantReferenceId)
        {
            this.childTenantName = tenantName;
            this.childReferenceId = tenantReferenceId;
            this.selectedChild = this.associatedMspTenants.find(x=>x.ChildTenantReferenceId === tenantReferenceId);
            this.selectChildTenant.emit(this.selectedChild);
        }
    }

    public OnValueChange(keyword: string): void {
        this.keyWord = keyword;
        this.setResultFound(false);
        if (this.keyWord == '') {
            this.setResultFound(false);
            this.filteredTenants = this.associatedMspTenants;
        } else {

            this.filteredTenants = this.filterApplicationByKeyword(this.associatedMspTenants, this.keyWord);
            if (this.filteredTenants.length <= 0) {
                this.setResultFound(true);
            }
        }
    }


    public setResultFound(isFound: boolean) {
        this.noApplicationFound = isFound;
    }

    public filterApplicationByKeyword(associatedTenants: AssociatedMspTenants[], keyword: string): AssociatedMspTenants[] {

        let matches: AssociatedMspTenants[] = [];
        let arrayThatContainsMatches = associatedTenants.filter(person =>
            person.ChildTenantNameIdentifier.toLowerCase().match(keyword.toLowerCase()));
        let arrayThatContainsAliasNameMatches = associatedTenants.filter(person =>
            person.ChildTenantAliasName != null && person.ChildTenantAliasName.toLowerCase().match(keyword.toLowerCase()));

        //Exact match
        let exactMatches = arrayThatContainsMatches.filter(person =>
            person.ChildTenantNameIdentifier.toLowerCase() === keyword.toLowerCase()
        );
        if (exactMatches.length > 0) {
            matches = matches.concat(exactMatches);
        }
        exactMatches = arrayThatContainsAliasNameMatches.filter(person =>
            person.ChildTenantAliasName.toLowerCase() === keyword.toLowerCase()
        );
        if (exactMatches.length > 0) {
            matches = matches.concat(exactMatches);
        }
        
        //prefix match
        let prefixMatches = arrayThatContainsMatches.filter(person =>
            person.ChildTenantNameIdentifier.toLowerCase().startsWith(keyword.toLowerCase())
        );
        if (prefixMatches.length > 0) {
            matches = matches.concat(prefixMatches);
        }
        prefixMatches = arrayThatContainsAliasNameMatches.filter(person =>
            person.ChildTenantAliasName.toLowerCase().startsWith(keyword.toLowerCase())
        );
        if (prefixMatches.length > 0) {
            matches = matches.concat(prefixMatches);
        }

        //sufix match
       let suffixMatches = arrayThatContainsMatches.filter(person =>
            person.ChildTenantNameIdentifier.toLowerCase().endsWith(keyword.toLowerCase())
        );
        if (suffixMatches.length > 0) {
            matches = matches.concat(suffixMatches);
        }
        suffixMatches = arrayThatContainsAliasNameMatches.filter(person =>
            person.ChildTenantAliasName.toLowerCase().endsWith(keyword.toLowerCase())
        );
        if (suffixMatches.length > 0) {
            matches = matches.concat(suffixMatches);
        }

        //anyother match
        matches = matches.concat(arrayThatContainsMatches);
        matches = matches.concat(arrayThatContainsAliasNameMatches);
        let uniqueMatches = Array.from(new Set(matches));

        return uniqueMatches;
    }

    public isShowTooltip(): void {
        if (!this.parentdiv || !this.divToMeasure || this.divToMeasure.toArray().length == 0) {
            return;
        }
        let divparentWidth = this.parentdiv.nativeElement.offsetWidth - 48;

        this.divToMeasure.forEach((divToMeasure, index) => {
            let divItemWidth = divToMeasure.nativeElement.offsetWidth;

            this.filteredTenants[index].showTooltip = divItemWidth > divparentWidth;
        });
    }

    public openAliasNameModal(tenant: AssociatedMspTenants): void {
        this.attributesPopup.close()
        let dialogRef = this._modalDialogService.open(MspTenantAliasNameModal, {tenant: tenant, associatedMspTenants: this.associatedMspTenants});
        dialogRef.closed().subscribe((result: any) => {
            this.selectChildTenant.emit(result);
        });

    }

    public deleteAliasName(tenant: AssociatedMspTenants): void {
        this._disposalBag .nextSub = this.companyService.patchMspChildTenantAliasName(tenant.ChildTenantReferenceId, "").subscribe(() => {
            tenant.ChildTenantAliasName = "";
            this.selectChildTenant.emit(tenant);
            let message = this.translationService.getTranslation('msg_TenantAliasName_Delete_Success', {
                TenantName: tenant.ChildTenantNameIdentifier
              })
            appMessenger.postMessage(new ClientNotificationMessage(
                "",
                message,
                NotificationType.Success,
                AlertSeverityEnum.Minor));
            this.attributesPopup.close()
        }, (error) => {
            let message = this.translationService.getTranslation('msg_TenantAliasName_Delete_Failure', {
                TenantName: tenant.ChildTenantNameIdentifier
              })
            appMessenger.postMessage(new ClientNotificationMessage(
                "",
                message,
                NotificationType.Error,
                AlertSeverityEnum.Minor));
        });
    }
}