import { PlatformModule } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CDK_TABLE, CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { icon_sortup, icon_sortdown, SotiIcon, IconsLiteModule } from 'src/app/shared/controls/icons-lite/src/index';
import { SotiColumnResize } from './column-resize/column-resize';
import { SotiReorder } from './reorder/reorder';
import { SotiReorderHeader } from './reorder/reorder-header';
import { SotiSort } from './sort/sort';
import { SotiSortHeader } from './sort/sort-header.ctrl';
import { SotiCell, SotiCellDef, SotiColumnDef, SotiHeaderCell, SotiHeaderCellDef } from './table/cell';
import { SotiHeaderRow, SotiHeaderRowDef, SotiRow, SotiRowDef } from './table/row';
import { SotiTable } from './table/table';

@NgModule({
  imports: [CommonModule, CdkTableModule, ScrollingModule, PlatformModule, IconsLiteModule],
  declarations: [
    SotiTable,
    SotiCell,
    SotiCellDef,
    SotiColumnDef,
    SotiHeaderCell,
    SotiHeaderCellDef,
    SotiHeaderRow,
    SotiHeaderRowDef,
    SotiRow,
    SotiRowDef,
    SotiColumnResize,
    SotiReorder,
    SotiReorderHeader,
    SotiSort,
    SotiSortHeader
  ],
  exports: [
    SotiTable,
    SotiCell,
    SotiCellDef,
    SotiColumnDef,
    SotiHeaderCell,
    SotiHeaderCellDef,
    SotiHeaderRow,
    SotiHeaderRowDef,
    SotiRow,
    SotiRowDef,
    SotiColumnResize,
    SotiReorder,
    SotiReorderHeader,
    SotiSort,
    SotiSortHeader
  ],
  providers: [
    { provide: CDK_TABLE, useClass: SotiTable }
  ]
})
export class TableModule {
  constructor() {
    SotiIcon.registerIcon(icon_sortup);
    SotiIcon.registerIcon(icon_sortdown);
  }
}
