import { ModalDialogService2 } from '../../shared/controls/dialogs/modal-dialog-2/modal-dialog-2.service';

import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

//import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppTranslationService, TranslateLanguageLoader } from '../../services/app-translation.service';
import { SharedVirtualScrollModule } from 'src/app/shared/controls/virtual-scroll';
import { MspManagementRoutingModule } from './msp-management-routing.module';
import { getBaseUrl } from '../../app.module';
import { AppErrorHandler } from '../../app-error.handler';
import { SharedModule } from '../../shared/shared.module';
import { SharedDialogsModule } from '../../shared/controls/dialogs';
import { OverlayModule } from '@angular/cdk/overlay';
import { MspManagementViewComponent } from './msp-management-view';
import { SharedActionBarPanelModule } from '../../shared/controls/action-bar-panel/shared-action-bar-panel.module';
import { BrowserUtilsService } from '../../services/browserUtil/browserUtils.service';
import { TooltipService } from '../../shared/controls/tooltip/tooltip.service';
import { SharedTooltipModule } from '../../shared/controls/tooltip';
import { SharedPaginationModule } from '../../shared/soti-node-modules/pagination/shared-pagination.module';
import { ConfirmationDialogModalModule } from '../../shared/internal-controls/confirmation-dialog/confirmation-dialog.module';
import { ItemsCountControlModule } from '../../shared/controls/items-count/items-count.module';
import { MspTenantListingComponent } from './msp-listing/msp-tenants-listing.component';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { CompanyEndpoint } from 'src/app/services/company-services/company-endpoint.service';
import { CompanyListService } from 'src/app/services/company-services/company-list-service';
import { ApplicationService } from 'src/app/services/application-services/application.service';
import { ApplicationEndpoint } from 'src/app/services/application-services/application-endpoint.service';
import { AnnounceService } from 'src/app/services/announce.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedInlineSearchModule } from 'src/app/shared/controls/inline-search';
import { MspTenantApplicationComponent } from './msp-tenant-apps/msp-tenant-apps.component';
import { NoContentModule } from 'src/app/shared/no-content/no-content.module';
import { DashboardApplicationModule } from '../Application/dashboard-application.module';
import { SharedLoadingDirectiveModule } from '../../shared/directives/loading/shared-loading.module';
import { MspTenantApplicationsViewComponent } from './msp-tenant-application/msp-tenant-applications-view.component';
import { ListGridBtnSwitcherModule } from 'src/app/shared/controls/listgrid-btn-switcher/listgrid-btn-switcher.module';
import { TableModule } from '@im/table';
import { SharedSearchBoxModule } from "../../shared/controls/search-box/shared-search-box.module";
import { MspTenantAliasNameModal } from './Modals/msp-tenant-aliasname-modal/msp-tenant-aliasname-modal.ctrl';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        SharedDialogsModule,
        SharedVirtualScrollModule,
        SharedInlineSearchModule,
        NoContentModule,
        MspManagementRoutingModule,
        ListGridBtnSwitcherModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        OverlayModule,
        ConfirmationDialogModalModule,
        SharedActionBarPanelModule,
        SharedTooltipModule,
        SharedPaginationModule,
        ItemsCountControlModule,
        DashboardApplicationModule,
        SharedLoadingDirectiveModule,
        TableModule,
        SharedSearchBoxModule
    ],
    declarations: [
        MspManagementViewComponent,
        MspTenantListingComponent,
        MspTenantApplicationComponent,
        MspTenantApplicationsViewComponent,
        MspTenantAliasNameModal
    ],
    providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        AppTranslationService,
        ModalDialogService2,
        BrowserUtilsService,
        TooltipService,
        CompanyEndpoint,
        CompanyService,
        CompanyListService,
        ApplicationService,
        ApplicationEndpoint,
        AnnounceService
    ],
    exports: [
        MspTenantApplicationsViewComponent
    ]
})

export class MspManagementModule {

}
