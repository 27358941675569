import {
  Component,
  Input,
  OnChanges,
  HostBinding,
  ElementRef,
  Renderer2,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  SimpleChanges,
  ViewChild,
  AfterViewInit
} from '@angular/core';

import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Icon } from './icon-data';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SotiBaseIcon {
  @Input()
  @HostBinding('class.xsmall')
  public get xsmall() {
    return this._xsmall;
  }
  public set xsmall(value: boolean) {
    this._xsmall = coerceBooleanProperty(value);
  }
  private _xsmall = false;

  @Input()
  @HostBinding('class.small')
  public get small() {
    return this._small;
  }
  public set small(value: boolean) {
    this._small = coerceBooleanProperty(value);
  }
  private _small = false;

  @Input()
  @HostBinding('class.large')
  public get large() {
    return this._large;
  }
  public set large(value: boolean) {
    this._large = coerceBooleanProperty(value);
  }
  private _large = false;

  @Input()
  @HostBinding('class.xlarge')
  public get xlarge() {
    return this._xlarge;
  }
  public set xlarge(value: boolean) {
    this._xlarge = coerceBooleanProperty(value);
  }
  private _xlarge = false;

  @Input()
  @HostBinding('class.round')
  public get round() {
    return this._round;
  }
  public set round(value: boolean) {
    this._round = coerceBooleanProperty(value);
  }
  private _round = false;

  constructor(element: ElementRef, renderer: Renderer2) {
    renderer.addClass(element.nativeElement, 'soti-svg-icon');
  }
}

@Component({
  selector: 'soti-icon',
  template: `
    <svg [attr.aria-label]='iconName' #iconpath class="soti-icon__svg" focusable="false" viewBox="0 -64 1024 960" xmlns="http://www.w3.org/2000/svg">
    </svg>
  `,
  styleUrls: ['icon.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SotiIcon extends SotiBaseIcon implements OnChanges, AfterViewInit {

  private static iconRegistry: Map<string, Icon> = new Map();

  @Input() public iconName = '';

  @Input() public color: string;

  @Input() public secondColor: string;


  @ViewChild('iconpath', { read: ElementRef }) svgElement: ElementRef;


  private _svgData = '';

  private _svgSecondData: string;


  static registerIcon(icon: Icon) {
    this.iconRegistry.set(icon.name, icon);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.iconName) {
      // Get the icon definition from the registry

      const icon = SotiIcon.iconRegistry.get(this.iconName);
      if (icon) {
        this._svgData = icon.svgData;
        this._svgSecondData = icon.secondData;
      } else {
        console.error('Icon name ' + this.iconName + ' was not registered as an icon.');
        this._svgData = null;
        this._svgSecondData = null;
      }

      this._changeIcon()
    }
  }

  ngAfterViewInit(): void {
    this._changeIcon()
  }

  private _changeIcon() {
    if (this.svgElement) {
      /* Remove previous path elements */
      while (this.svgElement.nativeElement && this.svgElement.nativeElement.firstChild) {
        this.svgElement.nativeElement.removeChild(this.svgElement.nativeElement.lastChild);
      }

      if (this._svgData) {
        const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        pathElement.setAttribute('d', this._svgData);
        if (this.color) {
          pathElement.setAttribute('fill', this.color);
        } else {
          pathElement.setAttribute('fill', 'currentColor');
        }
        this.svgElement.nativeElement.appendChild(pathElement);
      }
      if (this._svgSecondData) {
        const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        pathElement.setAttribute('d', this._svgSecondData);
        if (this.secondColor) {
          pathElement.setAttribute('fill', this.secondColor);
        }  else {
          pathElement.setAttribute('fill', 'currentColor');
        }
        this.svgElement.nativeElement.appendChild(pathElement);
      }
    }

  }
}
