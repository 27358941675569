import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SotiIcon, SotiBaseIcon } from './icon';
import { Icon } from './icon-data';

@NgModule({
  imports: [CommonModule],
  declarations: [SotiIcon, SotiBaseIcon],
  exports: [SotiIcon]
})
export class IconsLiteModule {

  /** Allows importing modules to register specific icons they know they will need */
  static registerIcon(icon: Icon) {
    SotiIcon.registerIcon(icon);
  }

 }
