import { Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';

export interface LazyScrollAction {
  offset: number;
  start: number;
  end: number;
}
export class LazyScroller {
  public total: number;
  public rowHeight: number;
  public staticContentHeight: number;
  public lastCluster = 0;
  public scrollTop = 0;

  public itemsStart = 0;
  public itemsEnd = 0;

  protected blockHeight = 0;
  protected rowsInBlock = 5;
  protected rowsInCluster = 0;
  protected clusterHeight = 0;
  protected blocksInCluster = 5;
  /**
   * Amount of rows to take after calculating the start
   */
  protected take: number;

  private _subscription: Subscription | null;
  private _scrollSubscription: Subscription | null;

  constructor(public scrollObserver: Observable<HTMLElement>) {}

  public create(
    // eslint-disable-line
    total: number,
    threshold: number,
    rowHeight: number,
    staticContentHeight: number = 0
  ): Observable<LazyScrollAction> {
    this.total = total;
    this.rowsInBlock = threshold;
    this.rowHeight = rowHeight;
    this.staticContentHeight = staticContentHeight;

    this.setUpSizing();

    const subject = new ReplaySubject<LazyScrollAction>(2)

    this.calculateItems(this.total, this.lastCluster, subject);

    this.unsubscribeAll();
    this._subscription = new Observable<LazyScrollAction>((observer: Subscriber<LazyScrollAction>) => {
      // eslint-disable-line
      this._unsubscribeScroll();
      this._scrollSubscription = this.scrollObserver.subscribe((element) => {
        if (element) {
          return this.onScroll(element, subject);
        }
      });
    }).subscribe((e: any) => {
      return subject.next(e);
    });

    return subject;
  }

  public onScroll(element: HTMLElement, subject: ReplaySubject<LazyScrollAction>): void {
    if (this.lastCluster !== (this.lastCluster = this.getClusterNum(element))) {
      this.calculateItems(this.total, this.lastCluster, subject);
    }
  }

  public topOffset(): number {
    let topOffset = Math.max(this.itemsStart * this.rowHeight, 0);
    if (this.itemsStart > 0) {
      // take away the static content height, but add a row size buffer
      topOffset -= this.staticContentHeight;
      if (this.staticContentHeight > 0) {
        topOffset += this.rowHeight * 3;
      }
    }
    return topOffset;
  }

  public totalHeight(): number {
    return this.total * this.rowHeight;
  }

  public unsubscribeAll(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._subscription = null;
    this._unsubscribeScroll();
  }

  public getClusterNum(element: HTMLElement): number {
    this.scrollTop = element.scrollTop;
    return Math.floor(this.scrollTop / (this.clusterHeight - this.blockHeight + this.staticContentHeight)) || 0;
  }

  /**
   * Sets up sizing for the blockHeight, the rows in a cluster, and a cluster height
   */
  protected setUpSizing(): void {
    this.blockHeight = this.rowHeight * this.rowsInBlock;
    this.rowsInCluster = this.blocksInCluster * this.rowsInBlock;
    this.clusterHeight = this.blocksInCluster * this.blockHeight;
    this.take = this.rowsInCluster + this.rowsInBlock * 2;
  }

  protected calculateItems(total: number, clusterNum: number, subject: ReplaySubject<LazyScrollAction>): void {
    this.itemsStart = Math.max((this.rowsInCluster - this.rowsInBlock) * clusterNum - this.rowsInBlock, 0);
    this.itemsEnd = this.itemsStart + this.take;

    if (total < this.rowsInBlock) {
      subject.next({ offset: 0, start: this.itemsStart, end: total });
    } else {
      if (this.itemsEnd > total) {
        this.itemsEnd = total;
        this.itemsStart = Math.max(this.itemsEnd - this.take, 0);
      }
      const offset = this.topOffset();
      subject.next({ offset, start: this.itemsStart, end: this.itemsEnd });
    }
  }

  private _unsubscribeScroll(): void {
    if (this._scrollSubscription) {
      this._scrollSubscription.unsubscribe();
    }
    this._scrollSubscription = null;
  }
}
