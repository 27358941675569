/**
 * Created by jcammisuli on 2016-05-18.
 */
import {
    Component,
    Host,
    HostBinding,
    Input,
    OnInit,
    Optional,
    ElementRef,
    HostListener
} from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { SubDropdownComponent } from '../sub-dropdown/sub-dropdown.component';

@Component({
    selector: 'soti-dropdown-node',
    templateUrl: './dropdown-node.component.html',
    styleUrls: ['./dropdown-node.component.scss']
})
export class DropdownNodeComponent implements OnInit {

    @HostBinding('class.header')
    @Input()
    public header: boolean = false;

    @HostBinding('class.disabled')
    @Input()
    public disabled: boolean = false;

    @HostBinding('class.current-selected')
    @Input()
    public selected: boolean = false;

    @Input()
    public scrollToTop: boolean = true;

    constructor(
        @Optional() @Host() private _dropdown: DropdownComponent,
        @Optional() private _subdropdown: SubDropdownComponent,
        public element: ElementRef
    ) { }

    public ngOnInit(): void {
    }

    public scrollToSelectedNode(): void {
        if (this.selected) {
            this.element.nativeElement.scrollIntoView(this.scrollToTop);
        }
    }
}