import {NgModule} from '@angular/core';
import {ActionBarPanelCtrl} from './action-bar-panel.ctrl';
import {ActionsListService} from './model/ActionsListService';
import {CoreCommonModule} from '../../core-common.module';
import {SharedActionBarModule} from '../action-bars/shared-action-bar.module';
import { SharedTooltipModule } from '../tooltip';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../../../services/app-translation.service';

@NgModule({
    imports: [
        CoreCommonModule,
        SharedActionBarModule,
        SharedTooltipModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [
        ActionBarPanelCtrl
    ],
    providers: [
        ActionsListService
    ],
    exports: [
        ActionBarPanelCtrl
    ]
})
export class SharedActionBarPanelModule {
}
