import { Permissions } from 'src/app/shared/enums/Permissions';
export class Constants {
    public static readonly metadataDownloadedFileName: string = 'SOTIIdentityMetadata.xml';
    public static readonly certificateDownloadFileName: string = 'SOTIIdentityCertificate.cer';
    public static readonly pemCertificateDownloadFileName: string = 'SOTIIdentityCertificate.pem';

    public static readonly encryptionCertificateDownloadFileName: string = 'EncryptionCertificate.crt';
    public static readonly signingCertificateDownloadFileName: string = 'SigningCertificate.crt';
    public static readonly templateDownloadFileName: string = 'SOTI_Identity_Bulk_Users.csv';
    public static readonly userListDownloadFileName: string = 'User_Data_Download.csv';
    public static readonly downloadErrorReportName : string = 'Bulk_Users_Error_Report_<TimeSpan>.csv';
    public static readonly downloadEventLogName : string = 'Event_Logs_<TimeSpan>.csv';
    public static readonly eventLogStartKey : string = 'Event_Logs_';
    public static readonly eventLogExtensionKey : string = '.csv';

    public static readonly alteastOneLower: string = '(?=.*[a-z])';
    public static readonly alteastOneUpper: string = '(?=.*[A-Z])';
    public static readonly alteastOneDigit: string = '(?=.*?[0-9])';
    public static readonly alteastOneSpecialChar: string = '(?=.*?[#?!@$^&*-+])';
    public static readonly noWhiteSpaceChar: string = '^\\S*$';

    public static readonly mobiControlDefaultDescriptionKey: string = 'DefaultDescriptionAppType_2';
    public static readonly connectDefaultDescriptionKey: string = 'DefaultDescriptionAppType_3';
    public static readonly assistDefaultDescriptionKey: string = 'DefaultDescriptionAppType_8';
    public static readonly termsAndConditionsUrlKey: string = 'TermsAndConditionsUrl';
    public static readonly userManagementPath: string = 'user-management';
    public static readonly connectionManagementPath: string = 'connection-management';
    public static readonly view: string = '/view';
    public static readonly navigationPermissions: Permissions[] = [Permissions.ENABLEUSERSECTIONVIEW,
        Permissions.ENABLEAPPLICATIONSECTIONVIEW, 
        Permissions.ENABLEDIRECTORYSECTIONVIEW,
        Permissions.ENABLEACCOUNTSECTIONVIEW,
        Permissions.CREATECOMPANY, 
        Permissions.GETCOMPANY,
        Permissions.MANAGECOMPANY,
        Permissions.ENABLEPOLICYSECTIONVIEW,
        Permissions.VIEWPOLICY,
        Permissions.VIEWMSPTENANTPORTAL];
    public static readonly strAzureAdConnection: string = 'azure ad';
    public static readonly strExternalIDPConnection: string = 'external idp';
    public static readonly strldapConnection: string = 'ldap';
    public static readonly strGSuiteConnection: string = 'gsuite';
    
    public static readonly domainManagmentRoutingPath: string = '/account-management/view/domains';

    public static readonly eventLogsRoutingPath: string = '/account-management/view/eventlogs';

    public static readonly supportAccountRoutingPath: string = '/account-management/view/supportaccount';
    public static readonly mspRequestRoutingPath: string = '/account-management/view/msprequest';
    public static readonly tenantLinkingRequestsRoutingPath: string = '/account-management/view/tenantlinkingrequests';
    public static readonly backgroundPreferencesRoutingPath: string = '/account-management/view/background_preferences';
    public static readonly accountinfoRoutingPath: string = '/account-management/view/generalsettings/accountInformation';
    public static readonly usersessionManagementRoutingPath: string = '/account-management/view/generalsettings/usersessionmanagement';
    public static readonly newDeviceSignInRoutingPath: string = '/account-management/view/generalsettings/newdevicesignin';
    public static readonly useraccessManagementRoutingPath: string = '/account-management/view/generalsettings/useraccessmanagement';
    public static readonly sotionePortalRoutingPath: string = '/account-management/view/generalsettings/sotioneportal';
    public static readonly userpasswordRoutingPath: string = '/account-management/view/authenticationfactors/userpassword';
    public static readonly emailauthenticationRoutingPath: string = '/account-management/view/authenticationfactors/email';
    public static readonly googleAuthenticationRoutingPath: string = '/account-management/view/authenticationfactors/google';
    public static readonly duoAuthenticationRoutingPath: string = '/account-management/view/authenticationfactors/duo';
    public static readonly certificateAuthenticationRoutingPath: string = '/account-management/view/authenticationfactors/certificate';
    
    
    public static readonly microsoftAuthenticationRoutingPath: string = '/account-management/view/authenticationfactors/microsoft'
    public static readonly policymanagementRoutingPath: string = 'policy-management/view'

    public static readonly configDetailsJsonPropName = {
        AgentName: 'AgentName',
        AgentClientId : 'AgentClientId',
        AgentClientSecret: 'AgentClientSecret',
        Application : 'Application',
        AuthorizationEndpoint: 'AuthorizationEndpoint',
        ConfigurationEndpoint: 'ConfigurationEndpoint',
        Scopes: 'Scope'
    };

    public static readonly Identity: string = "Identity";
    public static readonly Information: string = "Information";
    public static readonly Warning: string = "Warning";
    public static readonly Failure: string = "Failure";
    public static readonly CompanyIDDisplayLengthLimit: number = 24;
    public static readonly DateTimeMinValue = "0001-01-01T00:00:00";
    public static readonly localConnection: string = 'DB';
    public static readonly ldapConnection: string = 'LDAP';
    public static readonly externalIdpConnection: string = 'External IDP';
    public static readonly azureAdConnection: string = 'Azure AD';
    public static readonly gsuiteConnection: string = 'GSuite';

    public static readonly cityLocationGranularity: string = 'lbl_city';
    public static readonly stateOrRegionLocationGranularity: string = 'lbl_state_or_region';
    public static readonly countryLocationGranularity: string = 'lbl_country';
    public static readonly supportAccountHrsMinValue: number = 1;
    public static readonly supportAccountHrsMaxValue: number = 23;
    public static readonly supportAccountDaysMinValue: number = 1;
    public static readonly supportAccountDaysMaxValue: number = 14;
    public static readonly AddGSuiteHelpURL : string = 'AddGsuiteConnection';
    public static readonly EditGSuiteHelpURL : string = 'EditGsuiteConnection';

    public static readonly redirectArrayMaxLength: number = 100;
    public static readonly defaultSignInRedirectUri: string = "http://localhost:8080/authorization-code/callback";
    public static readonly  NotificationKindPublic = "PUBLIC";
    public static readonly NotificationService = {
        AlertsVariable : 'recentNotifications',
        AnnouncementsVariable : 'recentAnnouncements'
    };
    public static readonly SearchKeywordValidLength: number = 3;
    public static readonly EditDirectoryUserHelpURL : string = 'EditDirectoryUser';

    public static readonly UserActions = {
        UserEditAction : 'UserEditAction',
        UserAssignApplicationAction: 'UserAssignApplicationAction',
        UserResetPasswordAction: 'UserResetPasswordAction',
        UserForceResetPasswordAction: 'UserForceResetPasswordAction',
        UserCopyEmailAction: 'UserCopyEmailAction',
        UserUnlockAction: 'UserUnlockAction',
        UserActivateAction: 'UserActivateAction',
        UserDeactivateAction: 'UserDeactivateAction',
        UserDeleteAction: 'UserDeleteAction'
    }

    public static readonly DashboardPath : string = '';

 
    public static readonly DefaultWindowOpenerTargetOrigin = "*";

    public static readonly ImagesPath = 'app/assets/images';
    public static readonly SnapSvgImage = 'soti-snap.svg';
    public static readonly InsightSvgImage = 'soti-insight.svg';
    public static readonly CentralSvgImage = 'soti-central.svg';
    public static readonly MobiSvgImage = 'soti-mc.svg';
    public static readonly ConnectSvgImage = 'soti-connect.svg';
    public static readonly AssistSvgImage = 'soti-assist.svg';
    public static readonly XsightSvgImage = 'soti-xsight.svg';
    public static readonly DefaultAppSvgImage = 'default-apps.svg';
    public static readonly AzureADSvgImage = 'azure-ad-color.svg';
    public static readonly GsuiteConnectionSvgImage = 'gsuite-connection.svg';
    public static readonly DirectoryConnectionSvgImage = 'directoryconnection.svg';
    public static readonly IconBrowseFilesSvgImage = 'icon-browse-files.svg';
    public static readonly LogoSotiOneDarkMode = 'icon-soti-one-hdr-dark-mode.svg';
    public static readonly LogoSotiOneDefaultMode = 'icon-soti-one-header.svg';
    public static readonly LogoSotiOne = 'logo-soti-one.svg';

    public static readonly DefaultAppPngImage = 'default-apps.png';
 
    public static readonly pending: string = 'Pending';
    public static readonly approved: string = 'Approved';
    public static readonly rejected: string = 'Rejected';
    public static readonly expired: string = 'Expired';
    public static readonly received: string = 'Received';
    public static readonly delinked: string = 'Delinked';

    public static readonly userReferenceHeader: string = 'user-reference';

    public static readonly InvalidOtpTime: number = 5000;
    
    public static readonly ManagerEmail: string = 'ManagerEmail';
    public static readonly ManagerObjectID: string = 'ManagerObjectID';
    public static readonly ManagerDN: string = 'ManagerDN';
    public static readonly Manager: string = 'manager';

    public static readonly Ascending: string = 'asc';

    public static readonly PREFERENCE = 'preference'

    public static readonly createUserPermissions = [
        Permissions.CREATEUSER
    ];

    public static readonly userActionPermissions = [
        Permissions.EDITUSER,
        Permissions.ASSIGNAPPLICATIONTOUSERS,
        Permissions.ASSIGNTENANTTOUSER,
        Permissions.RESETPASSWORD,
        Permissions.FORCERESETPASSWORD,
        Permissions.ACTIVATEUSER,
        Permissions.DEACTIVATEUSER,
        Permissions.DELETEUSER
    ];

    public static readonly createGroupPermissions = [
        Permissions.CREATELOCALGROUP,
        Permissions.CREATELDAPUSERGROUP,
        Permissions.CREATEIDPGROUP
    ];

    public static readonly groupActionPermissions = [
        Permissions.EDITGROUP,
        Permissions.ASSIGNAPPLICATIONTOGROUP,
        Permissions.ASSIGNUSERTOGROUP,
        Permissions.ASSIGNTENANTTOGROUP,
        Permissions.DELETEGROUP
    ];

    public static readonly createRolePermissions = [
        Permissions.CREATEIDPROLE
    ];

    public static readonly roleActionPermissions = [
        Permissions.EDITROLE,
        Permissions.ASSIGNROLETOUSER,
        Permissions.MANAGEROLES,
        Permissions.DELETEROLE
    ];
    
    public static readonly designPreferencePermissions = [
        Permissions.MANAGEBACKGROUNDIMAGE,
        Permissions.MANAGELOGO
    ];

    public static readonly MSPParentTenantCustomRoles = [
        'lbl_business_permission_assign_tenants_user',
        'lbl_business_permission_assign_tenants_group'
    ];

    // Icon Name 
    public static readonly IdentityIconActiveRecord: string = 'identity-icon-active-record';

    public static readonly AppRedirectUrl: string ='application-redirect';
  
    public static readonly genralsettings = {
        accountInformation : 'accountinformation',
        usersessionmanagement : 'usersessionmanagement',
        useraccessmanagement : 'useraccessmanagement',
        newDeviceSignIn : 'newDeviceSignIn',
        sotioneportal : 'sotioneportal'

    };
    public static readonly DefaultBackgrounImageId: number = 1;

    public static readonly DefaultPrimaryColor: string = "#0075ab";
    public static readonly DefaultTextColor: string = "#ffffff";
    public static readonly DefaultSessionWarningTime: number = 300;
    public static readonly  ValidationValue: number = 1;
    public static readonly  CompareOldPasswordCount: number = 12;
    public static readonly  PasswordExpiryDays: number = 365;
    public static readonly  LoginAttempts: number = 99;
    public static readonly ClaVersionForDirectorySync = "2025.1.0";
}