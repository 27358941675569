import { Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { TableDataSource } from '@im/table';
import { GenericColumnDef } from 'src/app/models/GenericColumnDef';
import { ViewType } from 'src/app/shared/controls/listgrid-btn-switcher/listgrid-btn-switcher.ctrl';
import { Utilities } from '../../../services/utilities';
import { IntergratedApplicationTypes } from '../../../models/IntergratedApplicationTypes';
import { LocalStoreManager } from '../../../services/local-store-manager.service';
import { DBkeys } from '../../../services/db-keys';
import { MspDisplayApplication } from '../../../models/mspdisplayapplication.model';
import { DatePipe } from '@angular/common';
import { AppTranslationService } from '../../../services/app-translation.service';
import { PopUpTrigger } from 'src/app/shared/controls/dialogs';

@Component({
    selector: 'msp-tenant-applications-view',
    templateUrl: './msp-tenant-applications-view.component.html',
    styleUrls: ['./msp-tenant-applications-view.component.scss'],
})
export class MspTenantApplicationsViewComponent implements OnInit{

    @Input() public applications: MspDisplayApplication[];
    @Input() public viewType: number = ViewType.Tile;
    @Input() public sotiOneDashboard: boolean = false;
    public dataSource: TableDataSource<MspDisplayApplication>;
    public availableColumns: string[] = [
        'ACTION',
        'INSTANCE NAME',
        'VERSION',
        'LICENSE COUNT',
        'EXPIRY'
        
    ];
    @ViewChild('popUpTrigger')
    public threeDotPopup: PopUpTrigger;

    listViewType: number = ViewType.List;
    tileViewType: number = ViewType.Tile;

    public columnConfigs: Map<string, GenericColumnDef> = new Map<string, GenericColumnDef>();

    
    constructor(
        private localStorage: LocalStoreManager,
        private datePipe: DatePipe,
        private translationService: AppTranslationService
    ) {
    }
    ngOnInit(): void {
        this.configureTableColumns();
        this.dataSource = new TableDataSource(this.applications);
        if (this.sotiOneDashboard)
            this.availableColumns.push('ABOUT')
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (changes.applications) {
            this.dataSource = new TableDataSource(this.applications);
        }
    }

    public openApp(application: MspDisplayApplication) {
        let url = window.location.protocol + '//' + window.location.host + '/application-redirect?applicationReferenceId=' + application.ReferenceId;

        while (this.localStorage.getData(DBkeys.READLOCK + "APPLICATION_REDIRECT" + application.ReferenceId));
        this.localStorage.saveSessionData(1, DBkeys.READLOCK + "APPLICATION_REDIRECT" + application.ReferenceId);
        let previousRedirectInfo = this.localStorage.getData(DBkeys.APPLICATION_REDIRECT_INFO + application.ReferenceId)
        if (previousRedirectInfo != null && previousRedirectInfo.InstanceCount != 0) {
            application.InstanceCount = previousRedirectInfo.InstanceCount + 1;
        }
        else {
            application.InstanceCount = 1;
        }
        this.localStorage.saveSessionData(application, DBkeys.APPLICATION_REDIRECT_INFO + application.ReferenceId);
        this.localStorage.deleteData(DBkeys.READLOCK + "APPLICATION_REDIRECT" + application.ReferenceId);
        window.open(url, '_blank');
    }

    public redirectXsight(url: string): void {
        window.open(url, '_blank');
        this.threeDotPopup.close();
    }

    public getColumnWidth(colName: string): { width: string; maxWidth: string } {
        const colWidth = this.columnConfigs.get(colName).Width;
        if (colWidth > 0) {
            const widthInPx = colWidth + 'px';
            return {
                width: widthInPx,
                maxWidth: widthInPx
            };
        } else {
            return null;
        }
    }

    public resizeColumn(colName: string, newColWidth: number): void {
        let column = this.columnConfigs.get(colName);
        column.Width = newColWidth;
        column.MinWidth = newColWidth;
    }

    public getAppLogo(thumbnailURL: string, applicationType: number): string {
        if(thumbnailURL && thumbnailURL.length > 0) {
            return thumbnailURL;
        } else if(applicationType == IntergratedApplicationTypes.MobiControl) {
            return Utilities.getMobicontrolSvgImage();
        } else if(applicationType == IntergratedApplicationTypes.SotiConnect) {
            return Utilities.getConnectSvgImage();
        }
        else if(applicationType == IntergratedApplicationTypes.SotiXsight)  {
            return Utilities.getXsightSvgImage();
        }
         else {
            return Utilities.getDefaultAppSvgImage();
        }
    }

    public renderDate(date: string|Date|null|undefined): string | null {
        if (date) {
            return this.datePipe.transform(date, 'yyyy-MM-dd');
        } else {
            return this.translationService.getTranslation('lbl_NA');
        }
    }

    private configureTableColumns(): void {
        this.columnConfigs.set('ACTION', this.getColumnData('ACTION',10));
        if (this.sotiOneDashboard)
        {
            this.columnConfigs.set('INSTANCE NAME', this.getColumnData('INSTANCE NAME', 250));
            this.columnConfigs.set('VERSION', this.getColumnData('VERSION', 110));
            this.columnConfigs.set('LICENSE COUNT', this.getColumnData('LICENSE COUNT', 110));
            this.columnConfigs.set('EXPIRY', this.getColumnData('EXPIRY', 110));
        }
        else
        {
            this.columnConfigs.set('INSTANCE NAME', this.getColumnData('INSTANCE NAME'));
            this.columnConfigs.set('VERSION', this.getColumnData('VERSION'));
            this.columnConfigs.set('LICENSE COUNT', this.getColumnData('LICENSE COUNT'));
            this.columnConfigs.set('EXPIRY', this.getColumnData('EXPIRY'));
        }
        if (this.sotiOneDashboard)
            this.columnConfigs.set('ABOUT', this.getColumnData('ABOUT', 250));

    }

    private getColumnData(colName: string, width?: number, minWidth?: number): GenericColumnDef {

        if (colName === undefined || colName === null) {
            return null;
        }

        let column: GenericColumnDef = new GenericColumnDef({
            Name: colName
        });

        if (width) {
            column.Width = width;
        }

        if (minWidth) {
            column.MinWidth = minWidth;
        }

        return column;
    }
}