import { Component, Input, OnInit } from '@angular/core';
import { InactivityService } from '../../services/user-session-services/inactivity.service';
import { AccountEndpoint } from 'src/app/services/account-services/account-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/constants';
import { BehaviorSubject } from 'rxjs-compat';
import { ModalDialogRef, ModalDialogService2 } from 'src/app/shared/controls/dialogs';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-session-warning',
  templateUrl: './session-warning.component.html',
  styleUrls: ['./session-warning.component.scss']
})
export class SessionWarningComponent implements OnInit {
  @Input()
  public isAppInactive = false;
  private modalRef: ModalDialogRef<null>;
  private intervalId: any;
  showWarning: boolean = false;
  timeLeftInSeconds: number = Constants.DefaultSessionWarningTime;
  displayTime: string = '';
  fullname: string = '';
  alertmessage: string = '';
  disablebutton: boolean = false;
  stoptimer: boolean = false;
  private alertMessageSubject = new BehaviorSubject<string>(''); 

  constructor(
    private inactivityService: InactivityService,
    private accountEndpointService: AccountEndpoint,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public _modalDialogService: ModalDialogService2
  ) { }

  ngOnInit(): void {
    this.inactivityService.inactivityAlert.subscribe((x) => {
      this.updateDisplayTime();
      this.showWarning = true;
      this.fullname = this.authService.currentUser.FirstName;
      if (this.authService.currentUser.LastName !== undefined) {
        this.fullname = this.fullname + ' ' + this.authService.currentUser.LastName;
      }
      this.alertmessage = this.translationService.getTranslation('msg_session_timeout_alert').replace('<fullname>', this.fullname);
      this.startCountdown();  
    });
    this.inactivityService.inactivityAlert.subscribe(isInactive => {
      this.isAppInactive = true;
    });
  }


  private startCountdown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);  
    }
    this.intervalId = setInterval(() => {
      if (this.timeLeftInSeconds > 0) {
        this.timeLeftInSeconds--;
        this.updateDisplayTime();
      } else {
        clearInterval(this.intervalId);  
        this.logout();
      }
    }, 1000);
  }

  private updateDisplayTime() {
    const minutes = Math.floor(this.timeLeftInSeconds / 60);
    const seconds = this.timeLeftInSeconds % 60;
    if (minutes > 0) {
      this.displayTime = `${minutes} minutes ${seconds < 10 ? '0' : ''}${seconds} seconds`;
    } else {
      this.displayTime = `${seconds < 10 ? '0' : ''}${seconds} seconds`;
    }
  }

  extendSession() {
    this.stoptimer = true;
    this.accountEndpointService.ValidateUserSession().subscribe(x => {
      if (x === true) {
        this._modalDialogService.closeModal();
        this.showWarning = false;
        this.displayTime = '';
        this.timeLeftInSeconds = Constants.DefaultSessionWarningTime;
        this.inactivityService.enableResetTimer(); 
        this.inactivityService.resetInactivityTimer();
        if (this.intervalId) {
          clearInterval(this.intervalId);  
        }
        this.isAppInactive = false;
      }
    });
  }

  logout() {
    this.stoptimer = true;
    this.disablebutton = true;
    this.authService.redirectToLogoutUrl();
  }
}
