import { unset } from "lodash";
import { ThemeType } from "src/app/shared/enums/ThemeType";

export interface ITheme {
  name: string;
  properties: any;
  themeType : ThemeType;
}

export class LightTheme implements ITheme {
  name = 'light';
  themeType = ThemeType.LIGHT;
  properties = {
    '--notification-icon-color': '#4B4B4B',
    '--header-icon-color': '#4B4B4B',
    '--header-icon-hvr-bg': '#e9e9e9',
    '--background-default': '#ffffff',
    '--so-header-bg-default': '#F7F7F7',
    '--notification-container-bg-default': '#F7F7F7',
    '--notification-content-box-bg': '#ffffff',
    '--notification-content-title': '#292929',
    '--dashboard-tab-bg': '#ffffff',
    '--soti-one-header-txt': '#4b4b4b',
    '--soti-one-tab-txt-inactive': '#4b4b4b',
    '--burger-menu-icon': '#4b4b4b',
    '--soti-one-mc-popup-bg': '#ffffff',
    '--soti-one-hvr-bg': '#F4F4F9',
    '--soti-one-hvr-selected-bg': '#F4F4F9',
    '--soti-one-sub-txt': '#6B6B6B',
    '--soti-one-tab-txt': '#292929',
    '--soti-one-tab-txt-active': '#292929',
    '--soti-one-tab-txt-active-bdr': '#0075AB',
    '--soti-one-navigation-devider-bdr': '#e9e9e9',
    '--light-dark-btn-txt': '#2F73A5',
    '--light-dark-btn-bg': '#FAFBFC',
    '--light-dark-btn-bdr': '#2F73A5',
    '--tab-btn-inactive-bdr': '#c7c7c7',
    '--tablist-btn-inactive-txt': '#4b4b4b',
    '--tablist-btn-active-txt': '#2F73A5',
    '--tablist-btn-hover-bg': '#f7f7f7',
    '--tab-switcher-bottom-bdr': '#e9e9e9',
    '--search-header-bg': '#ffffff',
    '--search-header-bdr': '#c7c7c7',
    '--search-header-txt': '#292929',
    '--search-header-icon': '#6b6b6b',
    '--no-content-title-txt': '#4b4b4b',
    '--no-content-subtitle-txt': '#4b4b4b',
    '--soti-one-app-list-bg': '#ffffff',
    '--soti-one-app-list-bdr': '#c7c7c7',
    '--soti-one-app-list-txt': '#4b4b4b',
    '--tab-btn-active-txt': '#c7c7c7',
    '--list-view-bg': '#ffffff',
    '--list-view-bdr': '#e9e9e9',
    '--list-view-hvr': '#f7f7f7',
    '--list-view-txt': '#4b4b4b',
    '--footer-txt': '#6b6b6b',
    '--footer-txt-hover': '#292929',
    '--soti-one-username-txt': '#4b4b4b',
    '--soti-one-avtar-bg': '#2F73A5',
    '--soti-one-avtar-txt': '#ffffff',
    '--header-section-devider': '#808080',
    '--header-search-close-icon': '#292929',
    '--header-menu-selected_bdr': '#0075AB',
    '--threedot-button-selected': '#808080',
    '--threedot-button-selected-notification':'#e9e9e9',
    '--soti-one-primary-modal-bg': '#0075AB',
    '--soti-one-primary-modal-bg-mob': '#ffffff',
    '--secondary-btn': '#0075AB',
    '--secondary-btn-icon': '#0075AB',
    '--soti-one--modal-content-bg': '#ffffff',
    '--soti-one--modal-content-txt': '#292929',
    '--soti-one--modal-content-txt-focus': '#27292d',
    '--soti-one--modal-content-input-bg': '#ffffff',
    '--soti-one--modal-content-input-bdr': '#e9e9e9',
    '--soti-one--modal-content-bdr': '#eef1f3',
    '--soti-one-primary-btn-bg': '#0075AB',
    '--soti-one-decline-btn-bg': '#C13F2B',     
    '--soti-one-decline-btn-bg-hvr': '#a71e22',
    '--soti-one-primary-btn-bg_hvr': '#00568A',
    '--soti-one-primary-btn-txt-cancel': '#6B6B6B',
    '--soti-one-primary-btn-txt-cancel-hvr': '#4b4b4b',
    '--soti-one-primary-panel-bg': '#F2F2F2',
    '--soti-one-primary-panel-bdr': '#E9E9E9',
    '--profile-header-bg': 'transparent',
    '--profile-header-img-bg': '#6B6B6B',
    '--profile-header-txt': '#ffffff',
    '--txt-name-title': '#292929',
    '--other-detail-txt': '#292929',
    '--notification-list-item-bg':'#ffffff',
    '--notification-list-item-title':'#292929',
    '--notification-list-item-secondary':'#6b6b6b',
    '--notification-list-item-txt':'#292929',
    '--notification-list-item-txt-link':'#0075AB',
    '--notification-list-item-txt-more-less':'#0075AB',
    '--dropdown-popup-bg':'#FAFBFC',
    '--dropdown-popup-bdr':'#E9E9E9',
    '--dropdown-popup-content-txt':'#292929',
    '--tab-switcher-selected':'#2F73A5',
    '--tab-switcher-txt':'#292929',
    '--tab-switcher-bdr':'#c7c7c7',
    '--focus-ring-bdr':'#092F6C',
    '--notification-bell-clr':'#C13F2B',
    '--profile-not-avlaible-txt':'#C7C7C7',
    '--profile-icon-bg':'#eef1f3',
    '--profile-icon-txt':'#000000',
    '--profile-detail-bdr':'#E9E9E9',
    '--input-eye-color':'#092442',
    '--member-profile-shadow':'0px 0px 0px 5px rgba(0,0,0,0.1)',
    '--umbraco-help-link':'#0C5FE4',
    '--umbraco-background-tile':'#F1F2F440',
    '--modal-title-txt':'#092442',
    '--mobile-header-title-txt':'#292929',
    '--mobile-header-title-bg':'#ffffff',
    '--tab-btn-inactive-bdr-disabled':'#c7c7c7',
    '--tab-btn-inactive-txt-disabled':'#A9A9A9',
    '--tab-btn-inactive-bg-disabled':'#F7F7F7',
    '--scrollbar-main-bg':'#ffffff',
    '--scrollbar-main-bg-hvr':'#F9F9F9',
    '--scrollbar-thumb-main-bg':'#aaaaab',
    '--modal-footer-btn-danger': '#4b4b4b',
    '--vertical-tab-switcher-active': '#E1F5FB',
    '--vertical-tab-switcher-txt': '#292929',
    '--vertical-tab-switcher-icon': '#4b4b4b',
    '--vertical-tab-switcher-active-bdr': '#0075AB',    
    '--accordian-header-txt': '#0075AB',       
    '--accordian-arrow-txt': '#292929',      
    '--accordian-arrow-bdr': '#E9E9E9',
    '--primary-btn-disabled':'#A9A9A9',
    '--primary-btn-disabled-txt':'#ffffff',
    '--info-msg-bar-error-bg':'#fef0ed',
    '--info-msg-bar-error-txt':'initial',
    '--info-msg-bar-error-icon':'#C13F2B',
    '--selected-border':'#092F6C',
    '--browsefile':'#0075AB',
    '--selected-container': '$body-txt',
    '--prefrence-header': '#1D2125',
    '--upload-desc' : '$placeholder-txt',
    '--add-new-icon': '$placeholder-txt',
    '--border-input-bdr': '#E9E9E9',
    '--img-border-bg' : '#C7C7C7',
    '--scrollbar-track-color' : '#FFFFFF',
    '--primary':'#0099D3',
    '--text':'#ffffff',
    '--btn-focus-visible':'#0074aa',
    '--btn-primary-focus-visible':'#3F3280',
    '--help-btn-focus-outline':'#092F6C'
  };
}

export class DarkTheme implements ITheme {
  name = 'dark';
  themeType = ThemeType.DARK;
  properties = {
    '--notification-icon-color': '#B6C2CF',
    '--header-icon-color': '#e6eaf0',
    '--header-icon-hvr-bg': '#22262B',
    '--soti-one-header-txt': '#E6EAF0',
    '--soti-one-tab-txt-inactive': '#B6C2CF',
    '--burger-menu-icon': '#E6EAF0',
    '--background-default': '#16191D',
    '--so-header-bg-default': '#1D2125',
    '--notification-container-bg-default': '#22262B',
    '--notification-content-box-bg': '#2C333A',
    '--notification-content-title': '#E6EAF0',
    '--dashboard-tab-bg': 'transparent',
    '--soti-one-mc-popup-bg': '#22262B',
    '--soti-one-hvr-bg': '#4b4b4b',
    '--soti-one-hvr-selected-bg': '#2C333A',
    '--soti-one-sub-txt': '#E6EAF0',
    '--soti-one-tab-txt': '#B6C2CF',
    '--soti-one-tab-txt-active': '#E6EAF0',
    '--soti-one-tab-txt-active-bdr': '#389BFF',
    '--soti-one-navigation-devider-bdr': '#4b4b4b',
    '--light-dark-btn-txt': '#B6C2CF',
    '--light-dark-btn-bg': '#22262B',
    '--light-dark-btn-bdr': '#454E59',
    '--tab-btn-inactive-bdr': '#4b4b4b',
    '--tablist-btn-inactive-txt': '#B6C2CF',
    '--tablist-btn-active-txt': '#ffffff',
    '--tablist-btn-hover-bg': '#1E1E1E',
    '--tab-switcher-bottom-bdr': '#454E59',
    '--search-header-bg': '#22262B',
    '--search-header-bdr': '#454E59',
    '--search-header-txt': '#8695A7',
    '--search-header-icon': '#8695A7',
    '--no-content-title-txt': '#E6EAF0',
    '--no-content-subtitle-txt': '#E6EAF0',
    '--soti-one-app-list-bg': '#22262B',
    '--soti-one-app-list-bdr': '#454E59',
    '--soti-one-app-list-txt': '#E6EAF0',
    '--tab-btn-active-txt': '#ffffff',
    '--list-view-bg': 'rgba(34,38,43,0.4)',
    '--list-view-bdr': '#454E59',
    '--list-view-hvr': 'rgba(34,38,43,1)',
    '--list-view-txt': '#E6EAF0',
    '--footer-txt': '#8695A7',
    '--footer-txt-hover': '#ffffff',
    '--soti-one-username-txt': '#B6C2CF',
    '--soti-one-avtar-bg': '#596573',
    '--soti-one-avtar-txt': '#E6EAF0',
    '--header-section-devider': '#a9a9a9',
    '--header-search-close-icon': '#f7f7f7',
    '--header-menu-selected_bdr': '#389BFF',
    '--threedot-button-selected': '#454E59',
    '--threedot-button-selected-notification':'#454E59',
    '--soti-one-primary-modal-bg': '#1D2125',    
    '--soti-one-primary-modal-bg-mob': '#1D2125',    
    '--secondary-btn': '#0066CC',
    '--secondary-btn-icon': '#ffffff',
    '--soti-one--modal-content-bg': '#1D2125',
    '--soti-one--modal-content-txt': '#E6EAF0',
    '--soti-one--modal-content-txt-focus': '#E6EAF0',
    '--soti-one--modal-content-input-bg': 'transparent',
    '--soti-one--modal-content-input-bdr': '#454E59',
    '--soti-one--modal-content-bdr': '#2C333A',
    '--soti-one-primary-btn-bg': '#0066CC',
    '--soti-one-primary-btn-bg_hvr': '#0C5FE4',    
    '--soti-one-decline-btn-bg': '#C13F2B',   
    '--soti-one-decline-btn-bg-hvr': '#a71e22',
    '--soti-one-primary-btn-txt-cancel': '#9FADBC',
    '--soti-one-primary-btn-txt-cancel-hvr': '#B6C2CF',
    '--soti-one-primary-panel-bg': '#2C333A',
    '--soti-one-primary-panel-bdr': '#2C333A',
    '--profile-header-bg': '#2C333A',
    '--profile-header-img-bg': '#454E59',
    '--profile-header-txt': '#B6C2CF',
    '--txt-name-title': '#B6C2CF',
    '--other-detail-txt': '#B6C2CF',
    '--notification-list-item-bg':'#2C333A',
    '--notification-list-item-title':'#C7D0DB',
    '--notification-list-item-secondary':'#9FADBC',
    '--notification-list-item-txt':'#9FADBC',
    '--notification-list-item-txt-link':'#389BFF',
    '--notification-list-item-txt-more-less':'#57ABFF',
    '--dropdown-popup-bg':'#454E59',
    '--dropdown-popup-bdr':'#454E59',
    '--dropdown-popup-content-txt':'#E6EAF0',
    '--tab-switcher-selected':'#0066CC',
    '--tab-switcher-txt':'#B6C2CF',
    '--tab-switcher-bdr':'#454E59',
    '--focus-ring-bdr':'#2F73A5',
    '--notification-bell-clr':'#389BFF',
    '--profile-not-avlaible-txt':'#B6C2CF',
    '--profile-icon-bg':'#454E59',
    '--profile-icon-txt':'#E6EAF0',
    '--profile-detail-bdr':'#292929',
    '--input-txt':'#8695A7',
    '--input-eye-color':'#E6EAF0',
    '--member-profile-shadow':'none',
    '--umbraco-help-link':'#389BFF',
    '--umbraco-background-tile':'#22262B40',
    '--modal-title-txt':'#E6EAF0',
    '--mobile-header-title-txt':'#E6EAF0',    
    '--mobile-header-title-bg':'#1D2125',
    '--tab-btn-inactive-bdr-disabled':'#454E59',
    '--tab-btn-inactive-txt-disabled':'#596573',
    '--tab-btn-inactive-bg-disabled':'#22262B',
    '--scrollbar-main-bg':'#22262B',
    '--scrollbar-main-bg-hvr':'#22262B',
    '--scrollbar-thumb-main-bg':'#596573',
    '--modal-footer-btn-danger': '#c7c7c7',
    '--vertical-tab-switcher-active': '#2C333A',
    '--vertical-tab-switcher-txt': '#E6EAF0',
    '--vertical-tab-switcher-icon': '#E6EAF0',
    '--vertical-tab-switcher-active-bdr': '#389BFF',
    '--accordian-header-txt': '#E6EAF0',       
    '--accordian-arrow-txt': '#E6EAF0',      
    '--accordian-arrow-bdr': '#2C333A',
    '--primary-btn-disabled':'#2C333A',
    '--primary-btn-disabled-txt':'#596573',
    '--info-msg-bar-error-bg':'#572219',
    '--info-msg-bar-error-txt':'#E6EAF0',
    '--info-msg-bar-error-icon':'#E87A66',
    '--selected-border':'#5797FF',
    '--browsefile':'#5797FF',
    '--selected-container': '#E6EAF0',
    '--prefrence-header': '#5797FF',
    '--upload-desc' : '#9FADBC',
    '--add-new-icon': '#9FADBC',
    '--border-input-bdr': '#2C333A',
    '--img-border-bg' : '#2C333A',
    '--scrollbar-track-color' : '#1D2125',
    '--primary':'#0099D3',
    '--text':'#ffffff',
    '--btn-focus-visible':'#0074aa',
    '--btn-primary-focus-visible':'#8484BE',
    '--help-btn-focus-outline':'#2F73A5'
  };
}
