import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';

import {ActionsListService} from './model/ActionsListService';
import {IContextualActionBarItem} from '../action-bars/contextual-action-bar/model/IContextualActionBarItem';
import {ActionContext} from './model/ActionContext';
import {DisposalBag} from '../../utils/DisposalBag';
import {TargetsDescription} from './model/TargetsDescription';
import {ActionBarOptions} from './model/ActionBarOptions';
import {ContextualActionBar} from '../action-bars/contextual-action-bar/action-bar.ctrl';
import { UserActionsBaseService } from '../../../components/UserManagement/user-action/user-actions-base.service';
import { UserService } from '../../../services/user-services/user.service';
import { fromEvent } from 'rxjs/observable/fromEvent';

@Component({
    selector: 'soti-action-bar-panel',
    templateUrl: './action-bar-panel.ctrl.html',
    styleUrls: ['./action-bar-panel.ctrl.scss']
})
export class ActionBarPanelCtrl implements OnInit {

    @Input()
    public options: ActionBarOptions;

    @Input()
    public onlyActions: boolean = false;

    public actionsList: IContextualActionBarItem<ActionContext>[] = [];
    public targetsDescription: TargetsDescription;
    public descriptionMaxWidth: number = null;

    @ViewChild(ContextualActionBar, { read: ElementRef })
    private _actionBarCtrl: ElementRef;

    @ViewChild('closeButton')
    private _closeButton: ElementRef;
    private _disposalBag: DisposalBag = new DisposalBag();


    constructor(private _actionsListService: ActionsListService,
                private _cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        if (!this._actionsListService) {
            return;
        }

        this._disposalBag.nextSub = this._actionsListService.actionsList.subscribe((actions: IContextualActionBarItem<ActionContext>[]) => {
            this.actionsList = actions;
            this._cdr.markForCheck();

            setTimeout(() => {
                // Calculates the max-width of the Target Description element right after the action items are loaded the first time.
                // This has to be inside a setTimeout to make sure the action bar was properly rendered.
                // After this, the max-width will be only recalculated when window resizes
                this._calculateDescriptionMaxWidth();
            });
        });

        this._disposalBag.nextSub = this._actionsListService.targetsDescriptionData.subscribe((targetsDescription: TargetsDescription) => {
            this.targetsDescription = targetsDescription;
            this._cdr.markForCheck();
        });

        this._actionsListService.init();

        if (!this.onlyActions) {
            // Recalculate the max-width of the Target Description element on window resize
            this._disposalBag.nextSub = fromEvent(window,  'resize').subscribe(() => {
                this._calculateDescriptionMaxWidth();
            });
        }
    }

    public ngOnDestroy(): void {
        this._disposalBag.dispose();
        this._actionsListService.dispose();
    }


    public onActionClicked(actionContext: any): void {
        this._actionsListService.executeAction(actionContext);
    }

    public onTargetDescriptionClick(event: MouseEvent): void {
        this._actionsListService.onTargetsDescriptionClick(event, this.targetsDescription);
    }

    public closeActionBar(): void {
        this._actionsListService.deselect(this.targetsDescription);
    }

    private _calculateDescriptionMaxWidth(): void {
        if (!this._actionBarCtrl || !this._closeButton) {
            return;
        }

        // Set max-width for Target Description element based the space available on the left side of the action bar.
        // This calculation is necessary because both TargetDescription and ActionBar have absolute positions.
        let clientRect = (<HTMLElement>this._actionBarCtrl.nativeElement).getBoundingClientRect();
        let closeButtonRect = (<HTMLElement>this._closeButton.nativeElement).getBoundingClientRect();
        this.descriptionMaxWidth = clientRect.left - closeButtonRect.right;
        this._cdr.markForCheck();
    }
}
