import { IntergratedApplicationTypes } from './../../models/IntergratedApplicationTypes';
import { UmbracoContentType } from 'src/app/shared/enums/UmbracoContentTypeEnum';
import { Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Constants } from 'src/app/constants';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { DarkTheme, LightTheme } from 'src/app/services/soti-theme/theme';
import { InfoMessageBarOptions } from 'src/app/shared/controls/info-message-bar/InfoMessageBarOptions';
import { InfoMessageBarTypeEnum } from 'src/app/shared/controls/info-message-bar/InfoMessageBarTypeEnum';
import { ViewType } from 'src/app/shared/controls/listgrid-btn-switcher/listgrid-btn-switcher.ctrl';
import { ApplicationStatusEnum } from 'src/app/shared/enums/ApplicationStatusEnum';
import { Permissions } from 'src/app/shared/enums/Permissions';
import { ThemeType } from 'src/app/shared/enums/ThemeType';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { Application } from '../../models/application.model';
import { ApplicationService } from '../../services/application-services/application.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user-services/user.service';
import { ModalDialogService2 } from '../../shared/controls/dialogs';
import { Utilities } from 'src/app/services/utilities';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TenantBackgroundImages } from 'src/app/models/tenantBackgroundImages.model';
import { LanguageTypeEnum } from '../../shared/enums/LanguageTypeEnum';
import { UmbracoService } from 'src/app/services/umbraco-services/umbraco.service';
import { UmbracoPromotionalContent } from 'src/app/models/umbracoPromotionalContent';
import { HelpURLKeys } from 'src/app/shared/enums/HelpURLKeys';
import { combineLatest } from 'rxjs-compat/operator/combineLatest';
import { UserPreferenceService } from 'src/app/services/user-preference-service/user-preference-service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TabSwitcher } from 'src/app/shared/controls/tab-switcher';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.view.html',
    styleUrls: ['./dashboard.view.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    public applications: Application[];

    public noImage = Utilities.getDefaultAppPngImage();
    public filteredapplications: Application[];
    public noFrequentapplications: Application[];
    public showapplication: boolean;
    public portalId: number;
    public isUserLoggedIn: boolean = false;
    public infoMobileUrlAvailablity: InfoMessageBarOptions = null;
    public keyWord: string = '';
    public selectedTab: string = '';
    public msgText: string = 'lbl_no_applications_assigned';
    public subMsgText: string = 'lbl_please_contact_admin';
    public currentTab: number = 0;
    public loadingIndicator: boolean;
    public router: any;
    public listViewType: number = ViewType.List;
    @HostBinding('style') public backgroundImage: SafeStyle;
    @HostBinding('class.dark-mode-bg')
    public isDarkMode: boolean = false;
    public viewMode: UserPreferenceModel = {
        TypeId: UserPreferenceType.DASHBOARDVIEW,
        Preference: ViewType.Tile
    }
    public themeMode: UserPreferenceModel = {
        TypeId: UserPreferenceType.THEME,
        Preference: ThemeType.LIGHT
    };

    public isMobile: boolean = false;
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
    this.checkScreenSize();
    }

    public lightThemeType: number = ThemeType.LIGHT;
    public darkThemeType: number = ThemeType.DARK;
    public tenantBackgroundImages: TenantBackgroundImages[];
    public selectedtenantBackgroundImages: TenantBackgroundImages;
    public selectedBackgroundReferenceId: string;
    public selectedBackgroundImgUrl: string;
    public getImagePath: string;
    public isThirdPartyAppEnabled: boolean = false;
    public isdisabled: boolean = false;
    public thirdPartyIntegrateAppHelpUrl: string;
    public umbracoPromotionContent: UmbracoPromotionalContent;
    public tabNameList = [this.translationService.getTranslation('lbl_tab_frequent'), this.translationService.getTranslation('lbl_tab_soti_one_app'), this.translationService.getTranslation('lbl_tab_company_app')]
    public showExtraAppInfo: boolean; 
    @ViewChild('lightThemeButton')
    private lightThemeBtn: ElementRef;
    @ViewChild('darkThemeButton')
    private darkThemeBtn: ElementRef;
    @ViewChild('tabRef') tabSwitcher: TabSwitcher;
    private _hasPortalPrference: boolean = false;
    private _disposalBag: DisposalBag = new DisposalBag();

    constructor(
        private userService: UserService,
        private authService: AuthService,
        public translationService: AppTranslationService,
        private _modalDialogService: ModalDialogService2,
        private appService: ApplicationService,
        private themeService: SotiThemeService,
        private _sanitizer: DomSanitizer,
        private renderer: Renderer2, 
        private el: ElementRef,
        private umbracoService: UmbracoService,
        private userPreferenceService: UserPreferenceService
    ) {
        this.checkScreenSize();
    }

    public ngOnInit(): void {
        this.getImagePath = Utilities.getAssestPath();
        this._disposalBag.nextSub = this.authService._isUserFullyLoggedIn.subscribe((value) => {
            if (value === true) {
                this.isUserLoggedIn = true;
                this.loadingIndicator = true;
                this._disposalBag.nextSub = this.userPreferenceService.hasShowDashboardAppInfo$.subscribe((value) =>{
                    this.showExtraAppInfo = value?.toLowerCase() === "true";
                });
                this.loadData();
                this.loadPreferences();
                let helpId = HelpURLKeys['ThirdPartyIntegrateApp'];
                this.thirdPartyIntegrateAppHelpUrl = `${this.authService.systemParams.HelpFileUrl}${helpId}`;
                this.isThirdPartyAppEnabled = this.authService.currentUser.AddThirdPartyApplications;
                if(!this.isThirdPartyAppEnabled)
                {
                    this.umbracoService.getPromotionalContent(UmbracoContentType.ThirdPartyPromotion.toString()).subscribe((value) => {
                        this.umbracoPromotionContent = value;
                    });
                }
            }
        });
        this.loadingIndicator = true;
        this.showapplication = false;
        this.authService.isHeaderRequired.next(false);
        this.selectedTab = this.translationService.getTranslation('lbl_tab_frequent');
    }

    public ngOnDestroy(): void {
        this._disposalBag.dispose();
        this.userPreferenceService.cleanUp()
        this.themeService.setTheme(new LightTheme());
        this._modalDialogService.closeModal();
    }

    public loadData(): void {
        if (
            this.authService.currentUser !== undefined &&
            this.authService.currentUser !== null
        ) {
            let id = this.authService.currentUser.ReferenceId;
            this._disposalBag.nextSub = this.userService.getApplications(id).subscribe(
                (result) => {
                    if (result) {
                        this.applications = this.appService.SortApplications(result.Applications.filter(app => app.Id !== 1));
                        this.setTabDataNewUser();
                        this.loadingIndicator = false;
                        this.showapplication = true;
                        let mobicontrolApplicationIds: string[] = this.applications.filter(x => x.ApplicationType == IntergratedApplicationTypes.MobiControl).map(x => x.ReferenceId);
                        if(mobicontrolApplicationIds.length > 0 && result.ValidateXsightorAssist)
                            {
                                this._disposalBag.nextSub = this.userService.getLongLoadingApplications(IntergratedApplicationTypes.SotiXsight ,mobicontrolApplicationIds).subscribe(
                                    (response) => {
                                        if(response.length > 0)
                                        {
                                            let allApplications = this.applications;
                                            allApplications = allApplications.concat(response);
                                            this.applications = this.appService.SortApplications(allApplications.filter(app => app.Id !== 1));
                                            this.UpdateSearchKeyword(this.keyWord);
                                        }
                                    });
                            }
                    } else {
                        this.loadingIndicator = false;
                        this.showapplication = true;
                    }
                },
                (error) => {
                    this.loadingIndicator = false;
                }
            );
        }
    }

    checkScreenSize() {
        this.isMobile = window.innerWidth < 768;
    }
  
    public checkDisableStatus(status: number): boolean {
        if (status === ApplicationStatusEnum.INACTIVE) {
            return true;
        }
        return false;
    }


    public getSotiIdentityPermission(): Permissions[] {
        return Constants.navigationPermissions;
    }

    public UpdateSearchKeyword(keyword: string): void {
        this.keyWord = keyword;
        if (this.keyWord == '') {
            this.setTabData();
        } else {
            this.filteredapplications = this.applications.filter((element) => element.Name.toLocaleLowerCase().match(keyword.toLocaleLowerCase()))
        }
    }

    public onidentityUrlClick(): void {
        let width = window.innerWidth;
        if (width <= 767) {
            this.infoMobileUrlAvailablity = new InfoMessageBarOptions(
                InfoMessageBarTypeEnum.Error,
                this.translationService.getTranslation(
                    'msg_info-mobile-url-availablity'
                ),
                'identity-icon-toast-msg-warning'
            );
            setTimeout(() => {
                this.infoMobileUrlAvailablity = null;
            }, 5000);
        } else {
            window.location.replace(
                window.location.origin +
                '/' +
                Constants.userManagementPath +
                Constants.view
            );
        }
    }

    public viewChanged(viewType: number) {
        this.viewMode.Preference = viewType;
        this.savePreferences(this.viewMode, viewType, this.themeMode.Preference);
    }

    public setTheme(themeType: ThemeType, savePreference: boolean = true): void {
        this.themeMode.Preference = themeType;

        if (savePreference) {
            this.savePreferences(this.themeMode, this.viewMode.Preference, themeType);
            this.setThemeBtnFocus();
        }

        if (themeType == ThemeType.LIGHT) {
            this.themeService.setTheme(new LightTheme());
            this.isDarkMode = false;
        } else {
            this.themeService.setTheme(new DarkTheme());
            this.isDarkMode = true;
        }
    }

    private setThemeBtnFocus() {
        setTimeout(() => {
            if (this.themeMode.Preference == ThemeType.LIGHT && this.darkThemeBtn && this.darkThemeBtn.nativeElement) {
                this.darkThemeBtn.nativeElement.focus();
            } else if (this.lightThemeBtn && this.lightThemeBtn.nativeElement) {
                this.lightThemeBtn.nativeElement.focus();
            }
        });
    }

    public tabClicked(tabObj: { tab: string }): void {
        this.selectedTab = tabObj.tab;
        this.setTabData();
    }

    private loadPreferences() {
        this._disposalBag.nextSub = this.userPreferenceService.backgroundImage$.subscribe((value) =>{
            this.selectedBackgroundImgUrl = value;
            this.renderer.setStyle(this.el.nativeElement as HTMLElement, 'background-image', `url('${this.selectedBackgroundImgUrl}')`);
        });

        this._disposalBag.nextSub = this.userPreferenceService.language$.subscribe((value) =>{
            const language = parseInt(value);
            if(language == LanguageTypeEnum.JAPANESE){
                this.translationService.changeLanguage("ja-JP");
            }
            else if(language){
                this.translationService.changeLanguage("en-US");
            }
        });

        this._disposalBag.nextSub = this.userPreferenceService.theme$.subscribe((value) =>{
            const theme = parseInt(value);
            this.themeMode.Preference = theme;
            if (theme == ThemeType.LIGHT) {
                this.themeService.setTheme(new LightTheme());
                this.isDarkMode = false;
            } else {
                this.themeService.setTheme(new DarkTheme());
                this.isDarkMode = true;
            }
        });

        this._disposalBag.nextSub = this.userPreferenceService.dashboardView$.subscribe((value) =>{
            const dashboardView = parseInt(value);
            this.viewMode.Preference = dashboardView;
        });
    }

    private savePreferences(userPreference, viewType, themeType) {
        this.userService.savePreferences([userPreference]).subscribe(() => {
            this.userPreferenceService.updateTheme(themeType.toString());
            this.userPreferenceService.updateDashboardView(viewType.toString());
        });
    }

    private setTabData() {
        if (this.selectedTab === this.translationService.getTranslation('lbl_tab_frequent')) {
            this.filteredapplications = this.applications.filter(x => x.AccessCount > 0).sort((a, b) => b.AccessCount - a.AccessCount).slice(0, 3);
            this.msgText = 'msg_No_Frequent_App';
            this.subMsgText = 'sub_msg_No_Frequent_App';

        }
        else if (this.selectedTab === this.translationService.getTranslation('lbl_tab_soti_one_app')) {
            this.filteredapplications = this.appService.filterSotiApplications(this.applications);
            this.msgText = 'lbl_no_applications_assigned';
            this.subMsgText = 'lbl_please_contact_admin';
        }
        else if (this.selectedTab === this.translationService.getTranslation('lbl_tab_company_app')) {
            this.filteredapplications = this.appService.filter3rdPartyApplications(this.applications);
            this.msgText = 'lbl_no_applications_assigned';
            this.subMsgText = 'lbl_please_contact_admin';
        }
    }
    private setTabDataNewUser() {
        this._disposalBag.nextSub = this.userPreferenceService.portal$.pipe(take(1)).subscribe((portalvalue) =>{
            this._disposalBag.nextSub = this.userPreferenceService.hasPortalPreference$.pipe(take(1)).subscribe((hasportalprefvalue)=>{
                this.currentTab = parseInt(portalvalue);
                this._hasPortalPrference = (hasportalprefvalue === "true");
                if(this._hasPortalPrference)
                {
                    this.selectedTab = this.tabNameList[this.currentTab];
                    this.tabSwitcher.openTargetTab(this.selectedTab);
                    this.setTabData();
                    return;
                }
                this.noFrequentapplications = this.applications.filter(x => x.AccessCount > 0).sort((a, b) => b.AccessCount - a.AccessCount).slice(0, 3);
                if(this.noFrequentapplications.length === 0){            
                    this.selectedTab = this.translationService.getTranslation('lbl_tab_soti_one_app');
                    this.currentTab = 1;
                    this.filteredapplications = this.appService.filterSotiApplications(this.applications);
                } else{
                    this.selectedTab = this.translationService.getTranslation('lbl_tab_frequent');
                    this.currentTab = 0;
                    this.setTabData();
                }
                this._hasPortalPrference = false;
            });
        });

    }
}

