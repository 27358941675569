import { CollectionViewer } from '@angular/cdk/collections';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  CdkCellDef,
  CdkCellOutlet,
  CdkCellOutletRowContext,
  CdkColumnDef,
  CdkHeaderCellDef,
  CdkHeaderRowDef,
  DataSource,
  HeaderRowOutlet,
  DataRowOutlet,
  CdkTable
} from '@angular/cdk/table';
import {
  AfterContentChecked,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  Input,
  IterableDiffer,
  IterableDiffers,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  IterableChangeRecord,
  IterableChanges,
  AfterContentInit
} from '@angular/core';
import { uniqueId } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { map, mergeMap, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { LazyScrollAction, LazyScroller } from './lazy';
import { SotiRowDef } from './row';
import { TableDataSource } from './table-data-source';
import {
  getTableDuplicateColumnNameError,
  getTableMissingMatchingRowDefError,
  getTableMissingRowDefsError,
  getTableMultipleDefaultRowDefsError,
  getTableNoScrollableAncestorRegistered,
  getTableUnknownColumnError,
  getTableUnknownDataSourceError
} from './table-errors';

interface SotiCellOutletRowContext<T> extends CdkCellOutletRowContext<T> {

  /**
   * Id used to identify the instance of the row/cell
   */
  rowId?: string;
}

abstract class RowViewRef<T> extends EmbeddedViewRef<SotiCellOutletRowContext<T>> { }
abstract class CellViewRef<T> extends EmbeddedViewRef<SotiCellOutletRowContext<T>> { }

@Component({
  selector: 'soti-table',
  templateUrl: './table.html',
  styleUrls: ['./table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: CdkTable, useExisting: SotiTable }]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SotiTable<T> implements AfterContentInit, CollectionViewer, OnInit, OnDestroy, AfterContentChecked {
  @Input()
  @HostBinding('class.fixed')
  public fixed = false;
  /**
   * Hide the headers whenever there is no data to show within the table
   */
  @Input()
  public hideHeadersOnEmpty = false;


  /*
  * Expands row with another table
  */
  @Input() public enableExpandableRow: boolean = false;

  @Input() public noRows: TemplateRef<object>;
  @Input() public lazy = false;
  @Input() public lazyScrollClearUI = false;
  /**
   * Row heights used by the lazy scroller to determine calculations
   */
  @Input() public rowHeight: number;
  /**
   * Save column width when column is resized, so that it does not fallback to default
   */
  @Input() public saveColumnWidth = false;

  public skip = 0;
  /**
   * Used by the lazy scroller to determine the amount of rows for each calculating block
   */
  public threshold = 8;
  public offset = 0;
  private _hasInitialized: boolean = false;
  public get offsetTransform(): string | undefined {
    if (this.lazy) {
      return `translate3d(0, ${this.offset}px, 0)`;
    }
    return undefined;
  }
  @Output() public rowsChanged: EventEmitter<void> = new EventEmitter<void>();

  public lazyScrollService: LazyScroller;

  /**
   * Stream containing the latest information on what rows are being displayed on screen.
   * Can be used by the data source to as a heuristic of what data should be provided.
   */
  public viewChange: BehaviorSubject<{ start: number; end: number }> = new BehaviorSubject<{
    start: number;
    end: number;
  }>({
    start: 0,
    end: Number.MAX_VALUE
  });

  /**
   * Get the data source as the TableDataSource type
   * 
   * @returns {TableDataSource<any>} returns table source
   */
  public get tableSource(): TableDataSource<T> {
    return this.dataSource as TableDataSource<T>;
  }

  public get element(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  public get headerRowElement(): HTMLElement {
    return this.element.getElementsByTagName('soti-header-row')[0] as HTMLElement;
  }

  public get hasMultipleRowDefinitions(): boolean {
    return this._rowDefs && this._rowDefs.length > 1;
  }

  public headerWidths: Map<string, number> = new Map<string, number>();

  /**
   * Template definition used as the header container. By default it stores the header row
   * definition found as a direct content child. Override this value through `setHeaderRowDef` if
   * the header row definition should be changed or was not defined as a part of the table's
   * content.
   */
  @ContentChild(CdkHeaderRowDef, { static: true }) public headerRowDef: CdkHeaderRowDef;
  // Placeholders within the table's template where the header and data rows will be inserted.
  @ViewChild(DataRowOutlet, { static: true }) public rowPlaceholder: DataRowOutlet;

  @ViewChild(HeaderRowOutlet, { static: true }) public headerRowPlaceholder: HeaderRowOutlet;

  /**
   * The column definitions provided by the user that contain what the header and cells should
   * render for each column.
   */
  @ContentChildren(CdkColumnDef) private _contentColumnDefs: QueryList<CdkColumnDef>;

  /** Set of template definitions that used as the data row containers. */
  @ContentChildren(SotiRowDef) private _contentRowDefs: QueryList<SotiRowDef>;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy: Subject<void> = new Subject<void>();

  /** Latest data provided by the data source. */
  private _data: T[] | ReadonlyArray<T>;

  /** Subscription that listens for the data provided by the data source.    */
  private _renderChangeSubscription: Subscription | null;

  /**
   * Map of all the user's defined columns (header and data cell template) identified by name.
   * Collection populated by the column definitions gathered by `ContentChildren` as well as any
   * custom column definitions added to `_customColumnDefs`.
   */
  private _columnDefsByName: Map<string, CdkColumnDef> = new Map<string, CdkColumnDef>();

  /**
   * Set of all row defitions that can be used by this table. Populated by the rows gathered by
   * using `ContentChildren` as well as any custom row definitions added to `_customRowDefs`.
   */
  private _rowDefs: SotiRowDef<object>[];

  /** Differ used to find the changes in the data provided by the data source. */
  private _dataDiffer: IterableDiffer<T>;

  /** Stores the row definition that does not have a when predicate. */
  private _defaultRowDef: SotiRowDef | null;

  /** Column definitions that were defined outside of the direct content children of the table. */
  private _customColumnDefs: Set<CdkColumnDef> = new Set<CdkColumnDef>();

  /** Row definitions that were defined outside of the direct content children of the table. */
  private _customRowDefs: Set<SotiRowDef> = new Set<SotiRowDef>();

  /**
   * Whether the header row definition has been changed. Triggers an update to the header row after
   * content is checked.
   */
  private _headerRowDefChanged = false;

  /**
   * Map of the cell view containers for each row
   * Used to update the $implicit context for the cells
   */
  private _cellOutletViewContainers: Map<number, ViewContainerRef> = new Map<number, ViewContainerRef>();

  /** Whether the table has rendered out all the outlets for the first time. */
  _hasAllOutlets = false; // we do not support outlets at this point!

  /**
   * Tracking function that will be used to check the differences in data changes. Used similarly
   * to `ngFor` `trackBy` function. Optimize row operations by identifying a row based on its data
   * relative to the function to know if a row should be added/removed/moved.
   * Accepts a function that takes two parameters, `index` and `item`.
   * 
   * @returns { TrackByFunction<any>} function to call to check if two rows are the same
   */
  @Input()
  public get trackBy(): TrackByFunction<T> {
    return this._trackByFn;
  }
  public set trackBy(fn: TrackByFunction<T>) {
    this._trackByFn = fn;
  }
  private _trackByFn: TrackByFunction<T>;

  /**
   * The table's source of data, which can be provided in three ways (in order of complexity):
   *   - Simple data array (each object represents one table row)
   *   - Stream that emits a data array each time the array changes
   *   - `DataSource` object that implements the connect/disconnect interface.
   *
   * If a data array is provided, the table must be notified when the array's objects are
   * added, removed, or moved. This can be done by calling the `renderRows()` function which will
   * render the diff since the last table render. If the data array reference is changed, the table
   * will automatically trigger an update to the rows.
   *
   * When providing an Observable stream, the table will trigger an update automatically when the
   * stream emits a new array of data.
   *
   * Finally, when providing a `DataSource` object, the table will use the Observable stream
   * provided by the connect function and trigger updates when that stream emits new data array
   * values. During the table's ngOnDestroy or when the data source is removed from the table, the
   * table will call the DataSource's `disconnect` function (may be useful for cleaning up any
   * subscriptions registered during the connect process).
   * 
   * @returns {[]} data source
   */
  @Input()
  public get dataSource(): DataSource<T> | Observable<T[]> | T[] {
    return this._dataSource;
  }
  public set dataSource(dataSource: DataSource<T> | Observable<T[]> | T[]) {
    if (this._dataSource !== dataSource) {
      this._switchDataSource(dataSource);
    }
  }
  private _dataSource: DataSource<T> | Observable<T[]> | T[];

  constructor(
    @Optional() public scrollContainer: ScrollDispatcher,
    private readonly _differs: IterableDiffers,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    @Attribute('role') public role: string,
    private _zone: NgZone
  ) {
  }

  ngAfterContentInit(): void {
    // some more initialization.
    if (!this.role) {
      this._elementRef.nativeElement.setAttribute('role', 'grid');
    }
    this._hasInitialized = true;
  }

  public ngOnInit(): void {
    this._dataDiffer = this._differs.find([]).create(this._trackByFn);

    // If the table has a header row definition defined as part of its content, flag this as a
    // header row def change so that the content check will render the header row.
    if (this.headerRowDef) {
      this._headerRowDefChanged = true;
    }

    // only set up lazy scroller if the lazy property is set
    if (this.lazy) {
      if (this.scrollContainer.getAncestorScrollContainers(this._elementRef).length === 0) {
        throw getTableNoScrollableAncestorRegistered();
      }

      this.lazyScrollService = new LazyScroller(
        this.scrollContainer.ancestorScrolled(this._elementRef, 0).pipe(
          map((evt) => {
            if (evt) {
              return evt.getElementRef().nativeElement;
            }
            return undefined;
          })
        )
      );
    }
  }

  public ngAfterContentChecked(): void {
    // Cache the row and column definitions gathered by ContentChildren and programmatic injection.
    this._cacheRowDefs();
    this._cacheColumnDefs();

    // Make sure that the user has at least added a header row or row def.
    if (!this.headerRowDef && !this._rowDefs.length) {
      throw getTableMissingRowDefsError();
    }

    // Render updates if the list of columns have been changed for the header or row definitions.
    this._renderUpdatedColumns();

    // If the header row definition has been changed, trigger a render to the header row.
    if (this._headerRowDefChanged) {
      this._renderHeaderRow();
      this._headerRowDefChanged = false;
    }

    // If there is a data source and row definitions, connect to the data source unless a
    // connection has already been made.
    if (this.dataSource && this._rowDefs.length > 0 && !this._renderChangeSubscription) {
      this._observeRenderChanges();
    }
  }

  public ngOnDestroy(): void {
    this.rowPlaceholder?.viewContainer.clear();
    this.headerRowPlaceholder?.viewContainer.clear();
    this._onDestroy.next();
    this._onDestroy.complete();

    if (this.dataSource instanceof TableDataSource) {
      this.dataSource.disconnect(this);
    }
  }

  /**
   * Set widths for columns, optionally clearing all previous stored widths.
   * Re-renders the table if anything has changed.
   *
   * @param {Map<string, number>} newWidths the widths to set
   * @param {boolean} clear if true clear all previous remembered widths
   */
  public setColumnWidths(newWidths: Map<string, number>, clear: boolean = false): void {


    if (clear && !this._prepareHeaderWidth(newWidths)) {
      // width is the same do nothing
      return;
    }
    let changeMade = false;
    newWidths.forEach((v, k) => {
      if (this.headerWidths.get(k) !== v) {
        this.headerWidths.set(k, v); // reset headers and continue
        changeMade = true;
      }
    });

    if (changeMade) {
      /** Widths have changed so redraw the table */
      this._renderHeaderRow();
      this.renderRows(true);
    }

  }

  /**
   * Detects if number of columns or new size is different
   * 
   * @param { Map<string, number>} newWidths new colum definition
   * @returns {boolean} true if headerWidths is cleared, otherwise false
   */
  private _prepareHeaderWidth(newWidths: Map<string, number>): boolean {
    let isWidthOrSizeDifferent = this.headerWidths.size !== newWidths.size; // size is the same
    if (!isWidthOrSizeDifferent) {
      for (const k in newWidths.keys) {
        if (newWidths.get(k) !== this.headerWidths.get(k)) {
          isWidthOrSizeDifferent = false; // width is different at least for one column
          break;
        }
      }
    }
    if (isWidthOrSizeDifferent) {
      this.headerWidths.clear();
    }
    return isWidthOrSizeDifferent;
  }

  /**
   * Renders rows based on the table's latest set of data, which was either provided directly as an
   * input or retrieved through an Observable stream (directly or from a DataSource).
   * Checks for differences in the data since the last diff to perform only the necessary
   * changes (add/remove/move rows).
   *
   * If the table's data source is a DataSource or Observable, this will be invoked automatically
   * each time the provided Observable stream emits a new data array. Otherwise if your data is
   * an array, this function will need to be called to render any changes.
   *
   * forceRerender: if true then forces the table to be redrawn from scratch, without trying to use any
   * previously existing cells. Set this if you have changed the widths of columns programmatically.
   * 
   * @param {boolean} forceRerender false if no need to force
   */
  public renderRows(forceRerender: boolean = false): void {
    const viewContainer = this.rowPlaceholder.viewContainer;

    if (forceRerender) {
      viewContainer.clear();
    }

    if (forceRerender) {
      this._dataDiffer.diff([]);
    }

    const viewContainerLength = viewContainer.length;
    const tuples: { [key: number]: T } = {};

    if (this.lazy && this._data.length === 0 && viewContainerLength !== 0) {
      viewContainer.remove(0);
      this._updateRowContext(tuples);
      if (this.lazyScrollClearUI) {
        viewContainer.clear();
      }
      return;
    }

    const changes = this._dataDiffer.diff(this._data);
    if (!changes) {
      return;
    }

    if (this.lazy) {
      this._renderLazyRows(changes, tuples)
    } else {
      this._renderEagerRows(changes);
    }

    // Update the meta context of a row's context data (index, count, first, last, ...)
    this._updateRowContext(tuples);
  }

  /**
   * Sets the header row definition to be used. Overrides the header row definition gathered by
   * using `ContentChild`, if one exists. Sets a flag that will re-render the header row after the
   * table's content is checked.
   * 
   * @param {CdkHeaderRowDef} headerRowDef - header row definition
   */
  public setHeaderRowDef(headerRowDef: CdkHeaderRowDef): void {
    this.headerRowDef = headerRowDef;
    this._headerRowDefChanged = true;
  }

  /** 
   * Adds a column definition that was not included as part of the direct content children. 
   * 
   * @param {CdkColumnDef} columnDef column definition
   */
  public addColumnDef(columnDef: CdkColumnDef): void {
    this._customColumnDefs.add(columnDef);
  }

  /** 
   * Removes a column definition that was not included as part of the direct content children. 
   * 
   * @param {CdkColumnDef} columnDef column definition
   */
  public removeColumnDef(columnDef: CdkColumnDef): void {
    this._customColumnDefs.delete(columnDef);
  }

  /** 
   * Adds a row definition that was not included as part of the direct content children. 
   * 
   * @param {SotiRowDef} rowDef row definition
   */
  public addRowDef(rowDef: SotiRowDef): void {
    this._customRowDefs.add(rowDef);
  }

  /** 
   * Removes a row definition that was not included as part of the direct content children. 
   * 
   * @param {SotiRowDef} rowDef row definition
   */
  public removeRowDef(rowDef: SotiRowDef): void {
    this._customRowDefs.delete(rowDef);
  }

  public showNoRows(show: boolean): void {
    const noRows: HTMLElement = this.element.querySelector('.no-rows') as HTMLElement;
    if (show) {
      if (this.hideHeadersOnEmpty && this.headerRowElement) {
        this.headerRowElement.style.display = 'none';
      }
      noRows.style.display = 'flex';
      noRows.role = 'row';
    } else {
      if (this.headerRowElement) {
        this.headerRowElement.style.display = '';
      }
      noRows.style.display = 'none';
    }
  }

  // Separate function to reduce complexity of renderRows
  private _renderLazyRows(changes: IterableChanges<T>, tuples: { [key: number]: T }) {
    const viewContainer = this.rowPlaceholder.viewContainer;

    changes.forEachOperation((record: IterableChangeRecord<T>, prevIndex: number, currentIndex: number) => {
      if (currentIndex !== null) {
        tuples[currentIndex] = record.item;
      }
    });

    // create template instances
    for (let i = viewContainer.length; i < this._data.length; i++) {
      this._insertRow(tuples[i], i);
    }

    // remove template instances
    for (let i = viewContainer.length - 1; i >= this._data.length; i--) {
      viewContainer.remove(i);
    }
  }

  // Separate function to reduce complexity of renderRows
  private _renderEagerRows(changes: IterableChanges<T>) {
    const viewContainer = this.rowPlaceholder.viewContainer;
    changes.forEachOperation((record: IterableChangeRecord<T>, prevIndex: number, currentIndex: number) => {
      if (record.previousIndex == null) {
        if (this.enableExpandableRow) {
          this._insertRows(record.item, this.hasMultipleRowDefinitions ? (2 * currentIndex) : currentIndex);
        } else {
          this._insertRow(record.item, currentIndex);
        }

      } else if (currentIndex == null) {
        if (viewContainer.length > 0) {
          viewContainer.remove(prevIndex);
        }
      } else {
        const view = <RowViewRef<T>>viewContainer.get(prevIndex);
        viewContainer.move(view, currentIndex);
      }
    });
    // Update rows that did not get added/removed/moved but may have had their identity changed,
    // e.g. if trackBy matched data on some property but the actual data reference changed.
    changes.forEachIdentityChange((record: IterableChangeRecord<T>) => {
      const rowView = <RowViewRef<T>>viewContainer.get(record.currentIndex);
      rowView.context.$implicit = record.item;
    });
  }

  /** Update the map containing the content's column definitions. */
  private _cacheColumnDefs(): void {
    this._columnDefsByName.clear();

    const columnDefs = this._contentColumnDefs ? this._contentColumnDefs.toArray() : [];
    this._customColumnDefs.forEach((columnDef) => columnDefs.push(columnDef));

    columnDefs.forEach((columnDef) => {
      if (this._columnDefsByName.has(columnDef.name)) {
        throw getTableDuplicateColumnNameError(columnDef.name);
      }
      this._columnDefsByName.set(columnDef.name, columnDef);
    });
  }

  /** Update the list of all available row definitions that can be used. */
  private _cacheRowDefs(): void {
    this._rowDefs = this._contentRowDefs ? this._contentRowDefs.toArray() : [];
    this._customRowDefs.forEach((rowDef) => this._rowDefs.push(rowDef));

    const defaultRowDefs = this._rowDefs.filter((def) => !def.when);
    if (defaultRowDefs.length > 1) {
      throw getTableMultipleDefaultRowDefsError();
    }
    this._defaultRowDef = defaultRowDefs[0];
  }

  /**
   * Check if the header or rows have changed what columns they want to display. If there is a diff,
   * then re-render that section.
   */
  private _renderUpdatedColumns(): void {
    // Re-render the rows when the row definition columns change.
    this._rowDefs.forEach((def) => {
      const columnDiff = def.getColumnsDiff();
      if (columnDiff) {
        // Reset the data to an empty array so that renderRowChanges will re-render all new rows.
        this._dataDiffer.diff([]);

        this.rowPlaceholder.viewContainer.clear();
        this.renderRows();

        // Removes the column width from the cache if column was removed from definition.
        columnDiff.forEachRemovedItem((record) => {
          this.headerWidths.delete(record.item);
        });
      }
    });

    // Re-render the header row if there is a difference in its columns.
    if (this.headerRowDef && this.headerRowDef.getColumnsDiff()) {
      this._renderHeaderRow();
    }
  }

  /**
   * Switch to the provided data source by resetting the data and unsubscribing from the current
   * render change subscription if one exists. If the data source is null, interpret this by
   * clearing the row placeholder. Otherwise start listening for new data.
   * 
   * @param {[]} dataSource - new data source
   */
  private _switchDataSource(dataSource: DataSource<T> | Observable<T[]> | T[]): void {
    this._data = [];

    if (this.dataSource instanceof TableDataSource) {
      this.dataSource.disconnect(this);
    }

    // Stop listening for data from the previous data source.
    if (this._renderChangeSubscription) {
      this._renderChangeSubscription.unsubscribe();
      this._renderChangeSubscription = null;
    }

    if (!dataSource) {
      if (this._dataDiffer) {
        this._dataDiffer.diff([]);
      }

      this.rowPlaceholder.viewContainer.clear();
    }

    this._dataSource = dataSource;
  }

  /** Set up a subscription for the data provided by the data source. */
  private _observeRenderChanges(): void {
    // If no data source has been set, there is nothing to observe for changes.
    if (!this.dataSource) {
      return;
    }

    let dataStream: Observable<T[] | ReadonlyArray<T>>;

    // Check if the datasource is a DataSource object by observing if it has a connect function.
    // Cannot check this.dataSource['connect'] due to potential property renaming, nor can it
    // checked as an instanceof DataSource<T> since the table should allow for data sources
    // that did not explicitly extend DataSource<T>.
    if ((this.dataSource as DataSource<T>).connect instanceof Function) {
      dataStream = (this.dataSource as DataSource<T>).connect(this);
    } else if (this.dataSource instanceof Observable) {
      dataStream = this.dataSource;
    } else if (Array.isArray(this.dataSource)) {
      dataStream = of(this.dataSource);
    }

    if (dataStream === undefined) {
      throw getTableUnknownDataSourceError();
    }

    (this.dataSource as TableDataSource<T>).dataObservable
      ?.pipe(
        startWith(null as any),
        pairwise(),
        mergeMap(([prev, changes]) => {
          this.showNoRows((changes.length === 0));

          // only emit rowsChanged if the rows actually changed, and on first change.
          if (prev === null || prev !== changes) {
            this.rowsChanged.emit();
          }

          return of(changes);
        }),
        mergeMap((data) => {
          if (this.lazy) {
            return this._lazyScroll(data);
          } else {
            return of(<LazyScrollAction>{
              offset: 0,
              start: 0,
              end: Number.MAX_VALUE
            });
          }
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe((scrollAction) => {
        this.offset = scrollAction.offset;
        if (this.lazy) {
          this.viewChange.next({
            start: scrollAction.start,
            end: scrollAction.end
          });
        }
      });

    this._renderChangeSubscription = dataStream.pipe(takeUntil(this._onDestroy)).subscribe((data) => {
      this._data = data;
      if (this.lazy) {
        this._dataDiffer.diff([]);
      }
      this._zone.run(() => {
        this.renderRows();
      });
    });
  }

  /**
   * Clears any existing content in the header row placeholder and creates a new embedded view
   * in the placeholder using the header row definition.
   */
  private _renderHeaderRow(): void {
    // Clear the header row placeholder if any content exists.
    if (this.headerRowPlaceholder.viewContainer.length > 0) {
      this.headerRowPlaceholder.viewContainer.clear();
    }

    const cells = this._getHeaderCellTemplatesForRow(this.headerRowDef);
    if (!cells.length) {
      return;
    }

    this.headerRowPlaceholder.viewContainer.createEmbeddedView(this.headerRowDef.template, { cells });

    cells.forEach((cell) => {
      if (CdkCellOutlet.mostRecentCellOutlet) {
        CdkCellOutlet.mostRecentCellOutlet._viewContainer.createEmbeddedView(cell.template, {});
      }
    });

    this._changeDetectorRef.markForCheck();
  }

  /**
   * Finds the matching row definition that should be used for this row data. If there is only
   * one row definition, it is returned. Otherwise, find the row definition that has a when
   * predicate that returns true with the data. If none return true, return the default row
   * definition.
   * 
   * @param {any} data - row record
   * @param {number} i - position
   * @returns {SotiRowDef} row def
   */
  private _getRowDef(data: T, i: number): SotiRowDef {
    if (this._rowDefs.length === 1) {
      return this._rowDefs[0];
    }

    const rowDef = this._rowDefs.find((def) => def.when && def.when(i, data as object)) || this._defaultRowDef;
    if (!rowDef) {
      throw getTableMissingMatchingRowDefError();
    } else {
      return rowDef;
    }
  }

  /**
   * Create the embedded view for the data row template and place it in the correct index location
   * within the data row view container.
   * 
   * @param {any} rowData - data
   * @param {number} index record no
   */
  private _insertRow(rowData: T, index: number): void {
    const row = this._getRowDef(rowData, index);

    const rowId = uniqueId();

    this.rowPlaceholder.viewContainer.createEmbeddedView(row.template, { rowId, $implicit: rowData }, index);

    this._getCellTemplatesForRow(row).forEach((cell) => {
      if (CdkCellOutlet.mostRecentCellOutlet) {
        CdkCellOutlet.mostRecentCellOutlet._viewContainer.createEmbeddedView(cell.template, { $implicit: rowData });
        this._cellOutletViewContainers.set(index, CdkCellOutlet.mostRecentCellOutlet._viewContainer);
      }
    });
  }

  /*
  * Finds the matching row definition that should be used for this row data. If there is only
  * one row definition, it is returned. Otherwise, filters the row definitions that has a when
  * predicate that returns true with the data. If none return true, return the default row
  * definition.
  */
  private _getRowDefList(data: T, i: number): SotiRowDef[] {
    let result = [this._defaultRowDef];
    const rowDef = this._rowDefs.filter((def) => def.when && def.when(i, data as object));

    if (this._rowDefs.length === 1) {
      result = this._rowDefs;
    } else {
      result = rowDef;
    }
    return result;
  }
  /*
   * Create the embedded view for the data row template and place it in the correct index location
   * within the data row view container.
   * If there are multiple row definations it will insert another table for row
   */
  private _insertRows(rowData: T, index: number): void {
    const row = this._getRowDefList(rowData, index);

    row.forEach(r => { //looping all rows and creating view 
      const rowId = uniqueId();

      this.rowPlaceholder.viewContainer.createEmbeddedView(r.template, { rowId, $implicit: rowData }, index);

      this._getCellTemplatesForRow(r).forEach((cell) => {
        if (CdkCellOutlet.mostRecentCellOutlet) {
          CdkCellOutlet.mostRecentCellOutlet._viewContainer.createEmbeddedView(cell.template, { $implicit: rowData });
          this._cellOutletViewContainers.set(index, CdkCellOutlet.mostRecentCellOutlet._viewContainer);
        }
      });
      index++;
    })

  }

  /**
   * Updates the index-related context for each row to reflect any changes in the index of the rows,
   * e.g. first/last/even/odd.
   */
  private _updateRowContext(tuples: { [index: number]: T }): void {
    const viewContainer = this.rowPlaceholder.viewContainer;
    for (let index = 0, count = viewContainer.length; index < count; index++) {
      const rowViewRef = viewContainer.get(index) as RowViewRef<T>;
      const data = tuples[index];
      const rowId = rowViewRef.context.rowId;
      if (this.lazy) {
        rowViewRef.context.$implicit = data;
      }
      rowViewRef.context.index = index;
      rowViewRef.context.count = count;
      rowViewRef.context.first = index === 0;
      rowViewRef.context.last = index === count - 1;
      rowViewRef.context.even = index % 2 === 0;
      rowViewRef.context.odd = !rowViewRef.context.even;

      const cellViewContainer = this._cellOutletViewContainers.get(index);
      if (cellViewContainer) {
        for (let i = 0, cellViewCount = cellViewContainer.length; i < cellViewCount; i++) {
          const cellViewRef = cellViewContainer.get(i) as CellViewRef<T>;
          if (this.lazy) {
            cellViewRef.context.$implicit = data;
          }
          cellViewRef.context.rowId = rowId;
          cellViewRef.context.index = index;
          cellViewRef.context.count = count;
          cellViewRef.context.first = index === 0;
          cellViewRef.context.last = index === count - 1;
          cellViewRef.context.even = index % 2 === 0;
          cellViewRef.context.odd = !cellViewRef.context.even;
          cellViewRef.markForCheck();
        }
        this._changeDetectorRef.markForCheck();
      }
    }
  }

  /**
   * Returns the cell template definitions to insert into the header
   * as defined by its list of columns to display.
   * 
   * @param {CdkHeaderRowDef} headerDef - header
   * @returns {CdkHeaderCellDef[]} template for row
   */
  private _getHeaderCellTemplatesForRow(headerDef: CdkHeaderRowDef): CdkHeaderCellDef[] {
    if (!headerDef || !headerDef.columns) {
      return [];
    }
    return Array.from(headerDef.columns).map((columnId) => {
      const column = this._columnDefsByName.get(columnId);

      if (!column) {
        throw getTableUnknownColumnError(columnId);
      }

      return column.headerCell;
    });
  }

  /**
   * Returns the cell template definitions to insert in the provided row
   * as defined by its list of columns to display.
   * 
   * @param {SotiRowDef} rowDef - row definition
   * @returns {CdkCellDef[]} split row into cells def
   */
  private _getCellTemplatesForRow(rowDef: SotiRowDef): CdkCellDef[] {
    if (!rowDef.columns) {
      return [];
    }
    return Array.from(rowDef.columns).map((columnId) => {
      const column = this._columnDefsByName.get(columnId);

      if (!column) {
        throw getTableUnknownColumnError(columnId);
      }

      return column.cell;
    });
  }

  /**
   * Sets up the `LazyScrollService`
   * 
   * @param {any[]} data - input 
   * @returns { Observable<LazyScrollAction> } creates a lazyscroll action
   */
  private _lazyScroll(data: T[]): Observable<LazyScrollAction> {
    return this.lazyScrollService.create(data.length, this.threshold, this.rowHeight);
  }

  /** 
   * Whether the table has all the information to start rendering.
   * 
   * @returns {boolean} value if outlet could be rendered
   */
  _canRender(): boolean {
    return this._hasAllOutlets && this._hasInitialized;
  }

  /** Invoked whenever an outlet is created and has been assigned to the table. */
  _outletAssigned() {
    // Trigger the first render once all outlets have been assigned. We do it this way, as
    // opposed to waiting for the next `ngAfterContentChecked`, because we don't know when
    // the next change detection will happen.
    // Also we can't use queries to resolve the outlets, because they're wrapped in a
    // conditional, so we have to rely on them being assigned via DI.

    // in order to support it properly extend CdkTable would be required.
  }

  _getCellRole(): string {
    return "gridcell"
  }
}
