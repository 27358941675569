import { Directive, EventEmitter, Host, Output } from '@angular/core';
import { SotiTable } from '../table/table';

export interface SotiOrderable {
  id: string;
  index: number;
}

export interface OrderChange {
  columnName: string;
  newIndex: number;
  previousIndex: number;
}

@Directive({
  selector: '[sotiReorder]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiReorder {
  @Output() public orderChange: EventEmitter<OrderChange> = new EventEmitter<OrderChange>();

  public orderables: Map<string, SotiOrderable> = new Map<string, SotiOrderable>();

  public get columns(): Iterable<string> {
    return this._table.headerRowDef.columns;
  }

  constructor(@Host() private _table: SotiTable<object>) {}

  public register(orderable: SotiOrderable): void {
    if (!orderable.id) {
      throw new Error('orderable must have a unique ID');
    }

    if (this.orderables.has(orderable.id)) {
      throw new Error(`Cannot have two orderables with the same Id: ${orderable.id}`);
    }

    this.orderables.set(orderable.id, orderable);
  }

  public deregister(orderable: SotiOrderable): void {
    this.orderables.delete(orderable.id);
  }

  public updateOrder(orderable: SotiOrderable, updatedIndex: number): void {
    // const columns = [...this.columns];
    // columns.splice(orderable.index, 1);
    // columns.splice(updatedIndex, 0, orderable.id);
    this.orderChange.emit({
      columnName: orderable.id,
      newIndex: updatedIndex,
      previousIndex: orderable.index
    });
  }
}
