import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, combineLatest, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export class TableDataSource<T> implements DataSource<T> {
  private _renderData: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private _renderSubscription: Subscription | undefined;

  /**
   * Returns the source data as an observable
   */
  public get dataObservable(): Observable<T[]> {
    return this._data.asObservable();
  }

  /**
   * Set the current data
   */
  public set data(data: T[]) {
    this._data.next(data);
  }
  /**
   * Gets the current data value from the data source
   */
  public get data(): T[] {
    return this._data.value;
  }
  private _data: BehaviorSubject<T[]>;

  public set loading(loadingObservable: Observable<boolean>) {
    this._loading = loadingObservable;
  }
  public get loading(): Observable<boolean> {
    return this._loading;
  }
  private _loading: Observable<boolean>;

  constructor(initialData: T[] = []) {
    this._data = new BehaviorSubject<T[]>(initialData);

    this._updateSubscription();
  }

  public connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return combineLatest(collectionViewer.viewChange, this._renderData).pipe(
      // eslint-disable-line
      switchMap(([view, data]) => {
        return of(data.slice(view.start, view.end));
      })
    );
  }
  public disconnect(collectionViewer: CollectionViewer): void {
    if (this._renderSubscription) {
      this._renderSubscription.unsubscribe();
    }
    this._renderSubscription = undefined;
  }

  /**
   * TODO: add offline sorting and pagination
   */
  private _updateSubscription(): void {
    this._renderSubscription = this._data.subscribe((data) => {
      this._renderData.next(data);
    });
  }

  public updateSubscription() {
    this._updateSubscription();
  }
}
