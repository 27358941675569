import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionEnum } from '../shared/enums/ActionEnum';
import { PolicyType } from '../shared/enums/PolicyType';

@Injectable()
export class PolicyListService {
    public policyListValueObserver : Subject<boolean> = new Subject<boolean>();
    public policyActionValueObserver: Subject<[ActionEnum, PolicyType]> = new Subject<[ActionEnum, PolicyType]>();

    constructor() {

    }
}
