/**
 * Created by spentland on 2016-03-23.
 */

import {
    Component, Input, Output, EventEmitter, ViewChildren, OnInit, AfterViewInit, OnChanges, QueryList, Renderer2,
    OnDestroy
} from '@angular/core';
import {ControlItem} from './pagination-control-item/pagination-control-item.ctrl';
import {logger} from '../../utils/Logger';
import {NgForm, UntypedFormGroup} from '@angular/forms';
import {KeyCodeEnum} from '../../enums/KeyCodeEnum';
import {ISelectionChangeArgs} from '../select-box/select-box.ctrl';

@Component({
    selector: 'soti-pagination',
    templateUrl: './pagination.ctrl.html',
    styleUrls: ['./pagination.ctrl.scss']
})

export class PaginationComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input()
    public pageSizeOptions: number[] = [50, 75, 100, 125, 150, 175, 200, 225, 250];
    /** The number of items total */
    @Input() public totalItems: number;
    /** The type of items we are displaying, e.g.: 'Devices', 'Users', etc... */
    @Input() public itemType: string;
    /** The number of the first item on the page, essentially API 'skip' + 1 */
    @Input() public firstItem: number;
    /** The number of items that are displayed per page, essentially API 'take' */
    @Input() public pageSize: number;
    /** Check to determine if in modal which would indicate a smaller 'window' for width of pagination control */
    @Input() public isModal: boolean;

    /** Emitter used when the user requests a different page size */
    @Output() public pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();
    /** Emitter used when the user moves to a different page */
    @Output() public pageNumberRequested: EventEmitter<number> = new EventEmitter<number>();

    @ViewChildren(ControlItem) public controlItems: QueryList<ControlItem>;

    public controlsCollection: Array<number> = [];

    public lastPageItem: number;
    public showLeftEllipsis: boolean = true;
    public showRightEllipsis: boolean = true;
    public showFirstNumber: boolean = true;

    public currentPage: number;
    public numPages: number;
    public targetPage: number;

    private _numTrailingBeforeEllipsis: number = 4;
    private _numTotalPagesToShow: number = 9;

    private _isViewInit: boolean = false;


    // Bound to input on component and also set whenever we determine current page
    private _resizeListener: Function;

    constructor(private _renderer: Renderer2) {
        this._resizeListener = _renderer.listen('window', 'resize', () => this._windowSizeChanged());
    }

    public ngOnInit(): void {
        this._determineNumberOfPagesToShow();
        this._layoutControlItems();
    }

    public ngAfterViewInit(): void {
        this._addOrRemoveCurrentPageHighlight(this.currentPage, true);
        this._isViewInit = true;
        this.controlItems.changes.subscribe(_ => this._addOrRemoveCurrentPageHighlight(this.currentPage, true));
    }

    public ngOnChanges(changes: {}): void {
        if (!this._isViewInit) {
            return;
        }
        this.controlsCollection = [];
        this._layoutControlItems();
        this._addOrRemoveCurrentPageHighlight(this.currentPage, true);
    }

    public ngOnDestroy(): void {
        this._resizeListener();
    }

    /**
     * Event handler that is called when a number is clicked
     * @param page The page to go to
     */
    public requestPage(page: number): void {
        if (page === this.currentPage) {
            return;
        }
        this._addOrRemoveCurrentPageHighlight(this.currentPage, false);
        this._addOrRemoveCurrentPageHighlight(page, true);
        this.pageNumberRequested.emit(page);
    }

    /**
     * Event handler for when the < or > buttons are pressed
     * @param type Whether it was previous (0) or next (1)
     */
    public nextOrPreviousClick(type: number): void {
        if ((type === 0 && this.currentPage === 1) || (type === 1 && this.currentPage === this.numPages) || this.numPages === 1) {
            return;
        }

        switch (type) {
            case 0:
                this.requestPage(this.currentPage - 1);
                break;
            default:
                this.requestPage(this.currentPage + 1);
        }
    }

    // This is most likely a temporary method to satisfy the requirements. It is very possible the the dropdown
    // that has been envisioned will actually emits its own value and we'll pass it.
    public goToPage(form: NgForm, event: KeyboardEvent): void {
        if (form.invalid) {
            return;
        }
        const formModel: { targetPage: number } = form.value;
        let numeric = +formModel.targetPage;

        // Make sure it is a value we can use
        if (isNaN(numeric) || numeric < 1 || numeric > this.numPages) {
            this.targetPage = this.currentPage;
            return;
        }

        // Round down to whole number
        numeric = Math.floor(numeric);

        // Don't bother emitting if we are on the entered page
        if (numeric === this.currentPage) {
            this.targetPage = numeric;
            return;
        }

        // hide keyboard on tablets:
        event.target[0].blur();

        this._addOrRemoveCurrentPageHighlight(this.currentPage, false);
        this.pageNumberRequested.emit(numeric);
    }

    /**
     * Prevent a modal dialog from navigating left or right when in the input for page number.
     * @param event The keyboard event fired by user key down.
     */
    public preventNavigation(event: KeyboardEvent): void {
        if (event.keyCode == KeyCodeEnum.LEFT_ARROW || event.keyCode == KeyCodeEnum.RIGHT_ARROW) {
            event.stopImmediatePropagation();
        }
    }

    public onPageSizeChange(change: ISelectionChangeArgs): void {
        let numeric = +change.value;
        if (numeric === this.pageSize) {
            return;
        }
        if (change.isUserInitiated) {
            this._addOrRemoveCurrentPageHighlight(this.currentPage, false);
            this.pageSizeChanged.emit(numeric);
        }
    }

    // /**
    //  * Event handler for select on dropdown for the number of items to show per page
    //  * @param desired The new number of items to show per page
    //  */
    // private _numberItemsPerPageChanged(desired: number): void {
    //     let numeric = +desired;
    //     if (numeric === this.pageSize) {
    //         return;
    //     }
    //     logger.debug('Pager: Items per page changed: ', numeric);
    //     this._addOrRemoveCurrentPageHighlight(this.currentPage, false);
    //     this.pageSizeChanged.emit(numeric);
    // }

    /**
     * Helper used whenever we need to layout all of the elements again
     * @private
     */
    private _layoutControlItems(): void {
        this._calculateNumberOfPages();
        this._determineNumberOfPagesToShow();
        this._calculateCurrentPage();
        this._determineEllipsisVisibility();
        this._createPageNumbersForMainPager();
    }

    private _calculateNumberOfPages(): void {
        this.numPages = Math.ceil(this.totalItems / this.pageSize);
        this.showFirstNumber = this.numPages !== 1;
    }

    /**
     * Based on the first number we are given, determine what page we are on and by extension what page is highlighted
     * @private
     */
    private _calculateCurrentPage(): void {
        this.currentPage = this.lastPageItem == this.totalItems ? this.numPages : this.firstItem > this.pageSize ? (this.firstItem / this.pageSize >> 0) + 1 : 1;
        this.targetPage = this.currentPage;
    }

    /**
     * Determine whether or not we show the '...' on one or both sides
     * @private
     */
    private _determineEllipsisVisibility(): void {
        if (this.numPages === 1 || this.numPages <= this._numTotalPagesToShow) {
            this.showLeftEllipsis = false;
            this.showRightEllipsis = false;
            return;
        }
        this.showLeftEllipsis = this.currentPage - this._numTrailingBeforeEllipsis > 2;
        this.showRightEllipsis = this.numPages - this.currentPage > this._numTrailingBeforeEllipsis + 1;
    }

    /**
     * Based on where we are in the paging, generate the correct sequence of numbers to show in the navigation area
     * @private
     */
    private _createPageNumbersForMainPager(): void {
        // Number creation depends on whether we are showing one or both ellipsis
        if (this.numPages === 1) {
            return;
        } else if (this.showLeftEllipsis && this.showRightEllipsis) {
            // We are in the center
            let endtarget: number = this.currentPage + this._numTrailingBeforeEllipsis > this.numPages ? this.numPages - 1 : this.currentPage + this._numTrailingBeforeEllipsis;

            for (var num = this.currentPage - this._numTrailingBeforeEllipsis; num <= endtarget; num++) {
                this.controlsCollection.push(num);
            }

        } else if (this.showLeftEllipsis) {
            // We run from out page until end
            for (var i = this.numPages - this._numTotalPagesToShow; i < this.numPages; i++) {
                // Do not show pages that are consecutive to the first number
                if (i < 3) {
                    continue;
                }
                this.controlsCollection.push(i);
            }
        } else if (this.numPages < this._numTotalPagesToShow) {
            for (var i = 2; i < this.numPages; i++) {
                this.controlsCollection.push(i);
            }
        } else {
            // We run from 2 until the max number (1 is always visible)

            for (var i = 2; i < this._numTotalPagesToShow; i++) {
                this.controlsCollection.push(i);
            }
        }
    }

    /**
     * Add or remove the 'current-page' and 'not-clickable' classes to the current page
     * @param page The page to add them to
     * @param isAdd Whether to add or remove
     * @private
     */
    private _addOrRemoveCurrentPageHighlight(page: number, isAdd: boolean): void {
        if (page > this.numPages) {
            this.pageNumberRequested.emit(this.numPages);
        } else if (page <= 0) {
            this.pageNumberRequested.emit(1);
        }

        this.controlItems.forEach(item => {
            let theElement = item.ref.nativeElement;

            if (+item.text === page) {
                isAdd ? this._renderer.addClass(theElement, 'current-page') : this._renderer.removeClass(theElement, 'current-page');
                //this._renderer.setElementClass(theElement, 'current-page', isAdd);
                isAdd ? this._renderer.addClass(theElement, 'not-clickable') : this._renderer.removeClass(theElement, 'not-clickable');
                //this._renderer.setElementClass(theElement, 'not-clickable', isAdd);
            } else {
                // Remove highlight/clickable classes from any other control that may have them
                this._renderer.removeClass(theElement, 'current-page');
                this._renderer.removeClass(theElement, 'not-clickable');
            }
        });
    }

    /**
     * Based on the current window width, determine the amount of number components to generate
     * @private
     */
    private _determineNumberOfPagesToShow(): void {
        let pagewidth = document.documentElement.clientWidth;

        if (pagewidth < 1900 || (this.isModal && this.numPages > 200)) {
            this._numTotalPagesToShow = 5;
            this._numTrailingBeforeEllipsis = 2;
        } else {
            this._numTrailingBeforeEllipsis = 4;
            this._numTotalPagesToShow = 9;
        }
        this.pageNumberRequested.emit(0);
    }

    /**
     * Utility method that serves as callback when the window size changes
     * @private
     */
    private _windowSizeChanged(): void {
        this._determineNumberOfPagesToShow();
        this.controlsCollection = [];
        this._layoutControlItems();
        this._addOrRemoveCurrentPageHighlight(this.currentPage, true);
    }
}
