import { Directive, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';

export interface SotiSortable {
  id: string;
}

export interface SortOrder {
  active: string;
  direction: SortDirection;
}

@Directive({
  selector: '[sotiSort]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SotiSort {
  public sortables: Map<string, SotiSortable> = new Map<string, SotiSortable>();
  public active: string;
  public direction: SortDirection = '';

  @Output() public sortChange: EventEmitter<SortOrder> = new EventEmitter<SortOrder>();

  /**
   * Event that gets triggered whenever the direction is updated, but not sorted.
   * This means, that whenever the direction is changed programatically, we emit this event.
   */
  public directionChange: EventEmitter<void> = new EventEmitter<void>();

  private _sortOrder: SortDirection[] = ['asc', 'desc', ''];

  constructor(private _cdr: ChangeDetectorRef) {}

  public register(sortable: SotiSortable): void {
    if (!sortable.id) {
      throw new Error('Sortable must have a unique ID');
    }

    if (this.sortables.has(sortable.id)) {
      throw new Error(`Cannot have two sortables with the same Id: ${sortable.id}`);
    }

    this.sortables.set(sortable.id, sortable);
  }

  public deregister(sortable: SotiSortable): void {
    this.sortables.delete(sortable.id);
  }

  public sort(sortable: SotiSortable): void {
    if (this.active !== sortable.id) {
      this.active = sortable.id;
      this.direction = 'asc';
    } else {
      this.direction = this._getSortDirection(sortable);
    }

    this.sortChange.next({ active: this.active, direction: this.direction });
  }

  /**
   * Set the header(s) to show the sorting specified, without emitting any events to signal a change of sort.
   * For use when the grid must be set to show the sorting that the data already reflects.
   * @param active the column id to show sorting by. No indication will be shown if 'active' does not match
   *               the column this header is for.
   * @param direction sort direction; '' or 'asc' or 'desc'
   */
  public showSorted(active: string, direction: SortDirection) {
    Promise.resolve(null).then(() => {
      this.active = active;
      this.direction = direction;
      this.directionChange.next();
    });
  }

  private _getSortDirection(sortable: SotiSortable): SortDirection {
    let nextSortDirection = this._sortOrder.indexOf(this.direction) + 1;
    if (nextSortDirection >= this._sortOrder.length) {
      nextSortDirection = 0;
    }
    return this._sortOrder[nextSortDirection];
  }
}
