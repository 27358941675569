import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    SimpleChanges,
    Type,
    ViewChild
} from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

/**
 * TODO: use new virtual scroll service
 */
@Component({
    selector: 'soti-virtual-scroll',
    templateUrl: './virtual-scroll.ctrl.html',
    styleUrls: ['./virtual-scroll.ctrl.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    // encapsulation: ViewEncapsulation.None,
})
export class VirtualScrollControl implements AfterViewInit, OnChanges, OnDestroy {
    @Input() public data: Type<Object>[];
    @Input() public rowSize: number;
    @Input() public threshold: number;
    @Input() public additionalStaticHeight: number = 0;
    @Output() public rowsChanged: Observable<boolean>;

    @HostBinding('class')
    @Input()
    public position: string = 'absolute';

    public itemsStart: number = 0;
    public itemsEnd: number = 0;
    public visibleRows: Type<Object>[];

    public get rowsInCluster(): number {
        return this._rowsInCluster;
    }

    private _blockHeight: number = 0;
    private _rowsInBlock: number = 0;
    private _rowsInCluster: number = 0;
    private _clusterHeight: number = 0;
    private _blocksInCluster: number = 4;
    private _scrollTop: number = 0;
    private _lastCluster: number = 0;
    private _staticContentHeight: number = 0;
    private _scrollSubscription: Subscription;
    private _rowsChangedSource: Subject<boolean> = new Subject<boolean>();

    @ViewChild('topPadding') private _topPadding: ElementRef;
    @ViewChild('bottomPadding') private _bottomPadding: ElementRef;

    constructor(
        public element: ElementRef,
        private _cdr: ChangeDetectorRef,
        private _zone: NgZone,
        private _renderer: Renderer2
    ) {

        this.rowsChanged = this._rowsChangedSource.asObservable().pipe(debounceTime(100));

        let scroll = fromEvent<UIEvent>(element.nativeElement, 'scroll').pipe(throttleTime(10));
        this._scrollSubscription = scroll.subscribe((ev) => {
            this.onScroll(ev);
            this._cdr.markForCheck();
        });
    }

    public ngAfterViewInit(): void {
        const staticContent: HTMLElement = this.element.nativeElement.querySelector('[static]');
        if (staticContent) {
            this._staticContentHeight = staticContent.clientHeight;
        }
        this._staticContentHeight += this.additionalStaticHeight;
    }

    public ngOnChanges(changes: SimpleChanges): void {

        // if ('data' in changes && changes['data'].currentValue) {
        //     this._rowsInBlock = this.threshold || 25;
        //     this._setUpSizing();
        //     this._updateView(this.data);
        // }
    }

    public ngOnDestroy(): void {
        this._scrollSubscription && this._scrollSubscription.unsubscribe();
    }

    public onScroll($event: UIEvent): void {
        this._onScroll();
    }

    private _onScroll(): void {

        if (this._lastCluster != (this._lastCluster = this._getClusterNum())) {
            this._updateView(this.data);
            this._rowsChangedSource.next(true);
        }
    }

    private _setUpSizing(): void {
        this._blockHeight = this.rowSize * this._rowsInBlock;
        this._rowsInCluster = this._blocksInCluster * this._rowsInBlock;
        this._clusterHeight = this._blocksInCluster * this._blockHeight;
    }

    private _getClusterNum(): number {
        this._scrollTop = this.element.nativeElement.scrollTop;
        return Math.max(
            Math.floor((this._scrollTop - this._staticContentHeight) / (this._clusterHeight - this._blockHeight)),
            0
        );
    }

    private _calculateRows(rows: Type<Object>[], clusterNum: number): RowPositioning {
        this.itemsStart = Math.max((this._rowsInCluster - this._rowsInBlock) * clusterNum - this._rowsInBlock, 0);
        this.itemsEnd = this.itemsStart + this._rowsInCluster + this._rowsInBlock * 2;

        const rowsLength = rows.length;
        if (rowsLength < this._rowsInBlock) {
            return {
                bottomOffset: 0,
                topOffset: 0,
                rowsAbove: 0,
                rows
            };
        }

        const visibleRows = rows.slice(this.itemsStart, this.itemsEnd);
        const rowsAbove = this.itemsStart;

        let topOffset = Math.max(this.itemsStart * this.rowSize, 0);
        if (this.itemsStart > 0) {
            // take away the static content height, but add a row size buffer
            topOffset -= this._staticContentHeight;
            if (this._staticContentHeight > 0) {
                topOffset += this.rowSize * 3;
            }
        }

        let bottomOffset = Math.max((rowsLength - this.itemsEnd) * this.rowSize, 0);
        return {
            topOffset,
            bottomOffset,
            rowsAbove,
            rows: visibleRows
        };
    }

    private _updateView(rows: Type<Object>[]): void {
        const data = this._calculateRows(rows, this._getClusterNum());
        this._renderer.setStyle(this._topPadding.nativeElement, 'height', data.topOffset + 'px');
        //this._renderer.setStyle(this._bottomPadding.nativeElement, 'height', data.bottomOffset + 'px');
        this.visibleRows = data.rows;
    }
}

interface RowPositioning {
    topOffset: number;
    bottomOffset: number;
    rowsAbove: number;
    rows: Type<Object>[];
}
