import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AssociatedMspTenants } from 'src/app/models/AssociatedMspTenants';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { IntergratedApplicationTypes } from '../../../models/IntergratedApplicationTypes';
import { DBkeys } from '../../../services/db-keys';
import { LocalStoreManager } from '../../../services/local-store-manager.service';
import { DisposalBag } from 'src/app/shared/utils/DisposalBag';
import { Application } from '../../../models/application.model';
import { SotiThemeService } from '../../../services/soti-theme/soti-theme.service';
import { LightTheme } from '../../../services/soti-theme/theme';
import { UserPreferenceModel } from 'src/app/models/UserPreference.model';
import { UserPreferenceType } from 'src/app/shared/enums/UserPreferenceType';
import { ViewType } from 'src/app/shared/controls/listgrid-btn-switcher/listgrid-btn-switcher.ctrl';
import { MspDisplayApplication } from '../../../models/mspdisplayapplication.model';


@Component({
    selector: 'msp-tenant-apps',
    templateUrl: './msp-tenant-apps.component.html',
    styleUrls: ['./msp-tenant-apps.component.scss'],
})
export class MspTenantApplicationComponent implements OnInit, OnChanges {
    public noApplications: boolean = true;
    public applications: MspDisplayApplication[];
    public filteredApplications: MspDisplayApplication[] = [];
    public showConsoleLink: boolean = true;
    public searchApplicationKeyword: string = '';
    private _disposalBag: DisposalBag = new DisposalBag();
    @Input() public selectedMspTenant: AssociatedMspTenants;
    private defaultAppId: string;
    public isLoaded:boolean = false;

    public viewMode: UserPreferenceModel = {
        TypeId: UserPreferenceType.DASHBOARDVIEW,
        Preference: ViewType.Tile
    }
    public listViewType: number = ViewType.List;
    public tileViewType: number = ViewType.Tile;

    constructor(
        private themeService: SotiThemeService,
        private companyService: CompanyService,
        private authService: AuthService,
        private localStoreManager: LocalStoreManager
    ) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedMspTenant) {
            const previousValue = changes.selectedMspTenant.previousValue;
            const currentValue = changes.selectedMspTenant.currentValue;
            if (previousValue?.ChildTenantReferenceId == currentValue?.ChildTenantReferenceId) {
                return;
            }
            this._disposalBag.dispose();
            this.isLoaded = false;
            this.applications = [];
            this.filteredApplications = [];
            this.loadData(this.selectedMspTenant.ChildTenantReferenceId);
            this.searchApplicationKeyword = '';
        }
    }

    public ngOnInit(): void {
        this.themeService.setTheme(new LightTheme());
        this.defaultAppId = this.authService.systemParams.defaultApplicationReferenceId;
        if (this.authService.currentUser.RolesReferenceIds.includes(this.authService.systemParams.defaultMspTenantUserRoleReferenceId)) {
            this.showConsoleLink = false;
        }
    }

    public loadData(childTenantReferenceId: string): void {
        if (!childTenantReferenceId) {
            this.setNoApplicationValue(true);
            return
        }

        this._disposalBag.nextSub = this.companyService.getApplications(childTenantReferenceId).subscribe(
            (result) => {
                this.isLoaded = true;

                if (result?.Applications == null || result?.Applications.length < 1) {
 
                    this.setNoApplicationValue(true);
                    return;
                }
                
                this.applications = result.Applications.filter(app => app.ReferenceId !== this.defaultAppId);

                this.filteredApplications = this.applications;

                let mobicontrolApplicationIds: string[] = this.applications.filter(x => x.ApplicationType == IntergratedApplicationTypes.MobiControl).map(x => x.ReferenceId);
                if(mobicontrolApplicationIds.length > 0 && result.ValidateXsightorAssist)
                    {
                        this._disposalBag.nextSub = this.companyService.getLongLoadingApplications(IntergratedApplicationTypes.SotiXsight, mobicontrolApplicationIds, childTenantReferenceId).subscribe(
                            (response) => {
                                if(response.length > 0)
                                {
                                    this.applications = [...this.applications, ...response];
                                    this.filteredApplications = this.applications;
                                }
                            });
                    }

                if (this.applications.length < 1) {
                    this.setNoApplicationValue(true);
                    return;
                }
                this.setNoApplicationValue(false);
            },
            () => {
                this.setNoApplicationValue(true);
                this.isLoaded = true;
            }
        );

    }

    public viewChanged(viewType: number) {
        this.viewMode.Preference = viewType;
    }

    public setNoApplicationValue(value: boolean) {
        this.noApplications = value;
    }

    public openChildTenantConsole(): void {
        let applicationReferenceId = this.authService.systemParams.defaultApplicationReferenceId;
        let url = window.location.protocol + '//' + window.location.host + '/application-redirect?applicationReferenceId=' + applicationReferenceId + '&clientTenantReferenceId=' + this.selectedMspTenant.ChildTenantReferenceId;
        let app: Application = new Application();
        app.Id = 1;
        app.ReferenceId = applicationReferenceId;
        app.ApplicationType = IntergratedApplicationTypes.Default;
        while (this.localStoreManager.getData(DBkeys.READLOCK + 'APPLICATION_REDIRECT' + applicationReferenceId));
        this.localStoreManager.saveSessionData(1, DBkeys.READLOCK + 'APPLICATION_REDIRECT' + applicationReferenceId);
        let previousRedirectInfo = this.localStoreManager.getData(DBkeys.APPLICATION_REDIRECT_INFO + applicationReferenceId);
        if (previousRedirectInfo != null && previousRedirectInfo.InstanceCount != 0) {
            app.InstanceCount = previousRedirectInfo.InstanceCount + 1;
        }
        else {
            app.InstanceCount = 1;
        }
        this.localStoreManager.saveSessionData(app, DBkeys.APPLICATION_REDIRECT_INFO + applicationReferenceId);
        this.localStoreManager.deleteData(DBkeys.READLOCK + 'APPLICATION_REDIRECT' + applicationReferenceId);
        window.open(url, '_blank');
    }

    public updateSearchQuery(keyword: string) : void{
        this.searchApplicationKeyword = keyword;
        const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedKeyword.trim(), 'i');
        this.filteredApplications = this.applications.filter((element) => {
            const lowercaseName = element.Name.toLocaleLowerCase()
            return lowercaseName.match(regex);
        });
    }

}