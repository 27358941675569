import { shadowAnimation } from './../shared/controls/multi-select-box/select-animations';
import { ExternalIdpConnectionCertificate } from './externalIdpConnectionCertificate.model';
import { XsightDetails } from './xsightDetails.model';
import { ApplicationExtensionUrl } from './applicationExtensionUrl.model';

export class Application {
    // tslint:disable-next-line:variable-name
    public Id: number;

    // tslint:disable-next-line:variable-name
    public Name: string;

    // tslint:disable-next-line:variable-name
    public Description: string;

    // tslint:disable-next-line:variable-name
    public MetadataType: number;

    // tslint:disable-next-line:variable-name
    public SpMetadataUri: string;

    // tslint:disable-next-line:variable-name
    public ThumbnailURL: string;

    // tslint:disable-next-line:variable-name
    public SupportedProtocol: number;

    // tslint:disable-next-line:variable-name
    public Enabled: boolean;

    // tslint:disable-next-line:variable-name
    public appLogo: File;

    // tslint:disable-next-line:variable-name
    public HasappLogo: boolean;

    // tslint:disable-next-line:variable-name
    public AssertionUrl: string;

    // tslint:disable-next-line:variable-name
    public LogoutUrl: string;

    // tslint:disable-next-line:variable-name
    public SPRealmUrl: string;

    // tslint:disable-next-line:variable-name
    public SignedAuthRequest: boolean = false;

    // tslint:disable-next-line:variable-name
    public WantAssertionEncrypted: boolean;

    // tslint:disable-next-line:variable-name
    public CertificationInformation: ExternalIdpConnectionCertificate[];

    // tslint:disable-next-line:variable-name
    public ApplicationType: number;

    // tslint:disable-next-line:variable-name
    public ApplicationAttributes: any;

    // tslint:disable-next-line:variable-name
    public AllowTenantRoleCreation: boolean;

    // tslint:disable-next-line:variable-name
    public ApplicationRoleRequired: boolean;

    // tslint:disable-next-line:variable-name
    public RedirectionUrl: string;

    // tslint:disable-next-line:variable-name
    public Status: number;

    // tslint:disable-next-line:variable-name
    public SignedAuthResponse: boolean = false;

    // tslint:disable-next-line:variable-name
    public SendIdpSignCertificate: boolean;

    // tslint:disable-next-line:variable-name
    public SignElement: number;

    // tslint:disable-next-line:variable-name
    public SignAlgorithm: number;

    // tslint:disable-next-line:variable-name
    public EncryptionAlgorithm: number;

    // tslint:disable-next-line:variable-name
    public RelayState: string;

    // tslint:disable-next-line:variable-name
    public Recipient: string;

    // tslint:disable-next-line:variable-name
    public NameIdFormat: Number;

    // tslint:disable-next-line:variable-name
    public WantLogoutRequestSigned: boolean = false;

    // tslint:disable-next-line:variable-name
    public WantLogoutResponseSigned: boolean = false;

    // tslint:disable-next-line:variable-name
    public ReferenceId: string;

    // tslint:disable-next-line:variable-name
    public ShowOnOnePortal?: boolean;

    // tslint:disable-next-line:variable-name
    public WebAppClientCredentials: boolean = true;

    // tslint:disable-next-line:variable-name
    public UserAuthCode: boolean = true;

    // tslint:disable-next-line:variable-name
    public UsePkce: boolean = true;

    // tslint:disable-next-line:variable-name
    public IsConsentRequired: boolean = true;

    // tslint:disable-next-line:variable-name
    public TermsOfServiceURI?: string;

    // tslint:disable-next-line:variable-name
    public PolicyURI?: string;

    // tslint:disable-next-line:variable-name
    public InitiateLoginURI: string

    // tslint:disable-next-line:variable-name
    public SignInRedirectURIs: string[] = []

    // tslint:disable-next-line:variable-name
    public SignOutRedirectURIs: string[] = []

    // tslint:disable-next-line:variable-name
    public InstanceCount?: number = 0;

    public AccessCount: number = 0;

    public ShowXsightDetails: boolean = false;

    // tslint:disable-next-line:variable-name
    public XsightDetails: XsightDetails = null;

    public ApplicationExtensionUrls: ApplicationExtensionUrl[];

    public DCREndpointUrlData: string;

    public SCIMUrlData: string;

    public TokenAuthorizationUrlData: string;

    public TokenAuthorizationServerUrlData: string;

    public RoleManagedType : number;

    public OnlyAllowMobileAccessByManagedMC : boolean = false;

    public XsightDefaultPreferenceRedirectionUrl : string;

    public XsightDefaultPreferenceRedirectionUrlName : string;
    
    public Version: string;

    public TotalLicenses: number;

    public LicenseExpiryDate: Date;
}
